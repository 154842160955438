import { Page } from '@srcroot/utils/Page'
import { getPageFromPath } from '@srcroot/utils/getPageFromPath'
import { useLocation } from 'react-router-dom'

export const Banner = () => {
  const location = useLocation()

  const getCssHeaderAndTextFromLocation = (path: string): [string, string] => {
    if (getPageFromPath(path) === Page.MATT) {
      return ['page-header-index-matthew', 'Matthew Soo']
    } else if (getPageFromPath(path) === Page.JAMES) {
      return ['page-header-index-james', 'James Soo']
    } else if (getPageFromPath(path) === Page.FAMILY) {
      return ['page-header-index-family', 'Family']
    }

    return ['page-header-index', 'JASON SOO | Software Engineer | Programmer']
  }

  const [cssHeader, headerText] = getCssHeaderAndTextFromLocation(location.pathname)

  return (
    <>
      <header data-testid="banner-header" className={cssHeader}>
        <h1 data-testid="banner-text">{headerText}</h1>
      </header>
    </>
  )
}
