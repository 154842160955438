import PIZZA_IMAGE from '@srcroot/assets/images/family/recipes/pizza/pizza.jpg'
import UNITED_KINGDOM_FLAG_IMAGE from '@srcroot/assets/images/family/recipes/Flag_of_the_United_Kingdom-300x150.png'

export const OvenPizza = () => {
  return (
    <>
      <h1>Oven Pizza</h1>
      <img src={PIZZA_IMAGE} alt="Oven pizza" />
      <br />

      <img width="120" height="80" src={UNITED_KINGDOM_FLAG_IMAGE} alt="United Kingdom flag" />

      <h1>Preparation</h1>
      <ol>
        <li>
          rapid heat <b>200 degrees Celsius</b> until light goes off (temp reached)
        </li>
        <li>
          tomato on pizza base, cheese + toppings (eg corn on half of pizza, cut up tomatoes,
          salami)
        </li>
        <li>our pizza goes first in the middle #3 (tray), kids go below on #4 (tray)</li>
        <li>
          fan forced <b>10 mins</b> in bell mode
        </li>
      </ol>
    </>
  )
}
