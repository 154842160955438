import CENTRE_BACK_POSITION_IMAGE from '@srcroot/assets/images/family/soccer/center-back-position.png'

export const MidFieldAndDefense = () => {
  return (
    <>
      <h1 id="midfield">Mid Field</h1>
      <p>
        Seven great skills for{' '}
        <a href="https://www.youtube.com/watch?v=qqoiJYUE0Do" target="_blank" rel="noreferrer">
          mid fielders
        </a>{' '}
        ~ 8m
      </p>
      <ol>
        <li>keep ball, beat defenders, thru pass</li>
        <li>no touch turn</li>
        <li>jorghino pass</li>
        <li>draw in defender</li>
        <li>first touch against momentum</li>
        <li>1 touch turn</li>
        <li>adjusting your feet (before shooting)</li>
        <li>pass the ball in front</li>
      </ol>

      <hr />

      <h1 id="defending">Defending</h1>

      <img src={CENTRE_BACK_POSITION_IMAGE} alt="Center back position" />
      <p>
        How to play{' '}
        <a href="https://www.youtube.com/watch?v=_fKvOeik6ek" target="_blank" rel="noreferrer">
          center back
        </a>
        ~ 4m
      </p>
    </>
  )
}
