import { grayText } from '@srcroot/utils/commonCss'

import { AthleticsTracksAroundSydney } from './athleticsTracksAroundSydney'
import { AthleticsTableOfContents } from './athleticsTableOfContents'
import { WarmUp } from './warmUp'
import { Run100mDash } from './run100mDash'
import { Run200mDash } from './run200mDash'
import { MoreTechnicalAndMeasureDistance } from './moreTechnicalAndMeasureDistance'

export const Athletics = () => {
  return (
    <>
      <h1 data-testid="athletics-training-header">Athletics</h1>

      <AthleticsTableOfContents />
      <hr />

      <h2 className={grayText()}>1 Oct 2022</h2>

      <AthleticsTracksAroundSydney />
      <hr />
      <WarmUp />
      <hr />
      <Run100mDash />
      <hr />
      <Run200mDash />
      <hr />
      <MoreTechnicalAndMeasureDistance />
    </>
  )
}
