import LEMONS_IMAGE from '@srcroot/assets/images/family/recipes/clean-kettle/lemons.jpg'
import BOIL_WATER_WITH_LEMON_IMAGE from '@srcroot/assets/images/family/recipes/clean-kettle/boil-water-with-lemon.png'
import CLEAN_KETTLE_IMAGE from '@srcroot/assets/images/family/recipes/clean-kettle/clean-kettle.png'

import { greenText } from '@srcroot/utils/commonCss'

export const CleanKettle = () => {
  return (
    <>
      <h1>Clean Kettle naturally with Lemon</h1>

      <p>
        From{' '}
        <a
          href="https://www.housebeautiful.com/uk/lifestyle/cleaning/a32166936/before-after-photos-clean-descale-kettle-lemon-juice-hack/"
          target="_blank"
          rel="noreferrer"
        >
          how to descale kettle naturally using lemon juice
        </a>
      </p>

      <img width="500" src={LEMONS_IMAGE} alt="Lemons" />
      <img src={BOIL_WATER_WITH_LEMON_IMAGE} alt="Boil water with lemon" />

      <ol>
        <li>Cut lemon in half & squeeze it in kettle</li>
        <li>Fill up half of kettle with water (put the lemon halves in water)</li>
        <li>Leave water for 1 hour</li>
        <li>
          Boil water & throw away ={' '}
          <span className={greenText()}>
            <strong>clean kettle</strong>
          </span>
        </li>
      </ol>

      <img width="500" src={CLEAN_KETTLE_IMAGE} alt="Clean kettle" />
    </>
  )
}
