import DOCUMENTATION_QUADRANTS_IMAGE from '@srcroot/assets/images/jason/blog/2024/documentation-quadrants/documentation-quadrants.png'
import DIVIO_DOCUMENTATION_QUADRANTS_IMAGE from '@srcroot/assets/images/jason/blog/2024/documentation-quadrants/divio-documentation-quadrants.png'
import COLLAPSE_IMAGE from '@srcroot/assets/images/jason/blog/2024/documentation-quadrants/collapse.png'

import { Footer } from '@srcroot/components/footer'

import { grayText, greenText, redText } from '@srcroot/utils/commonCss'

export const BlogDocumentationQuadrants = () => {
  return (
    <>
      <h1 data-testid="blog-documentation-quadrants-header">Documentation Quadrants</h1>

      <h2 className={grayText()}>18 Mar 2024</h2>
      <p>
        Have you ever heard someone say:{' '}
        <span className={redText()}>I can&apos;t find anything in this documentation?</span>{' '}
        Potential solution = <strong>Documentation Quadrants</strong>
      </p>
      <p>
        At work, we recently had a{' '}
        <a
          href="https://www.thoughtworks.com/en-au/radar/techniques/documentation-quadrants"
          target="_blank"
          rel="noreferrer"
        >
          Thoughtworks Tech Radar
        </a>{' '}
        presentation that was pretty interesting. A colleague introduced us to a way of{' '}
        <span className={greenText()}>
          <strong>improving</strong>
        </span>{' '}
        documentation.
      </p>

      <img src={DOCUMENTATION_QUADRANTS_IMAGE} alt="Documentation Quadrants" />

      <p>
        This organises documentation into 4 universal <strong>categories</strong>, along{' '}
        <strong>2 axes</strong>.
      </p>
      <ol>
        <li>
          Y axes = <strong>nature</strong> of the information: is it practical or theoretical?
        </li>
        <li>
          X axes = context of <strong>use</strong>: are you studying or working?
        </li>
      </ol>
      <p>
        This is <strong>beneficial</strong> because:
      </p>
      <ol>
        <li>It helps readers to easier find documentation and understand the broader context</li>
        <li>It helps writers to think of the audience and purpose behind the documentation</li>
      </ol>

      <p>
        Originally developed by{' '}
        <a href="https://docs.divio.com/documentation-system/" target="_blank" rel="noreferrer">
          Divio
        </a>
        , Steve Dunn gives a{' '}
        <a
          href="https://dunnhq.com/posts/2023/documentation-quadrants/"
          target="_blank"
          rel="noreferrer"
        >
          good explanation
        </a>{' '}
        of what the nuances mean and how he used it effectively.
      </p>

      <h2>Divio diagram</h2>
      <img src={DIVIO_DOCUMENTATION_QUADRANTS_IMAGE} alt="Divio diagram" />

      <p>
        Beware the danger of <span className={redText()}>collapse</span>, where we&apos;re mixing up
        all the quadrants (discussion, reference, how to, tutorials) into one.
      </p>
      <img src={COLLAPSE_IMAGE} alt="Collapse" />

      <p>
        Although this is linked to <strong>Software</strong> documentation. I think it can be used
        for any documentation, as it&apos;s a good way of organisating documents in an intuitive
        way. I&apos;ve updated my Wiki to adopt this format. At work we&apos;ll adopt this for our
        team page as well.
      </p>

      <Footer />
    </>
  )
}
