import AUTH0_JWT_ISTIO_AUTHORISE_FLOW_IMAGE from '@srcroot/assets/images/jason/wiki/auth0/jwt-istio-authorize-flow.png'

import { Footer } from '@srcroot/components/footer'

import { grayText } from '@srcroot/utils/commonCss'

import { Link } from 'react-router-dom'

export const Tutorials = () => {
  return (
    <>
      <h1 data-testid="wiki-tutorials-header">Tutorials</h1>
      <h2>Learning oriented</h2>

      <div className="grid grid-cols-1 md:grid-cols-12 gap-5 my-4">
        <div className="md:col-span-6 flex justify-evenly">
          <div>
            <h2 className={grayText()}>How to use Auth0 token in rest call?</h2>
            <p>
              13 Nov 2022,{' '}
              <Link data-testid="auth0-connectivity-testing-link" to="auth0ConnectivityTesting">
                read more
              </Link>
            </p>
          </div>
        </div>
        <div className="md:col-span-6 flex justify-evenly">
          <img src={AUTH0_JWT_ISTIO_AUTHORISE_FLOW_IMAGE} alt="Auth0 JWT istio authorise flow" />
        </div>
      </div>
      <Footer />
    </>
  )
}
