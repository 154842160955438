import KUBERNETES_LOGO_IMAGE from '@srcroot/assets/images/jason/wiki/kubernetes/kubernetes-logo.png'
import KUBERNETES_MODULE_IMAGE from '@srcroot/assets/images/jason/wiki/kubernetes/kubernetes-modules.png'
import KUBERNETES_CLUSTER_IMAGE from '@srcroot/assets/images/jason/wiki/kubernetes/kubernetes-cluster.svg'

import { Footer } from '@srcroot/components/footer'

import { hyperlink, redText } from '@srcroot/utils/commonCss'

import { scrollToSection } from '@srcroot/utils/scrollToSection'

export const WikiKubernetesCheatSheet = () => {
  const installKubectlCommandOnLinux = `
# https://kubernetes.io/docs/tasks/tools/install-kubectl-linux/
$ sudo snap install kubectl --classic
$ kubectl version --client
    `

  const installKubeCtlCommandOnMac = `
# https://kubernetes.io/docs/tasks/tools/install-kubectl-macos/
$ brew install kubectl
$ kubectl version --client
    `

  const kubectlTroubleshootingCommands = `
// if you've changed AWS envs, this is useful to set your kube config to current env
$ aws eks --region ap-southeast-2 update-kubeconfig --name tap
    
$ kubectl -n digital get replicaset
// Useful for checking errors in replicaset
$ kubectl -n digital describe replicaset application-review-service
// Can use full name
$ kubectl -n digital describe replicaset application-review-service-xdfae
    
$ kubectl -n digital get deployments
// Useful for checking errors in deployments
$ kubectl -n digital describe deployments application-review-service
    
// schooler = CRD (custom resource definitions)
$ kubectl -n digital get schooner
$ kubectl -n digital describe schooner
$ kubectl -n digital describe schooner application-review-service
    
$ kubectl -n digital get pods
// Useful to find container logs that may show the error preventing pod from starting up
$ kubectl -n digital describe pods application-review-service
$ kubectl -n digital describe pods external-data-lookup
    
// Eg where application-review-service was failing due to party-db-cluster issue
$ kubectl -n digital logs -f application-review-service-5d9875d667-8dhjb -c digital-party-db-cluster-init
    
// Eg where external-data-lookup was failing as it could not login to vault using role, invalid role name
$ kubectl -n digital logs -f external-data-lookup-7bdc9b6ff6-78qll -c vault-static-secrets
$ kubectl -n digital logs -f -lapp=external-data-lookup -c vault-static-secrets
    
// shutdown pod (or application-review-avoka-integration)
$ kubectl scale deploy application-review-service --replicas=0
// restart pod
$ kubectl scale deploy application-review-service --replicas=2
    
$ kubectl -n digital get jobs
// Delete job (need admin access)
$ kubectl -n digital delete job {job-name}
    
// Login to pod
$ kubectl -n digital exec -it external-data-lookup-6f74f7c874-8cgdc /bin/sh
    
// Check Prometheus alerting of artefact
$ kubectl -n digital describe PrometheusRule auth0-tyro-customer-stream-subscribe    
    `

  const kubectlCheckAppLogs = `
$ kubectl -n=digital logs -f -lapp=existing-customer-apply-bff -c existing-customer-apply-bff
$ kubectl -n=digital logs -f -lapp=application-review-service -c application-review-service
$ kubectl -n=digital logs -f -lapp=application-review-avoka-integration -c application-review-avoka-integration
    
// ARS database schema migration
$ kubectl -n=digital logs -f -lapp=application-review-service-schema-migration -c application-review-service-schema-migration
    
// Check specific log
kubectl -n=digital logs -f application-review-service-db499b44f-l7wvw -c application-review-service    
    `

  return (
    <>
      <ol>
        <li className={hyperlink()}>
          <button
            data-testid="k8s-cheat-sheet-link"
            onClick={() => scrollToSection('#cheat-sheet')}
          >
            k8s cheat sheet
          </button>
        </li>
        <li className={hyperlink()}>
          <button
            data-testid="k8s-troubleshooting-link"
            onClick={() => scrollToSection('#useful-troubleshooting')}
          >
            Useful k8s troubleshooting
          </button>
        </li>
        <li className={hyperlink()}>
          <button
            data-testid="check-application-logs-link"
            onClick={() => scrollToSection('#check-logs')}
          >
            Check application logs
          </button>
        </li>
      </ol>

      <h1 data-testid="wiki-kubernetes-header">What is Kubernetes</h1>
      <h2>23 Oct 2022</h2>

      <p>
        Kubernetes (<b>k8s</b>), is an <b>open source</b> platform pioneered by <b>Google</b>, which
        started as a simple <b>container orchestration</b> tool but has grown into a{' '}
        <b>cloud native</b> platform.
      </p>

      <img width="400" height="200" src={KUBERNETES_LOGO_IMAGE} alt="Kubernetes logo" />

      <p>
        Container <b>orchestration</b> is about managing the <b>lifecycle</b> of containers,
        particularly in large, dynamic environments. It automates:
      </p>
      <ol>
        <li>deployment</li>
        <li>networking</li>
        <li>scaling</li>
        <li>availability of containerised workloads and services</li>
      </ol>

      <img src={KUBERNETES_MODULE_IMAGE} alt="Kubernetes modules" />

      <p>
        <a href="https://ubuntu.com/kubernetes/what-is-kubernetes" target="_blank" rel="noreferrer">
          What is k8s by Ubuntu
        </a>
      </p>
      <p>
        <a
          href="https://kubernetes.io/docs/tutorials/kubernetes-basics/"
          target="_blank"
          rel="noreferrer"
        >
          Learn k8s basics
        </a>
      </p>
      <p>
        <a
          href="https://enterprisersproject.com/article/2017/10/how-explain-kubernetes-plain-english"
          target="_blank"
          rel="noreferrer"
        >
          Explain k8s in plain english
        </a>
      </p>
      <p>
        <a
          href="https://www.redhat.com/en/topics/containers/what-is-kubernetes"
          target="_blank"
          rel="noreferrer"
        >
          What is k8s by RedHat
        </a>
      </p>
      <p>
        <a
          href="https://cloud.google.com/learn/what-is-kubernetes"
          target="_blank"
          rel="noreferrer"
        >
          What is k8s by Google
        </a>
      </p>

      <img src={KUBERNETES_CLUSTER_IMAGE} alt="Kubernetes cluster" />

      <hr />
      <h1 id="cheat-sheet" data-testid="cheat-sheet-anchor">
        k8s cheat sheet
      </h1>

      <h2>Installing kubectl on Linux</h2>
      <pre>{installKubectlCommandOnLinux}</pre>

      <h2>Installing kubectl on mac</h2>
      <pre>{installKubeCtlCommandOnMac}</pre>

      <h2 id="useful-troubleshooting" data-testid="useful-troubleshooting-anchor">
        Useful Kubernetes troubleshooting
      </h2>
      <p>
        <b>schooner</b> (Kubernetes Custom Resource Definition or <b>CRD</b> at our company) -&gt;
        deployment -&gt; replicaset -&gt; pods -&gt; containers -&gt; services, accounts, etc.
      </p>
      <p>
        If the pod is not up, there might be <span className={redText()}>useful error info</span> in
        describe <b>replicaset</b> or <b>deployment</b>
      </p>
      <p>
        Also useful to describe <b>pods</b> to find <b>dependent container logs</b> that may show{' '}
        <span className={redText()}>error preventing pod from starting up</span>
      </p>
      <pre>{kubectlTroubleshootingCommands}</pre>

      <h2 id="check-logs" data-testid="check-logs-anchor">
        Check application logs
      </h2>
      <pre>{kubectlCheckAppLogs}</pre>
      <Footer />
    </>
  )
}
