import ULTRA_MAGNUS_ROBOT_IMAGE from '@srcroot/assets/images/james/ultra-magnus/ultra-magnus-bot.png'
import ULTRA_MAGNUS_TRUCK_IMAGE from '@srcroot/assets/images/james/ultra-magnus/ultra-magnus-truck.png'
import ULTRA_MAGNUS_STATS_IMAGE from '@srcroot/assets/images/james/ultra-magnus/ultra-magnus-stats-big.png'

import { grayText } from '@srcroot/utils/commonCss'

export const TransformersUltraMagnus = () => {
  return (
    <>
      <h1 data-testid="transformers-ultra-magnus-header">Transformers: Ultra Magnus</h1>

      <h2 className={grayText()}>22 Sep 2022</h2>

      <div className="grid grid-cols-1 md:grid-cols-12 gap-5 my-4">
        <div className="md:col-span-6 flex justify-evenly">
          <img src={ULTRA_MAGNUS_ROBOT_IMAGE} alt="Ultra Magnus robot" />
        </div>
        <div className="md:col-span-6 flex justify-evenly">
          <img src={ULTRA_MAGNUS_TRUCK_IMAGE} alt="Ultra Magnus truck" />
        </div>
      </div>

      <h2>Why Ultra Magnus is my favourite Transformer?</h2>
      <p>
        Because he&apos;s <b>brave</b>, strong and is a <b>good leader</b>.
      </p>

      <h2>Profile</h2>
      <p>
        Ultra Magnus is all soldier. Most comfortable when carrying out Optimus Prime&apos;s orders.
        Possesses <b>magnificent</b> fighting skills, courage, and a gift for battlefield{' '}
        <b>improvisation</b>. Uncomfortable in the mantle of leadership, but presents strong profile
        as a commander.
      </p>
      <p>
        Carries missile launchers capable of hitting a target 30 miles away. Resolute, fair, and
        courageous beyond reproach. Ever ready to sacrifice himself for the good of men and mission.
      </p>

      <h2>Stats</h2>
      <img src={ULTRA_MAGNUS_STATS_IMAGE} alt="Ultra Magnus stats" />
    </>
  )
}
