import { hyperlink } from '@srcroot/utils/commonCss'
import { scrollToSection } from '@srcroot/utils/scrollToSection'

export const TableOfContents = () => {
  return (
    <>
      <ol>
        <li>Competitions</li>
        <ol>
          <li className={hyperlink()}>
            <button
              data-testid="soccer-competitions-link"
              onClick={() => scrollToSection('#soccer-competitions')}
            >
              Country soccer leagues
            </button>
          </li>
          <li className={hyperlink()}>
            <button
              data-testid="fifa-world-cup-link"
              onClick={() => scrollToSection('#fifa-world-cup')}
            >
              FIFA world cup
            </button>
          </li>
          <li className={hyperlink()}>
            <button
              data-testid="fifa-world-club-cup-link"
              onClick={() => scrollToSection('#fifa-world-club-cup')}
            >
              FIFA club world cup
            </button>
          </li>
        </ol>
        <li className={hyperlink()}>
          <button
            data-testid="personal-training-link"
            onClick={() => scrollToSection('#personal-training')}
          >
            Personal Training
          </button>
        </li>
        <li className={hyperlink()}>
          <button data-testid="basic-skills-link" onClick={() => scrollToSection('#basic-skills')}>
            Basic Skills
          </button>
        </li>
        <li className={hyperlink()}>
          <button data-testid="dribbling-link" onClick={() => scrollToSection('#dribbling')}>
            Dribbling
          </button>
        </li>
        <li className={hyperlink()}>
          <button data-testid="top-players-link" onClick={() => scrollToSection('#top-players')}>
            Top Players and Skills
          </button>
        </li>
        <li className={hyperlink()}>
          <button data-testid="shooting-link" onClick={() => scrollToSection('#shooting')}>
            Shooting
          </button>
        </li>
        <li className={hyperlink()}>
          <button data-testid="midfield-link" onClick={() => scrollToSection('#midfield')}>
            Mid Field
          </button>
        </li>
        <li className={hyperlink()}>
          <button data-testid="defending-link" onClick={() => scrollToSection('#defending')}>
            Defending
          </button>
        </li>
      </ol>
    </>
  )
}
