import CHRISTIANO_RONALDO_IMAGE from '@srcroot/assets/images/family/soccer/christiano-ronaldo.png'
import LIONEL_MESSI_IMAGE from '@srcroot/assets/images/family/soccer/lionel-messi.png'
import NEYMAR_DA_SILVA_IMAGE from '@srcroot/assets/images/family/soccer/neymar-da-silva-santos-jnr.png'

import { greenText } from '@srcroot/utils/commonCss'

export const TopPlayers = () => {
  return (
    <>
      <h1 id="top-players">Top players in the world</h1>
      <p>
        <a
          href="https://www.radiotimes.com/tv/sport/football/best-football-players-world/"
          target="_blank"
          rel="noreferrer"
        >
          Top player rankings
        </a>{' '}
        for 2022
      </p>
      <ol>
        <li>Lionel Messi = Paris St Germaine (French League)</li>
        <li>Robert Lewandowski = Barcelona (Spanish League)</li>
        <li>Kevin De Bruyne = Mancester City (English League)</li>
        <li>Kylian Mbappe = Paris St Germaine (French League)</li>
        <li>Erling Haaland = Mancester City (English League)</li>
        <li>Harry Kane = Tottenham (English League)</li>
        <li>Mohamed Salah = Liverpool (English League)</li>
        <li>Karim Benzema = Real Madrid (Spanish League)</li>
        <li>Sadio Mane = Bayern Munich (German League)</li>
        <li>Cristiano Ronaldo = Manchester United (English League)</li>
        <li>Neymar = Paris St Germaine (French League)</li>
        <li>Trent Alexander-Arnold = Liverpool (English League)</li>
      </ol>

      <h2>Top player skills</h2>

      <div className="grid grid-cols-1 md:grid-cols-12 gap-5 my-4">
        <div className="md:col-span-4 flex justify-evenly">
          <img src={CHRISTIANO_RONALDO_IMAGE} alt="Christiano Ronaldo" />
        </div>
        <div className="md:col-span-4 flex justify-evenly">
          <img src={LIONEL_MESSI_IMAGE} alt="Lionel Messi" />
        </div>
        <div className="md:col-span-4 flex justify-evenly">
          <img src={NEYMAR_DA_SILVA_IMAGE} alt="Neymar da Silva Santos Junior" />
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-12 gap-5 my-4">
        <div className="md:col-span-4 flex justify-evenly">
          <p className={greenText()}>Christiano Ronaldo (CR7)</p>
        </div>
        <div className="md:col-span-4 flex justify-evenly">
          <p className={greenText()}>Lionel Messi</p>
        </div>
        <div className="md:col-span-4 flex justify-evenly">
          <p className={greenText()}>Neymar da Silver Santos Junior</p>
        </div>
      </div>

      <p>
        Top{' '}
        <a href="https://www.youtube.com/watch?v=qsNKYgx6KCg" target="_blank" rel="noreferrer">
          CR7
        </a>{' '}
        skills ~ 7m
      </p>
      <ol>
        <li>elastico meg</li>
        <li>ronaldo chop</li>
        <li>1 foot Mcgeady</li>
        <li>step overs</li>
        <li>double chop</li>
        <li>drag & step over</li>
        <li>outside heel toe</li>
        <li>fake cross</li>
        <li>roll top chop</li>
        <li>foot hover</li>
      </ol>

      <p>
        Top{' '}
        <a href="https://www.youtube.com/watch?v=N-TuSZtyqPY" target="_blank" rel="noreferrer">
          Messi
        </a>{' '}
        skills ~ 8m
      </p>
      <ol>
        <li>the lift</li>
        <li>chop cuts</li>
        <li>stop & turn</li>
        <li>fake shots</li>
        <li>double cut</li>
        <li>la croquetta</li>
        <li>the L cut</li>
        <li>toe poke meg</li>
        <li>drop your shoulder</li>
        <li>referee block</li>
      </ol>

      <p>
        Top 10{' '}
        <a href="https://www.youtube.com/watch?v=jUUo-m8NksE" target="_blank" rel="noreferrer">
          Neymar
        </a>{' '}
        skills ~ 7m
      </p>
      <ol>
        <li>roll chop</li>
        <li>pull behind & go</li>
        <li>roll top meg</li>
        <li>off back leg</li>
        <li>drag & go</li>
        <li>shakey leg</li>
        <li>neymar rocket</li>
        <li>sombrero lift</li>
        <li>lift down the line</li>
        <li>behind the leg</li>
      </ol>
    </>
  )
}
