import TRAINING_TIME_IMAGE from '@srcroot/assets/images/family/soccer/training-time.png'
import SKILL_AREAS_IMAGE from '@srcroot/assets/images/family/soccer/skill-areas.png'
import SKILL_BEAT_GOAL_KEEPER_IMAGE from '@srcroot/assets/images/family/soccer/beat-goal-keeper.png'
import SKILL_KEEP_ARM_READY_IMAGE from '@srcroot/assets/images/family/soccer/keep-arm-ready.png'
import DRILL_PULL_BEHIND_LEG_IMAGE from '@srcroot/assets/images/family/soccer/drill-pull-behind-leg.png'
import DRILL_INSIDE_OUTSIDE_IMAGE from '@srcroot/assets/images/family/soccer/drill-inside-outside.png'
import CONES_INSIDE_OUTSIDE_IMAGE from '@srcroot/assets/images/family/soccer/cones-inside-outside.png'
import SKILL_SPEED_ELASTIC_IMAGE from '@srcroot/assets/images/family/soccer/skills-speed-elastic.png'
import HOME_CHAIR_MEGS_IMAGE from '@srcroot/assets/images/family/soccer/home-chair-megs.png'
import LEARN_JUGGLING_IMAGE from '@srcroot/assets/images/family/soccer/learn-juggling.png'

import { greenText } from '@srcroot/utils/commonCss'

export const Training = () => {
  return (
    <>
      <h1 id="personal-training">Personal training</h1>
      <img src={TRAINING_TIME_IMAGE} alt="Personal training time" />
      <div>
        <p>
          Training with team is not enough, you need to develop <b>individual skills</b> along with
          team training set:{' '}
          <a href="https://www.youtube.com/watch?v=DQVJhQd8QZ4" target="_blank" rel="noreferrer">
            personal training
          </a>
          ~ 5m
        </p>
        <ul>
          <li>start seeing improvements ~ 30m / day</li>
          <li>
            competing in <b>high level teams</b> ~ 2hrs / day
          </li>
          <li>
            <b>professional</b> ~ 5hrs / day
          </li>
        </ul>
      </div>

      <img src={SKILL_AREAS_IMAGE} alt="Skill areas" />
      <div>
        <p>
          Training plan in <b>4 skill areas</b>
        </p>
        <ol>
          <li>Dribbling</li>
          <li>Passing / receiving</li>
          <li>Shooting</li>
          <li>Fitness</li>
        </ol>
      </div>

      <hr />

      <h1 id="basic-skills">Basic skills</h1>

      <div className="grid grid-cols-1 md:grid-cols-12 gap-5 my-4">
        <div className="md:col-span-6 flex justify-evenly">
          <img src={SKILL_BEAT_GOAL_KEEPER_IMAGE} alt="Skill: beat goal keeper" />
        </div>
        <div className="md:col-span-6 flex justify-evenly">
          <img src={SKILL_KEEP_ARM_READY_IMAGE} alt="Skill: keep arm ready" />
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-12 gap-5 my-4">
        <div className="md:col-span-6 flex justify-evenly">
          <p className={greenText()}>Beat Goal Keeper</p>
        </div>
        <div className="md:col-span-6 flex justify-evenly">
          <p className={greenText()}>Keep Arm Ready</p>
        </div>
      </div>

      <p>
        Five most{' '}
        <a href="https://www.youtube.com/watch?v=cpIwMZ3cUEc" target="_blank" rel="noreferrer">
          basic skills
        </a>{' '}
        for beginners ~ 7m
      </p>
      <ol>
        <li>
          <b>beating the goalkeeper</b>
        </li>
        <li>passing & receiving</li>
        <li>controlling more difficult balls</li>
        <li>
          taking the ball with you (<b>dribbling</b>)
        </li>
        <li>protecting the ball</li>
      </ol>

      <p>
        Ten best skills for{' '}
        <a href="https://www.youtube.com/watch?v=5fl248V4tIU" target="_blank" rel="noreferrer">
          beginner players
        </a>{' '}
        ~ 9m
      </p>
      <ol>
        <li>basic receiving</li>
        <li>basic passing</li>
        <li>simple dribbling (take the ball with you)</li>
        <li>cuts (change direction)</li>
        <li>pull back (protecting the ball)</li>
        <li>cut back</li>
        <li>switch feet (la croquetta)</li>
        <li>control with top of foot (control high balls)</li>
        <li>lofted pass</li>
        <li>shoot for the corners</li>
        <li>fake kick</li>
      </ol>

      <p>
        <a href="https://www.youtube.com/watch?v=geIYxdG0WVQ" target="_blank" rel="noreferrer">
          Seven habits
        </a>{' '}
        that will make you a great soccer player ~ 8m
      </p>
      <ol>
        <li>
          <b>keep your arm ready</b>
        </li>
        <li>head up after dribbling</li>
        <li>sleep</li>
        <li>warm up</li>
        <li>look around</li>
        <li>ignore trash talk</li>
        <li>constantly be on the move</li>
        <li>check the offside line</li>
      </ol>

      <hr />

      <h1 id="dribbling">Dribbling</h1>

      <div className="grid grid-cols-1 md:grid-cols-12 gap-5 my-4">
        <div className="md:col-span-6 flex justify-evenly">
          <img src={DRILL_PULL_BEHIND_LEG_IMAGE} alt="Drill: pull behind leg" />
        </div>
        <div className="md:col-span-6 flex justify-evenly">
          <img src={DRILL_INSIDE_OUTSIDE_IMAGE} alt="Drill: inside outside" />
        </div>
      </div>

      <p>
        Ten best{' '}
        <a href="https://www.youtube.com/watch?v=5CdThq1KAlI" target="_blank" rel="noreferrer">
          footwork exercises
        </a>{' '}
        for <b>fast feet</b>~ 10m = practise footwork ~<b>30m / day</b>
      </p>
      <ol>
        <li>
          <b>pull behind leg</b>
        </li>
        <li>puskas</li>
        <li>inside feet (la croquetta)</li>
        <li>drag & slide (la croquetta)</li>
        <li>
          <b>inside outside (elastico)</b>
        </li>
        <li>outside feet</li>
        <li>roll between feet</li>
        <li>outside step overs</li>
        <li>inside step overs</li>
        <li>ronaldo roll</li>
      </ol>

      <p>
        Get{' '}
        <a href="https://www.youtube.com/watch?v=nHxClhfQjlM" target="_blank" rel="noreferrer">
          faster feet
        </a>{' '}
        in 10 mins ~ 12m. Extras
      </p>
      <ol>
        <li>outside in</li>
        <li>side to side roll</li>
        <li>roll & stop</li>
        <li>roll to inside</li>
      </ol>

      <div className="grid grid-cols-1 md:grid-cols-12 gap-5 my-4">
        <div className="md:col-span-6 flex justify-evenly">
          <img src={CONES_INSIDE_OUTSIDE_IMAGE} alt="Cones: inside outside" />
        </div>
        <div className="md:col-span-6 flex justify-evenly">
          <img src={SKILL_SPEED_ELASTIC_IMAGE} alt="Skills: speed elastic" />
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-12 gap-5 my-4">
        <div className="md:col-span-6 flex justify-evenly">
          <p className={greenText()}>Inside Outside</p>
        </div>
        <div className="md:col-span-6 flex justify-evenly">
          <p className={greenText()}>Speed Elastic</p>
        </div>
      </div>

      <p>
        10 best{' '}
        <a href="https://www.youtube.com/watch?v=5yKpaP5XFK8" target="_blank" rel="noreferrer">
          dribbling exercises
        </a>{' '}
        for control ~ 10m
      </p>
      <ol>
        <li>
          <b>inside outside</b>
        </li>
        <li>outside feet</li>
        <li>inside feet (la croqetta)</li>
        <li>roll to inside</li>
        <li>pyramid</li>
        <li>try turn</li>
        <li>cluster</li>
        <li>turn away</li>
        <li>1 vs 1</li>
        <li>speed dribbling</li>
      </ol>

      <p>
        15{' '}
        <a href="https://www.youtube.com/watch?v=L8taGwfp2sU" target="_blank" rel="noreferrer">
          skill moves
        </a>{' '}
        ~ 7m
      </p>
      <ol>
        <li>outside step over</li>
        <li>Mcgeady spin</li>
        <li>Berbatov spin</li>
        <li>
          <b>speed elastic</b>
        </li>
        <li>the V</li>
        <li>pull behind leg</li>
        <li>feints</li>
        <li>inside step over</li>
        <li>stop & go</li>
        <li>scoop turn</li>
        <li>Maradonna spin</li>
        <li>outside heel toe</li>
        <li>Ronaldo chop</li>
        <li>elastico</li>
        <li>roll to heel</li>
      </ol>

      <div className="grid grid-cols-1 md:grid-cols-12 gap-5 my-4">
        <div className="md:col-span-6 flex justify-evenly">
          <img src={HOME_CHAIR_MEGS_IMAGE} alt="Home chair megs" />
        </div>
        <div className="md:col-span-6 flex justify-evenly">
          <img src={LEARN_JUGGLING_IMAGE} alt="Learn juggling" />
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-12 gap-5 my-4">
        <div className="md:col-span-6 flex justify-evenly">
          <p className={greenText()}>Chair Megs</p>
        </div>
        <div className="md:col-span-6 flex justify-evenly">
          <p className={greenText()}>Learn juggling</p>
        </div>
      </div>

      <p>
        Easy ways to play{' '}
        <a href="https://www.youtube.com/watch?v=ocH8f6szQew" target="_blank" rel="noreferrer">
          football
        </a>{' '}
        at home ~ 7m
      </p>
      <ol>
        <li>laundry goals</li>
        <li>mattress goals</li>
        <li>line dribbling</li>
        <li>dog defender</li>
        <li>obstacle course</li>
        <li>stair juggling</li>
        <li>
          <b>chair megs</b>
        </li>
        <li>footwork</li>
        <li>bin passing</li>
        <li>tape speed ladder</li>
        <li>juggling</li>
        <li>skill moves</li>
        <li>stair control</li>
        <li>head stall</li>
        <li>target practise</li>
      </ol>

      <p>
        How to{' '}
        <a href="https://www.youtube.com/watch?v=SxVaMcHqcoU" target="_blank" rel="noreferrer">
          juggle
        </a>{' '}
        a soccer ball for beginners ~ 8m
      </p>
      <ol>
        <li>drop to thigh</li>
        <li>drop to feet</li>
        <li>
          <b>flick up</b>
        </li>
        <li>alternate feet</li>
        <li>juggle with a partner</li>
        <li>don&apos;t give up</li>
        <li>basketball hoop challenge</li>
        <li>7 point challenge</li>
      </ol>
    </>
  )
}
