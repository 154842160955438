import { grayText } from '@srcroot/utils/commonCss'

export const Covid = () => {
  return (
    <>
      <h2 className={grayText()}>1 Nov 2022</h2>
      <h1 data-testid="covid-header">Covid numbers</h1>
      <p>
        <a href="https://covidlive.com.au/" target="_blank" rel="noreferrer">
          Australia
        </a>
      </p>
      <p>
        <a
          href="https://gisanddata.maps.arcgis.com/apps/dashboards/bda7594740fd40299423467b48e9ecf6"
          target="_blank"
          rel="noreferrer"
        >
          Globally
        </a>
      </p>

      <h1>NSW Health fact sheets</h1>
      <p>
        Stay covid{' '}
        <a href="https://www.nsw.gov.au/covid-19/stay-safe" target="_blank" rel="noreferrer">
          safe
        </a>
      </p>
      <p>
        What to do after{' '}
        <a
          href="https://www.nsw.gov.au/covid-19/management/advice-for-confirmed"
          target="_blank"
          rel="noreferrer"
        >
          testing positive
        </a>{' '}
        for Covid-19
      </p>

      <hr />

      <h1>Covid school rules post 14 Oct 2022</h1>
      <p>
        As of <b>14 October 2022</b> it is no longer mandatory to <b>self-isolate</b> if someone
        tests <b>positive</b> for COVID-19, but it is <b>recommended</b> to
      </p>
      <ol>
        <li>stay home</li>
        <li>take steps to protect others</li>
      </ol>
      <p>
        Students, staff and visitors should only attend school when they are <b>symptom-free</b>. We
        will continue to send home students or staff displaying symptoms and follow COVID-smart
        measures to help reduce the risk of transmission.
      </p>
      <h2>Symptons</h2>
      <p>
        If staff or students test positive for COVID-19, they are to <b>stay home</b> until their{' '}
        <b>symptoms</b> have gone. This includes:
      </p>
      <ol>
        <li>fever</li>
        <li>coughing</li>
        <li>sore throat</li>
        <li>shortness of breath</li>
        <li>runny nose or congestion</li>
        <li>headache or fatigue</li>
        <li>muscle or joint pains</li>
        <li>nausea or loss of appetite</li>
        <li>diarrhoea or vomiting</li>
        <li>temporary loss of smell or altered sense of taste</li>
      </ol>
      <h2>Higher Risk 7 day isolation</h2>
      <p>
        Due to the <b>higher risk</b> associated with some school settings and activities, the{' '}
        <b>7-day isolation</b> period will remain for staff, students and visitors who have tested
        positive to COVID-19 and work at or attend:
      </p>
      <ol>
        <li>specialist centres</li>
        <li>support units</li>
        <li>assisted transport</li>
      </ol>
    </>
  )
}
