import EMAIL_IMAGE from '@srcroot/assets/images/home/email.png'
import LINKED_IN_IMAGE from '@srcroot/assets/images/home/linkedin.png'
import GITHUB_IMAGE from '@srcroot/assets/images/home/github.png'
import TWITTER_IMAGE from '@srcroot/assets/images/home/twitter.png'

export const Footer = () => {
  return (
    <>
      <footer data-testid="footer" className="mb-6">
        <div className="flex justify-center">
          <a
            data-testid="footer-email-link"
            className="w-16 h-auto pt-2"
            href="mailto:jasonsoooz@yahoo.com"
            target="_blank"
            rel="noreferrer"
          >
            <img src={EMAIL_IMAGE} alt="email" />
          </a>
          <a
            className="pl-3"
            data-testid="footer-linked-in-link"
            href="https://au.linkedin.com/in/jason-soo-43b6364"
            target="_blank"
            rel="noreferrer"
          >
            <img src={LINKED_IN_IMAGE} alt="linked-in" />
          </a>
          <a
            data-testid="footer-github-link"
            href="https://github.com/jasonsoooz"
            target="_blank"
            rel="noreferrer"
          >
            <img src={GITHUB_IMAGE} alt="github" />
          </a>
          <a
            data-testid="footer-twitter-link"
            href="https://twitter.com/jasonsoooz"
            target="_blank"
            rel="noreferrer"
          >
            <img src={TWITTER_IMAGE} alt="twitter" />
          </a>
        </div>
      </footer>
    </>
  )
}
