import CURRY_CHICKEN_IMAGE from '@srcroot/assets/images/family/recipes/curry-chicken/chicken-curry-11-768x1152.jpg'
import UNITED_KINGDOM_FLAG_IMAGE from '@srcroot/assets/images/family/recipes/Flag_of_the_United_Kingdom-300x150.png'

import { redText } from '@srcroot/utils/commonCss'

export const ThermomixCurryChicken = () => {
  return (
    <>
      <h1>Thermomix Curry chicken</h1>
      <img src={CURRY_CHICKEN_IMAGE} alt="Curry chicken" />
      <br />

      <img width="120" height="80" src={UNITED_KINGDOM_FLAG_IMAGE} alt="United Kingdom flag" />

      <h1>Ingredients</h1>
      <p>10 portion(s)</p>
      <h2>Marinating Chicken</h2>
      <ol>
        <li>
          600 g Chicken, (cut into bite sized piece and marinate with the following ingredients)
        </li>
        <li>20 g Light Sauce</li>
        <li>30 g Curry Powder</li>
        <li>1 tea spoon (tsp) Salt</li>
      </ol>

      <h2>Ingredients A</h2>
      <ol>
        <li>30 g Shallots</li>
        <li>30 g Garlic</li>
        <li>15 g Dried Chillies, (cut into small piece and soaked with hot water)</li>
        <li>20 g Water</li>
        <li>10 g Olive Oil</li>
      </ol>

      <h2>Ingredients B</h2>
      <ol>
        <li>20 g Olive Oil</li>
        <li>3 Potatoes (cut into pieces)</li>
        <li>200 g Water</li>
        <li>400 g Coconut Milk</li>
      </ol>

      <hr />

      <h1>Preparation</h1>
      <p>
        <b>MC</b> means Measuring Cup. Curry Chicken by Chef with Love
      </p>
      <ol>
        <li>
          Place all <b>ingredients A</b> into TM bowl to blend. <b>MC / 1 minute / Speed 10</b>
        </li>
        <li>
          Add in olive oil and potatoes to sautee.
          <b>MC / 3 minutes / 100°c / counterclock image Speed 1</b>
        </li>
        <li>
          Add in water and marinated chicken to cook.
          <b>MC / 15 minutes / 100°c / counterclock image Speed 1</b>{' '}
          <span className={redText()}>
            (PUT FIRST THE POTATOES SOME TIME BECAUSE JUST THAT THEY WERE HARD)
          </span>
        </li>
        <li>
          When the cooking time remains <b>last 5 mintues</b>, add in <b>coconut milk</b>.
        </li>
      </ol>
    </>
  )
}
