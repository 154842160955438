import SHOOT_LEAN_FORWARD_IMAGE from '@srcroot/assets/images/family/soccer/shoot-lean-forward.png'
import SHOOT_POWER_SHOT_IMAGE from '@srcroot/assets/images/family/soccer/shoot-power-shot.png'
import SHOOT_WITH_CURVE_IMAGE from '@srcroot/assets/images/family/soccer/shoot-with-curve.png'

import { greenText } from '@srcroot/utils/commonCss'

export const Shooting = () => {
  return (
    <>
      <h1 id="shooting">Shooting</h1>

      <div className="grid grid-cols-1 md:grid-cols-12 gap-5 my-4">
        <div className="md:col-span-6 flex justify-evenly">
          <img src={SHOOT_LEAN_FORWARD_IMAGE} alt="Shoot: lean forward" />
        </div>
        <div className="md:col-span-6 flex justify-evenly">
          <img src={SHOOT_POWER_SHOT_IMAGE} alt="Shoot: power shot" />
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-12 gap-5 my-4">
        <div className="md:col-span-6 flex justify-evenly">
          <p className={greenText()}>Lean Forward</p>
        </div>
        <div className="md:col-span-6 flex justify-evenly">
          <p className={greenText()}>Power</p>
        </div>
      </div>

      <p>
        7 ways to improve{' '}
        <a href="https://www.youtube.com/watch?v=uSPCV-w2Y7I" target="_blank" rel="noreferrer">
          shooting for beginners
        </a>{' '}
        ~ 8m
      </p>
      <ol>
        <li>hitting the center</li>
        <li>
          <b>lean forward</b> (head over top of the ball)
        </li>
        <li>placement over power</li>
        <li>line up ball at angle (not directly in front)</li>
        <li>adjust for bounces</li>
        <li>stork volley</li>
      </ol>

      <p>
        <a href="https://www.youtube.com/watch?v=GqHSWptUONI" target="_blank" rel="noreferrer">
          8 best shooting
        </a>{' '}
        techniques in soccer ~ 7m
      </p>
      <ol>
        <li>
          <b>power</b> (top of foot, straight through center)
        </li>
        <li>swaz (top of foot, strike across back of ball)</li>
        <li>lob (lift upwards)</li>
        <li>chip</li>
        <li>curve (top & inside of foot, strike along side of ball, low then upward)</li>
        <li>
          trivela (top outside part of foot, strike through side of ball, hitting lower part of ball
        </li>
        <li>lofted shot</li>
        <li>pass shot (inside of foot)</li>
      </ol>

      <img src={SHOOT_WITH_CURVE_IMAGE} alt="Shoot: with curve" />
      <p>
        How to{' '}
        <a href="https://www.youtube.com/watch?v=xIcUvrTn7FE" target="_blank" rel="noreferrer">
          shoot with curve
        </a>{' '}
        ~ 6m
      </p>
      <ol>
        <li>technique (side spin)</li>
        <li>power (between top & inside of foot, striking low then up)</li>
        <li>practise</li>
        <ul>
          <li>start with inside of foot</li>
          <li>get better, move to top of foot</li>
        </ul>
        <li>game situations: curve around defender</li>
        <li>difficult shooting angles</li>
        <li>reverse to near post</li>
        <li>free kicks, crosses, corners</li>
      </ol>
    </>
  )
}
