import HENSLEY_ATHLETICS_FIELD_IMAGE from '@srcroot/assets/images/family/athletics/hensley-athletics-field.png'
import KING_GEORGES_OVAL_IMAGE from '@srcroot/assets/images/family/athletics/king-georges-oval.png'

import { greenText } from '@srcroot/utils/commonCss'

export const AthleticsTracksAroundSydney = () => {
  return (
    <>
      <h1>Athletics tracks around Sydney</h1>

      <div className="grid grid-cols-1 md:grid-cols-12 gap-5 my-4">
        <div className="md:col-span-6 flex justify-evenly">
          <img src={HENSLEY_ATHLETICS_FIELD_IMAGE} alt="Hensley Athletics Field" />
        </div>
        <div className="md:col-span-6 flex justify-evenly">
          <img src={KING_GEORGES_OVAL_IMAGE} alt="King George's Oval" />
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-12 gap-5 my-4">
        <div className="md:col-span-6 flex justify-evenly">
          <p className={greenText()}>Hensley Athletics Field</p>
        </div>
        <div className="md:col-span-6 flex justify-evenly">
          <p className={greenText()}>King George&apos;s Oval</p>
        </div>
      </div>

      <p>
        Find{' '}
        <a href="https://tracks.insideathletics.com.au/" target="_blank" rel="noreferrer">
          Athletics tracks
        </a>{' '}
        around Australia
      </p>
      <ol>
        <li>
          <a href="https://www.bayside.nsw.gov.au/" target="_blank" rel="noreferrer">
            Hensley Athletics
          </a>{' '}
          (Pagewood): synthetic. Mon - Fri 8:30am - 4:30pm, Sat 9am - 1pm, Sun closed
        </li>
        <li>
          <a
            href="https://www.centennialparklands.com.au/visit/our-parks/moore-park/e-s-marks-athletics-field"
            target="_blank"
            rel="noreferrer"
          >
            ES Marks Athletics
          </a>{' '}
          (Kensington): synthetic. Mon - Thu 3:30 - 8:30pm
        </li>
        <li>
          <a
            href="https://www.innerwest.nsw.gov.au/explore/parks-sport-and-recreation/sporting-grounds/king-george-park"
            target="_blank"
            rel="noreferrer"
          >
            King George&apos;s Park
          </a>{' '}
          (Rozelle): grass, open 24hrs, 7 days a week
        </li>
      </ol>
    </>
  )
}
