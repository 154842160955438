import WHITE_BEANS_AND_RICE_IMAGE from '@srcroot/assets/images/family/recipes/white-beans-and-rice/large_louisiana-white-beans-rice.jpg'
import SPANISH_FLAG_IMAGE from '@srcroot/assets/images/family/recipes/Flag_of_Spain-300x200.png'

export const ThermomixWhiteBeansAndRice = () => {
  return (
    <>
      <h1>Thermomix White Beans and rice</h1>
      <img src={WHITE_BEANS_AND_RICE_IMAGE} alt="White beans and rice" />
      <br />
      <img width="120" height="80" src={SPANISH_FLAG_IMAGE} alt="Spanish flag" />

      <h1>Ingredientes</h1>
      <ol>
        <li>100 gramos judías blancas secas</li>
        <li>70 gramos de arroz blanco cocido</li>
        <li>1 diente de ajo</li>
        <li>1 tomate maduro</li>
        <li>15 gramos aceite de oliva virgen extra</li>
        <li>1/2 pimiento verde</li>
        <li>1/2 pimiento rojo</li>
        <li>1 cebolla</li>
        <li>1 pastilla de caldo vegetal</li>
        <li>1 hoja de laurel</li>
        <li>1 poco de oregano</li>
        <li>1 pellizco de sal</li>
        <li>media cucharadita pequeña de pimenton dulce</li>
        <li>1 Patata pequeña troceada</li>
        <li>1 zanahoria</li>
      </ol>

      <hr />

      <h1>Preparación</h1>
      <ol>
        <li>
          Ponemos el aceite de oliva, <b>4 minutos / temperatura varoma</b> / giro izquierda /{' '}
          <b>velocidad cuchara</b>.
        </li>
        <li>
          Picamos la cebolla, el ajo <b>5 segundos / velocidad 5</b>, ponemos el medio pimiento
          verde, y rojo y el tomate sin retirar la cebolla, y picamos{' '}
          <b>4 segundos / velocidad 5</b>, bajamos todo con la espátula y programamos{' '}
          <b>5 minutos / temperatura varoma</b> / giro izquierda / <b>velocidad cuchara</b>.
        </li>
        <li>
          Ponemos las judías blancas, la zanahoria en ruedas, añadimos la hoja de laurel, el
          pimentón dulce, la pastilla de caldo vegetal, el orégano, la patata troceada y cubrimos
          aproximadamente 3 dedos de agua, y programamos <b>15 minutos / varoma</b> / giro izquierda
          / <b>cuchara</b>.
        </li>
        <li>
          Añadimos el arroz, y un pellizco de sal, mas vale ponerle de menos, y luego ir probando,
          que no pasarse, si vemos que tenemos el guiso espeso añadimos un dedo mas de agua, al
          gusto. Programamos <b>15 minutos / varoma</b> / giro izquierda / <b>cuchara</b>
        </li>
      </ol>
    </>
  )
}
