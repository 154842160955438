import AUSTRALIAN_A_LEAGUE_IMAGE from '@srcroot/assets/images/family/soccer/aus-a-league-logo.png'
import SPAIN_LA_LIGA_IMAGE from '@srcroot/assets/images/family/soccer/spain-la-liga-logo.png'
import ENGLISH_PREMIER_LEAGUE_IMAGE from '@srcroot/assets/images/family/soccer/english-premier-leaque-logo.png'
import ITALIAN_SERIES_A_IMAGE from '@srcroot/assets/images/family/soccer/italian-serie-a-logo.png'
import GERMANY_BUNDESLIGA_IMAGE from '@srcroot/assets/images/family/soccer/germany-bundesliga-logo.png'
import FRANCE_LIGUE_1_IMAGE from '@srcroot/assets/images/family/soccer/france-lique1-logo.png'

import FIFA_WORLD_CUP_IMAGE from '@srcroot/assets/images/family/soccer/fifa-world-cup.png'
import SOCCER_CONFEDERATIONS_IMAGE from '@srcroot/assets/images/family/soccer/soccer-confederations.png'
import FIFA_CLUB_WORLD_CUP_IMAGE from '@srcroot/assets/images/family/soccer/fifa-club-world-cup.png'

import { grayText } from '@srcroot/utils/commonCss'

export const SoccerCompetitions = () => {
  return (
    <>
      <h1 id="soccer-competitions">Country soccer leagues (strongest club in country)</h1>

      <div className="grid grid-cols-1 md:grid-cols-12 gap-5 my-4">
        <div className="md:col-span-4 flex justify-evenly">
          <img width="90" height="90" src={AUSTRALIAN_A_LEAGUE_IMAGE} alt="Australia A league" />
          <img width="100" height="100" src={SPAIN_LA_LIGA_IMAGE} alt="Spain La Liga" />
        </div>
        <div className="md:col-span-4 flex justify-evenly">
          <img
            width="150"
            height="100"
            src={ENGLISH_PREMIER_LEAGUE_IMAGE}
            alt="English Premier League"
          />
          <img width="100" height="110" src={ITALIAN_SERIES_A_IMAGE} alt="Italia Serie A" />
        </div>
        <div className="md:col-span-4 flex justify-evenly">
          <img width="100" height="100" src={GERMANY_BUNDESLIGA_IMAGE} alt="Germany Bundesliga" />
          <img width="100" height="100" src={FRANCE_LIGUE_1_IMAGE} alt="France Lique 1" />
        </div>
      </div>

      <ol>
        <li>
          Australian <b>A League</b> (12 teams, started 2005):{' '}
          <a
            data-testid="australian-leaque-espn-link"
            href="https://www.espn.com.au/football/league/_/name/aus.1"
            target="_blank"
            rel="noreferrer"
          >
            ESPN
          </a>
          ,{' '}
          <a
            data-testid="australian-leaque-wiki-link"
            href="https://en.wikipedia.org/wiki/A-League_Men"
            target="_blank"
            rel="noreferrer"
          >
            wiki
          </a>
        </li>
        <li>
          Spanish League, <b>La Liga</b> (20 teams, started 1929, Optus Sports):{' '}
          <a
            data-testid="spanish-leaque-espn-link"
            href="https://www.espn.com.au/football/league/_/name/esp.1"
            target="_blank"
            rel="noreferrer"
          >
            ESPN
          </a>
          ,{' '}
          <a
            data-testid="spanish-leaque-wiki-link"
            href="https://en.wikipedia.org/wiki/La_Liga"
            target="_blank"
            rel="noreferrer"
          >
            wiki
          </a>
        </li>
        <li>
          English <b>Premier League</b> (20 teams, started 1992, Optus Sports):{' '}
          <a
            data-testid="english-leaque-espn-link"
            href="https://www.espn.com.au/football/league/_/name/eng.1"
            target="_blank"
            rel="noreferrer"
          >
            ESPN
          </a>
          ,{' '}
          <a
            data-testid="english-leaque-wiki-link"
            href="https://en.wikipedia.org/wiki/Premier_League"
            target="_blank"
            rel="noreferrer"
          >
            wiki
          </a>
        </li>
        <li>
          Italian League, <b>Serie A</b> (20 teams, started 1898, Kayo Sports){' '}
          <a
            data-testid="italian-leaque-espn-link"
            href="https://www.espn.com.au/football/league/_/name/ita.1"
            target="_blank"
            rel="noreferrer"
          >
            ESPN
          </a>
          ,{' '}
          <a
            data-testid="italian-leaque-wiki-link"
            href="https://en.wikipedia.org/wiki/Serie_A"
            target="_blank"
            rel="noreferrer"
          >
            wiki
          </a>
        </li>
        <li>
          German League, <b>Bundesliga</b> (18 teams, started 1963, Kayo Sports){' '}
          <a
            data-testid="german-leaque-espn-link"
            href="https://www.espn.com.au/football/league/_/name/ger.1"
            target="_blank"
            rel="noreferrer"
          >
            ESPN
          </a>
          ,{' '}
          <a
            data-testid="german-leaque-wiki-link"
            href="https://en.wikipedia.org/wiki/Bundesliga"
            target="_blank"
            rel="noreferrer"
          >
            wiki
          </a>
        </li>
        <li>
          French League, <b>Ligue 1</b> (18 teams, started 1930, Kayo Sports){' '}
          <a
            data-testid="french-leaque-espn-link"
            href="https://www.espn.com.au/football/league/_/name/fra.1"
            target="_blank"
            rel="noreferrer"
          >
            ESPN
          </a>
          ,{' '}
          <a
            data-testid="french-leaque-wiki-link"
            href="https://en.wikipedia.org/wiki/Ligue_1"
            target="_blank"
            rel="noreferrer"
          >
            wiki
          </a>
        </li>
      </ol>

      <h1 id="fifa-world-cup">FIFA World Cup (world strongest football country)</h1>
      <img src={FIFA_WORLD_CUP_IMAGE} alt="FIFA world cup" />
      <h2 className={grayText()}>Men World Cup</h2>
      <p>
        <a href="https://en.wikipedia.org/wiki/FIFA_World_Cup" target="_blank" rel="noreferrer">
          FIFA world cup
        </a>{' '}
        : 32 teams (finals), started 1930. List of{' '}
        <a
          data-testid="men-world-cup-champions-link"
          href="https://en.wikipedia.org/wiki/List_of_FIFA_World_Cup_finals"
          target="_blank"
          rel="noreferrer"
        >
          Men world cup champions
        </a>
      </p>
      <p>
        <a
          data-testid="men-world-cup-rankings-link"
          href="https://www.fifa.com/fifa-world-ranking/men?dateId=id13750"
          target="_blank"
          rel="noreferrer"
        >
          Men Rankings
        </a>{' '}
        for 2022. <b>Top 10</b> countries (men). <b>Australia = 39</b> (211 countries)
      </p>
      <ol>
        <li>
          Brazil: wins = <b>5</b> (2002, 1994, 1970, 1962, 1958)
        </li>
        <li>Belgium</li>
        <li>
          <b>Argentina:</b> wins = <b>3</b> (2022, 1986, 1978)
        </li>
        <li>
          France: wins = <b>2</b> (<b>2018</b>, 1998)
        </li>
        <li>
          England: wins = <b>1</b> (1966)
        </li>
        <li>
          Spain: wins = <b>1</b> (2010)
        </li>
        <li>
          Italy: wins = <b>4</b> (2006, 1982, 1938, 1934)
        </li>
        <li>Netherlands</li>
        <li>Portugal</li>
        <li>Denmark</li>
        <li>
          Germany: wins = <b>4</b> (2014, 1990, 1974, 1954)
        </li>
      </ol>

      <h2 className={grayText()}>Women World Cup</h2>
      <p>
        <a
          href="https://www.fifa.com/fifa-world-ranking/women?dateId=id13750"
          target="_blank"
          rel="noreferrer"
        >
          Women Rankings
        </a>{' '}
        for 2022. <b>Top 10</b> countries (women). <b>Australia = 10</b> (188 countries)
      </p>
      <p>
        <a
          href="https://en.wikipedia.org/wiki/List_of_FIFA_Women%27s_World_Cup_finals"
          target="_blank"
          rel="noreferrer"
        >
          Women world cup champions
        </a>
        , started in 1991, 8 teams (finals)
      </p>
      <ol>
        <li>
          <b>USA</b>: wins = <b>4</b> (2019, 2015, 1999, 1991)
        </li>
        <li>
          Germany: wins = <b>2</b> (2007, 2003)
        </li>
        <li>Sweden</li>
        <li>England</li>
        <li>France</li>
        <li>Spain</li>
        <li>Canada</li>
        <li>Brazil</li>
        <li>Netherlands</li>
        <li>Australia</li>
      </ol>

      <h1>Soccer Confederations</h1>
      <img src={SOCCER_CONFEDERATIONS_IMAGE} alt="Soccer confederations" />
      <p>
        List of{' '}
        <a
          href="https://en.wikipedia.org/wiki/List_of_FIFA_Confederations_Cup_finals"
          target="_blank"
          rel="noreferrer"
        >
          Confederation Cup champions
        </a>
        . Competition cancelled in <b>2019</b>, replaced by{' '}
        <a
          href="https://en.wikipedia.org/wiki/FIFA_Club_World_Cup"
          target="_blank"
          rel="noreferrer"
        >
          FIFA Club World Cup
        </a>
      </p>
      <ol>
        <li>
          <a
            href="https://en.wikipedia.org/wiki/Asian_Football_Confederation"
            target="_blank"
            rel="noreferrer"
          >
            AFC
          </a>{' '}
          = Asian Football Confederation: 47 members, started 1954
        </li>
        <li>
          <a
            data-testid="confederation-caf-link"
            href="https://en.wikipedia.org/wiki/Confederation_of_African_Football"
            target="_blank"
            rel="noreferrer"
          >
            CAF
          </a>{' '}
          = Confederation of African Football: 54 members, started 1957
        </li>
        <li>
          <a href="https://en.wikipedia.org/wiki/CONCACAF" target="_blank" rel="noreferrer">
            CONCACAF
          </a>{' '}
          = Confederation of North, Central America and Caribbean Assocation Football: 41 members,
          started 1961
        </li>
        <li>
          <a href="https://en.wikipedia.org/wiki/CONMEBOL" target="_blank" rel="noreferrer">
            CONMEBOL
          </a>{' '}
          = South American Football Confederation: 10 members, started 1916
        </li>
        <li>
          <a
            href="https://en.wikipedia.org/wiki/Oceania_Football_Confederation"
            target="_blank"
            rel="noreferrer"
          >
            OFC
          </a>{' '}
          = Oceania Football Confederation: 13 members, started in 1966
        </li>
        <li>
          <a href="https://en.wikipedia.org/wiki/UEFA" target="_blank" rel="noreferrer">
            UEFA
          </a>{' '}
          = Union of European Football Assocations: 55 members, started in 1954
        </li>
      </ol>

      <h1 id="fifa-world-club-cup">FIFA Club World Cup (world strongest football club)</h1>
      <img src={FIFA_CLUB_WORLD_CUP_IMAGE} alt="FIFA club world cup" />
      <p>
        7 finals from 6 confederations, started in 2000.{' '}
        <a
          href="https://en.wikipedia.org/wiki/FIFA_Club_World_Cup#Finals"
          target="_blank"
          rel="noreferrer"
        >
          List of champions
        </a>
      </p>
    </>
  )
}
