import { grayText, greenText } from '@srcroot/utils/commonCss'

import NBA_LOGO_IMAGE from '@srcroot/assets/images/family/basketball/nba-logo.png'
import NBL_LOGO_IMAGE from '@srcroot/assets/images/family/basketball/nbl-logo.png'
import LIGA_ACB_LOGO_IMAGE from '@srcroot/assets/images/family/basketball/liga-acb-logo.png'

import NIKOLA_JOKIC_IMAGE from '@srcroot/assets/images/family/basketball/nikola-jokic.png'
import STEPHEN_CURRY_IMAGE from '@srcroot/assets/images/family/basketball/stephen-curry.png'
import JOEL_EMBIID_IMAGE from '@srcroot/assets/images/family/basketball/joel-embiid.png'
import GIANNIS_ANTETOKOUNMPO_IMAGE from '@srcroot/assets/images/family/basketball/giannis-antetokounmpo.png'

import LEBRON_JAMES_IMAGE from '@srcroot/assets/images/family/basketball/lebron-james.png'
import MICHAEL_JORDAN_IMAGE from '@srcroot/assets/images/family/basketball/michael-jordan.png'
import MAGIC_JOHNSON_IMAGE from '@srcroot/assets/images/family/basketball/magic-johnson.png'
import WILT_CHAMBERLAIN_IMAGE from '@srcroot/assets/images/family/basketball/wilt-chamberlain.png'

import KYRIE_IRVING_IMAGE from '@srcroot/assets/images/family/basketball/2020-kyrie-irving.png'
import JOSH_GIDDEY_IMAGE from '@srcroot/assets/images/family/basketball/2022-josh-giddey.png'
import DANTE_EXUM_IMAGE from '@srcroot/assets/images/family/basketball/2014-dante-exum.png'
import DUOP_REATH_IMAGE from '@srcroot/assets/images/family/basketball/2023-duop-reath.png'

import NATHAN_SOBEY_IMAGE from '@srcroot/assets/images/family/basketball/nathan-sobey.png'
import DEJAN_VASILJEVIC_IMAGE from '@srcroot/assets/images/family/basketball/dejan-vasiljevic.png'
import MITCHELL_CREEK_IMAGE from '@srcroot/assets/images/family/basketball/mitchell-creek.png'
import CHRIS_GOULDING_IMAGE from '@srcroot/assets/images/family/basketball/chris-goulding.png'

export const Basketball = () => {
  return (
    <>
      <h1 data-testid="basketball-training-header">Basketball</h1>

      <h2 className={grayText()}>4 Jan 2024</h2>

      <p>
        <a
          data-testid="basketball-wikipedia-link"
          href="https://en.wikipedia.org/wiki/Basketball"
          target="_blank"
          rel="noreferrer"
        >
          Basketball
        </a>{' '}
        is a team sport, played between 2 teams each with 5 players. Invented in{' '}
        <strong>Dec 1891</strong> by <strong>James Naismith</strong>, a Canadian-American PE
        professor to play in the long winter months in Springfield, Massachusetts, USA.
      </p>

      <h1>Country basketball leagues (strongest club in country)</h1>
      <div className="grid grid-cols-1 md:grid-cols-12 gap-5 my-4">
        <div className="md:col-span-4 flex justify-evenly">
          <img width="80" height="200" src={NBA_LOGO_IMAGE} alt="NBA" />
        </div>
        <div className="md:col-span-4 flex justify-evenly">
          <img width="120" height="200" src={LIGA_ACB_LOGO_IMAGE} alt="Liga ACB" />
        </div>
        <div className="md:col-span-4 flex justify-evenly">
          <img width="200" height="200" src={NBL_LOGO_IMAGE} alt="NBL" />
        </div>
      </div>

      <p>
        <a
          href="https://www.espn.com.au/nba/story/_/id/18470135/fran-fraschilla-rankings-world-top-12-basketball-leagues-nba"
          target="_blank"
          rel="noreferrer"
        >
          Top basketball leagues
        </a>{' '}
        in the world
      </p>
      <ol>
        <li>
          American league:{' '}
          <a href="https://www.sportingnews.com/au/nba?gr=www" target="_blank" rel="noreferrer">
            National Basketball Association
          </a>{' '}
          (NBA) = number 1 basketball league in the world. 30 teams
        </li>
        <li>
          Spainish league:{' '}
          <a
            data-testid="liga-acb-link"
            href="https://www.acb.com/"
            target="_blank"
            rel="noreferrer"
          >
            Liga ACB
          </a>{' '}
          18 teams
        </li>
        <li>
          Australian league:{' '}
          <a data-testid="nbl-link" href="https://nbl.com.au/" target="_blank" rel="noreferrer">
            National Basketball League
          </a>{' '}
          (NBL) 10 teams
        </li>
      </ol>
      <h2>NBA Season</h2>
      <p>
        List of{' '}
        <a
          href="https://en.wikipedia.org/wiki/List_of_NBA_champions"
          target="_blank"
          rel="noreferrer"
        >
          NBA champions
        </a>
      </p>
      <p>
        <a
          href="https://en.wikipedia.org/wiki/2023%E2%80%9324_NBA_season"
          target="_blank"
          rel="noreferrer"
        >
          NBA season 2023-24
        </a>
        : 24 Oct 2023 - 14 Apr 2024. Play-in tournament 16-19 Apr 2024. Playoffs 20 Apr - 29 May
        2024. Finals = 6 Jun 2024
      </p>
      <p>
        <a
          href="https://en.wikipedia.org/wiki/2022%E2%80%9323_NBA_season"
          target="_blank"
          rel="noreferrer"
        >
          NBA season 2022-23
        </a>
        : 18 Oct 2022 - 9 Apr 2023. Play-in tournament 11-14 Apr 2023. Playoffs 15 Apr - 29 May
        2023. Finals = 1-12 Jun 2023
      </p>
      <p>
        <a href="https://en.wikipedia.org/wiki/2023_NBA_draft" target="_blank" rel="noreferrer">
          NBA draft 2023
        </a>
        : 22 June 2023
      </p>

      <h2>Liga ACB season</h2>
      <p>
        <a
          href="https://en.wikipedia.org/wiki/2023%E2%80%9324_ACB_season"
          target="_blank"
          rel="noreferrer"
        >
          Liga ACB season 2023-24
        </a>
        : 23 Sep 2023
      </p>

      <p>
        <a
          href="https://en.wikipedia.org/wiki/2022%E2%80%9323_ACB_season"
          target="_blank"
          rel="noreferrer"
        >
          Liga ACB season 2022-23
        </a>
        : 28 Sep 2022 - 21 May 2023. Playoffs 25 May - 27 Jun 2023.
      </p>

      <h2>NBL Season</h2>
      <p>
        List of{' '}
        <a
          href="https://en.wikipedia.org/wiki/List_of_NBL_champions"
          target="_blank"
          rel="noreferrer"
        >
          NBL champions
        </a>
      </p>
      <p>
        <a
          href="https://en.wikipedia.org/wiki/2023%E2%80%9324_NBL_season"
          target="_blank"
          rel="noreferrer"
        >
          NBL season 2023-24
        </a>
        : 28 Sep 2023 - 18 Feb 2024
      </p>
      <p>
        <a
          href="https://en.wikipedia.org/wiki/2022%E2%80%9323_NBL_season"
          target="_blank"
          rel="noreferrer"
        >
          NBL season 2022-23
        </a>
        : 1 Oct 2022 - 5 Feb 2023. Play-in tournament 9-12 Feb 2023. Semi finals 12-19 Feb 2023.
        Finals 3-15 Mar 2023
      </p>

      <h1>FIBA World Cup (world strongest basketball country)</h1>
      <p>
        <a
          href="https://en.wikipedia.org/wiki/List_of_NBL_champions"
          target="_blank"
          rel="noreferrer"
        >
          Men world cup champions
        </a>
      </p>
      <p>
        <a href="https://www.fiba.basketball/rankingmen" target="_blank" rel="noreferrer">
          Men Rankings
        </a>{' '}
        after World Cup 2023
      </p>
      <ol>
        <li>USA = 5 (2014, 2010, 1994, 1986, 1954)</li>
        <li>Spain = 2 (2019, 2006)</li>
        <li>Germany = 1 (2023)</li>
        <li>Australia = 0</li>
        <li>Serbia = 0</li>
        <li>Canada = 0</li>
        <li>Argentina = 1 (1950)</li>
        <li>Latvia = 0</li>
        <li>France = 0</li>
        <li>Lithuania = 0</li>
        <li>Slovenia = 0</li>
      </ol>

      <h1>Olympics Basketball (world strongest basketball country)</h1>
      <p>
        <a
          href="https://en.wikipedia.org/wiki/List_of_Olympic_medalists_in_basketball"
          target="_blank"
          rel="noreferrer"
        >
          Olympic basketball medalists
        </a>
      </p>
      <ol>
        <li>
          USA = 16 (2020, 2016, 2012, 2008, 2000, 1996, 1992, 1984, 1976, 1968, 1964, 1960, 1956,
          1952, 1948, 1936
        </li>
        <li>Soviet Union = 2 (1988, 1972)</li>
        <li>Argentina = 1 (2004)</li>
        <li>Yugoslavia = 1 (1980)</li>
      </ol>

      <h1>Australian Basketball</h1>
      <p>
        <a href="https://www.australia.basketball/" target="_blank" rel="noreferrer">
          Basketball Australia
        </a>{' '}
        has the latest Basketball Australia news and national teams.
      </p>
      <p>
        <a href="https://nbl.com.au/" target="_blank" rel="noreferrer">
          National Basketball League
        </a>{' '}
        (NBL) is Australia&apos;s basketball league.
      </p>

      <h1>Basketball Fundamentals</h1>

      <p>
        <a href="https://www.youtube.com/watch?v=tg-w45l2Ib0" target="_blank" rel="noreferrer">
          Teach basics of basketball for youth PE
        </a>{' '}
        (14m 45s) = covers dribbling & dribbling rules, layups and shooting. Really good for
        beginners.
      </p>
      <p>
        <a href="https://www.youtube.com/watch?v=qqXedKDtQ2g" target="_blank" rel="noreferrer">
          Masterclass: Basketball Fundamentals
        </a>{' '}
        by Michael Jordan (1h 6m), offensive player = notice how defensive player is playing you.
      </p>
      <ul>
        <li>offense</li>
        <ul>
          <li>triple threat: jab step, cross over, shot / fake / drive</li>
          <li>protect the ball: use your body, swing ball low (to change hands)</li>
          <li>fade away jump shot, free throws</li>
          <li>moving without the ball: using the screen</li>
        </ul>
        <li>passing: passing to the post</li>
        <li>defense</li>
        <ul>
          <li>defensive stance: hands, movement (never stand up)</li>
          <li>
            seeing your man = keep head straight (use peripheral vision) to see ball and man. Never
            turn your head
          </li>
          <li>1 pass away, 2 passes away</li>
        </ul>
      </ul>

      <h2>Jr NBA fundamentals</h2>
      <p>
        <a
          href="https://www.youtube.com/watch?v=BnvGa0I8bMc&list=PLHIEqhuXuRFJyAE86lmd52i32SKVZ6HkT"
          target="_blank"
          rel="noreferrer"
        >
          Jr NBA
        </a>{' '}
        videos that teach basketball fundamentals (few mins each)
      </p>
      <ol>
        <li>Dribbling (Ball Handling) and Footwork</li>
        <ol>
          <li>
            <a
              href="https://www.youtube.com/watch?v=BnvGa0I8bMc&list=PLHIEqhuXuRFJyAE86lmd52i32SKVZ6HkT&index=2"
              target="_blank"
              rel="noreferrer"
            >
              Dribbling fundamentals
            </a>{' '}
            by Isiah Thomas
          </li>
          <li>
            <a
              href="https://www.youtube.com/watch?v=LY31S_8yyOA&list=PLmPIogZWH3R4-zzK6k3ZpL-Sz-IL_m9yV&index=1"
              target="_blank"
              rel="noreferrer"
            >
              Retreat dribble fundamentals
            </a>{' '}
            by Coach Megan Griffith
          </li>
          <li>
            <a
              href="https://www.youtube.com/watch?v=HDHFyQ0DwS4&list=PLmPIogZWH3R4-zzK6k3ZpL-Sz-IL_m9yV&index=3"
              target="_blank"
              rel="noreferrer"
            >
              Pull back dribble fundamentals
            </a>{' '}
            by Teresa Edwards
          </li>
          <li>
            <a
              href="https://www.youtube.com/watch?v=OQWe-Y4zwEg&list=PLmPIogZWH3R4-zzK6k3ZpL-Sz-IL_m9yV&index=4"
              target="_blank"
              rel="noreferrer"
            >
              In and out crossover fundamentals
            </a>{' '}
            by Coach Jeremiah Boswell
          </li>
          <li>
            <a
              href="https://www.youtube.com/watch?v=BSxa28H3uz0&list=PLmPIogZWH3R4-zzK6k3ZpL-Sz-IL_m9yV&index=5"
              target="_blank"
              rel="noreferrer"
            >
              Between cross dribble fundamentals
            </a>{' '}
            by Kendall Gill
          </li>
          <li>
            <a
              href="https://www.youtube.com/watch?v=l9wet2iXRuI&list=PLHIEqhuXuRFJyAE86lmd52i32SKVZ6HkT&index=166"
              target="_blank"
              rel="noreferrer"
            >
              Figure 8 dribble 4 drill
            </a>{' '}
            by Coach Jeremiah Boswell
          </li>
          <li>
            <a
              href="https://www.youtube.com/watch?v=qBkSOtWQe4o&list=PLHIEqhuXuRFJyAE86lmd52i32SKVZ6HkT&index=72"
              target="_blank"
              rel="noreferrer"
            >
              Low dribble drill with numbers
            </a>{' '}
            by Coach Jon Babul
          </li>
          <li>
            <a
              href="https://www.youtube.com/watch?v=HI3uylRp1OY&list=PLHIEqhuXuRFJyAE86lmd52i32SKVZ6HkT&index=73"
              target="_blank"
              rel="noreferrer"
            >
              Zig zag drill
            </a>{' '}
            by Coach Dana Simonelli for cross overs and dribbling with both hands
          </li>
          <li>
            <a
              href="https://www.youtube.com/watch?v=xTtNAM2l8DM&list=PLHIEqhuXuRFJyAE86lmd52i32SKVZ6HkT&index=62"
              target="_blank"
              rel="noreferrer"
            >
              Moving between the legs drill
            </a>{' '}
            by Coach Jeremiah Boswell
          </li>
          <li>
            <a
              href="https://www.youtube.com/watch?v=b3hXI87nAtE&list=PLHIEqhuXuRFJyAE86lmd52i32SKVZ6HkT&index=166"
              target="_blank"
              rel="noreferrer"
            >
              Between the legs challenge
            </a>{' '}
            by Coach Joseph Timmes
          </li>
          <li>
            <a
              href="https://www.youtube.com/watch?v=kkWkt_hUp6k&list=PLHIEqhuXuRFJyAE86lmd52i32SKVZ6HkT&index=74"
              target="_blank"
              rel="noreferrer"
            >
              Between cross behind dribble drill
            </a>{' '}
            by Kendall Gill
          </li>
          <li>
            <a
              href="https://www.youtube.com/watch?v=WM90iRaOZ3w&list=PLHIEqhuXuRFJyAE86lmd52i32SKVZ6HkT&index=3"
              target="_blank"
              rel="noreferrer"
            >
              Jab step fundamentals
            </a>{' '}
            by Shane Battier
          </li>
          <li>
            <a
              href="https://www.youtube.com/watch?v=Ggg1unQOFfY&list=PLHIEqhuXuRFJyAE86lmd52i32SKVZ6HkT&index=90"
              target="_blank"
              rel="noreferrer"
            >
              Jab step drill
            </a>{' '}
            by Shane Battier
          </li>
          <li>
            <a
              href="https://www.youtube.com/watch?v=gjLbJ2_UH7I&list=PLHIEqhuXuRFJyAE86lmd52i32SKVZ6HkT&index=17"
              target="_blank"
              rel="noreferrer"
            >
              Jab and attack drill
            </a>{' '}
            by Shane Battier
          </li>
        </ol>
        <li>Passing</li>
        <ol>
          <li>
            <a
              href="https://www.youtube.com/watch?v=aAK8iqHXX9A&list=PLHIEqhuXuRFJyAE86lmd52i32SKVZ6HkT&index=69"
              target="_blank"
              rel="noreferrer"
            >
              Pass is faster than dribble drill
            </a>{' '}
            by Coach Joseph Timmes
          </li>
          <li>
            <a
              href="https://www.youtube.com/watch?v=I9pVT325pZA&list=PLmPIogZWH3R4-zzK6k3ZpL-Sz-IL_m9yV&index=17"
              target="_blank"
              rel="noreferrer"
            >
              High low pass fundamentals
            </a>{' '}
            by Coach Kyle Cummings
          </li>
          <li>
            <a
              href="https://www.youtube.com/watch?v=N6YwYTbhW5Q&list=PLHIEqhuXuRFJyAE86lmd52i32SKVZ6HkT&index=186"
              target="_blank"
              rel="noreferrer"
            >
              Backdoor pass fundamentals
            </a>{' '}
            by Coach Rick Lewis
          </li>
          <li>
            <a
              href="https://www.youtube.com/watch?v=l0LQMgMqFUE&list=PLHIEqhuXuRFJyAE86lmd52i32SKVZ6HkT&index=42"
              target="_blank"
              rel="noreferrer"
            >
              Backdoor pass layup drill
            </a>{' '}
            by Coach Chuck Neely
          </li>
        </ol>
        <li>Layups</li>
        <ol>
          <li>
            <a
              href="https://www.youtube.com/watch?v=3noGvqHdoRE&list=PLmPIogZWH3R4-zzK6k3ZpL-Sz-IL_m9yV&index=13"
              target="_blank"
              rel="noreferrer"
            >
              Layup fundamentals
            </a>{' '}
            by Coach Alvin Gentry
          </li>
          <li>
            <a
              href="https://www.youtube.com/watch?v=UW8Wl7K5HDA&list=PLHIEqhuXuRFJyAE86lmd52i32SKVZ6HkT&index=82"
              target="_blank"
              rel="noreferrer"
            >
              Euro Step fundamentals
            </a>{' '}
            by Coach Joseph Timmes
          </li>
          <li>
            <a
              href="https://www.youtube.com/watch?v=h-FP8ieKi7U&list=PLmPIogZWH3R4-zzK6k3ZpL-Sz-IL_m9yV&index=12"
              target="_blank"
              rel="noreferrer"
            >
              Up and under fundamentals
            </a>{' '}
            by Katie Smith
          </li>
          <li>
            <a
              href="https://www.youtube.com/watch?v=TlOwO09gzpI&list=PLHIEqhuXuRFJyAE86lmd52i32SKVZ6HkT&index=31"
              target="_blank"
              rel="noreferrer"
            >
              Post move: drop step layup
            </a>{' '}
            by Sue Wicks
          </li>
        </ol>
        <li>Shooting</li>
        <ol>
          <li>
            <a
              href="https://www.youtube.com/watch?v=t7ciq_x4138&list=PLHIEqhuXuRFJyAE86lmd52i32SKVZ6HkT&index=3"
              target="_blank"
              rel="noreferrer"
            >
              Shooting fundamentals
            </a>{' '}
            by Allan Houston
          </li>
          <li>
            <a
              href="https://www.youtube.com/watch?v=lnuZNZPDvaY&list=PLHIEqhuXuRFJyAE86lmd52i32SKVZ6HkT&index=67"
              target="_blank"
              rel="noreferrer"
            >
              Free throw fundamentals
            </a>{' '}
            by Coach Megan Griffith
          </li>
        </ol>
        <li>Offense</li>
        <ol>
          <li>
            <a
              href="https://www.youtube.com/watch?v=NC3jy-pb4Jg&list=PLmPIogZWH3R4-zzK6k3ZpL-Sz-IL_m9yV&index=25"
              target="_blank"
              rel="noreferrer"
            >
              Screen fundamentals
            </a>{' '}
            by Katie Smith
          </li>
          <li>
            <a
              href="https://www.youtube.com/watch?v=Ruce8Fb7zTY&list=PLmPIogZWH3R4-zzK6k3ZpL-Sz-IL_m9yV&index=26"
              target="_blank"
              rel="noreferrer"
            >
              Setting up a screen fundamentals
            </a>{' '}
            by Coach Jeremiah Boswell
          </li>
          <li>
            <a
              href="https://www.youtube.com/watch?v=NqgaWL7b86A&list=PLHIEqhuXuRFJyAE86lmd52i32SKVZ6HkT&index=20"
              target="_blank"
              rel="noreferrer"
            >
              Using a screen shooting drill
            </a>{' '}
            by Coach Jeremiah Boswell
          </li>
          <li>
            <a
              href="https://www.youtube.com/watch?v=SXYIYWHOPzI&list=PLHIEqhuXuRFJyAE86lmd52i32SKVZ6HkT&index=90"
              target="_blank"
              rel="noreferrer"
            >
              V cut fundamentals
            </a>{' '}
            by Coach Michael Collins
          </li>
          <li>
            <a
              href="https://www.youtube.com/watch?v=QSzapEqhOaE&list=PLHIEqhuXuRFJyAE86lmd52i32SKVZ6HkT&index=21"
              target="_blank"
              rel="noreferrer"
            >
              V cut layup drill
            </a>{' '}
            by Coach Michael Collins
          </li>
          <li>
            <a
              href="https://www.youtube.com/watch?v=YzadRKLSMB4&list=PLHIEqhuXuRFJyAE86lmd52i32SKVZ6HkT&index=19"
              target="_blank"
              rel="noreferrer"
            >
              Pass and cut drill
            </a>{' '}
            by Kiesha Brown
          </li>
          <li>
            <a
              href="https://www.youtube.com/watch?v=EHVzpuxqLzs&list=PLmPIogZWH3R4-zzK6k3ZpL-Sz-IL_m9yV&index=14"
              target="_blank"
              rel="noreferrer"
            >
              Out cut off a screen fundamentals
            </a>{' '}
            by Kendall Gill
          </li>
          <li>
            <a
              href="https://www.youtube.com/watch?v=k9GckaZVJj8&list=PLHIEqhuXuRFJyAE86lmd52i32SKVZ6HkT&index=43"
              target="_blank"
              rel="noreferrer"
            >
              Backdoor cut fundamentals
            </a>{' '}
            by Isiah Thomas
          </li>
          <li>
            <a
              href="https://www.youtube.com/watch?v=Is7GyXhvvag&list=PLHIEqhuXuRFJyAE86lmd52i32SKVZ6HkT&index=155"
              target="_blank"
              rel="noreferrer"
            >
              Backdoor cut layup drill
            </a>{' '}
            by Isiah Thomas
          </li>
          <li>
            <a
              href="https://www.youtube.com/watch?v=M9Acu3IlURQ&list=PLmPIogZWH3R4-zzK6k3ZpL-Sz-IL_m9yV&index=33"
              target="_blank"
              rel="noreferrer"
            >
              2 on 1 fast break fundamentals
            </a>{' '}
            by Coach Mike D&apos;Antoni
          </li>
          <li>
            <a
              href="https://www.youtube.com/watch?v=e2EX9m9RO4k&list=PLmPIogZWH3R4-zzK6k3ZpL-Sz-IL_m9yV&index=32"
              target="_blank"
              rel="noreferrer"
            >
              If fronted, flash fundamentals
            </a>{' '}
            by Coach Kyle Cummings
          </li>
        </ol>
        <li>Defense</li>
        <ol>
          <li>
            <a
              href="https://www.youtube.com/watch?v=nN7M6ewFqJg&list=PLHIEqhuXuRFJyAE86lmd52i32SKVZ6HkT&index=19"
              target="_blank"
              rel="noreferrer"
            >
              Denying the pass fundamentals
            </a>{' '}
            by Isiah Thomas
          </li>
          <li>
            <a
              href="https://www.youtube.com/watch?v=G3pVP5rktVg&list=PLHIEqhuXuRFJyAE86lmd52i32SKVZ6HkT&index=107"
              target="_blank"
              rel="noreferrer"
            >
              3 second deny drill
            </a>{' '}
            by Isiah Thomas
          </li>
          <li>
            <a
              href="https://www.youtube.com/watch?v=O7ZnEOIHciE&list=PLHIEqhuXuRFJyAE86lmd52i32SKVZ6HkT&index=68"
              target="_blank"
              rel="noreferrer"
            >
              Denying layup drill
            </a>{' '}
            by Coach Dana Simonelli
          </li>
          <li>
            <a
              href="https://www.youtube.com/watch?v=4A6KqSJX8Ek&list=PLmPIogZWH3R4-zzK6k3ZpL-Sz-IL_m9yV&index=40"
              target="_blank"
              rel="noreferrer"
            >
              Defensive Stance fundamentals
            </a>{' '}
            by Coach Darren Eman
          </li>
          <li>
            <a
              href="https://www.youtube.com/watch?v=F2VtSj7YZQI&list=PLmPIogZWH3R4-zzK6k3ZpL-Sz-IL_m9yV&index=35"
              target="_blank"
              rel="noreferrer"
            >
              Jump to the ball fundamentals
            </a>{' '}
            by Shane Battier
          </li>
          <li>
            <a
              href="https://www.youtube.com/watch?v=KJM9701zZaU&list=PLHIEqhuXuRFJyAE86lmd52i32SKVZ6HkT&index=159"
              target="_blank"
              rel="noreferrer"
            >
              Help the helper fundamentals
            </a>{' '}
            by Coach Randy Brown
          </li>
          <li>
            <a
              href="https://www.youtube.com/watch?v=nOwQXU7IHZg&list=PLmPIogZWH3R4-zzK6k3ZpL-Sz-IL_m9yV&index=37"
              target="_blank"
              rel="noreferrer"
            >
              Closeout fundamentals
            </a>{' '}
            by Coach Randy Brown
          </li>
          <li>
            <a
              href="https://www.youtube.com/watch?v=iEEiFzzNMgI&list=PLmPIogZWH3R4-zzK6k3ZpL-Sz-IL_m9yV&index=36"
              target="_blank"
              rel="noreferrer"
            >
              Stunt & recover fundamentals
            </a>{' '}
            by Coach Jim Boyden
          </li>
          <li>
            <a
              href="https://www.youtube.com/watch?v=tRDYAjN1enQ&list=PLmPIogZWH3R4-zzK6k3ZpL-Sz-IL_m9yV&index=38"
              target="_blank"
              rel="noreferrer"
            >
              Defending a 2 on 1 fundamentals
            </a>{' '}
            by Kendall Gill
          </li>
          <li>
            <a
              href="https://www.youtube.com/watch?v=PggPs5r-iJY&list=PLHIEqhuXuRFJyAE86lmd52i32SKVZ6HkT&index=24"
              target="_blank"
              rel="noreferrer"
            >
              3 touch defensive drill
            </a>{' '}
            by Coach Kyle Cummings
          </li>
          <li>
            <a
              href="https://www.youtube.com/watch?v=HK4KwpmzlYE&list=PLHIEqhuXuRFJyAE86lmd52i32SKVZ6HkT&index=56"
              target="_blank"
              rel="noreferrer"
            >
              2 on 2 shell drill
            </a>{' '}
            by Coach Jim Boylen
          </li>
          <li>
            <a
              href="https://www.youtube.com/watch?v=2TZ_KE2LmyE&list=PLHIEqhuXuRFJyAE86lmd52i32SKVZ6HkT&index=17"
              target="_blank"
              rel="noreferrer"
            >
              3 on 3 shell drill
            </a>{' '}
            by Coach Jim Boylen
          </li>
          <li>
            <a href="https://www.youtube.com/watch?v=I3VVZHDvf9o" target="_blank" rel="noreferrer">
              5 on 5 shell drill with post
            </a>{' '}
            by Coach Billy Donovan
          </li>
        </ol>
        <li>Values and Wellness</li>
        <ol>
          <li>
            <a
              href="https://www.youtube.com/watch?v=_ususc19m-w&list=PLHIEqhuXuRFJyAE86lmd52i32SKVZ6HkT&index=72"
              target="_blank"
              rel="noreferrer"
            >
              Know your team game
            </a>{' '}
            by Coach Jeremiah Boswell
          </li>
        </ol>
      </ol>

      <h1>Beginner Dribbling training</h1>
      <ol>
        <li>dribble knee level (right / left)</li>
        <li>One hand windscreen wipers (right / left)</li>
        <li>Front cross overs 2 hands</li>
        <li>Jab and cross body jab (right / left)</li>
        <li>Scissors (right / left)</li>
        <li>Facing front: between the legs (right / left)</li>
        <li>Scissors: between the legs (right / left)</li>
        <li>Facing front: behind the back (right / left)</li>
        <li>Wrap around: behind the back (right / left)</li>
        <li>Spin move</li>
      </ol>

      <h1>Current top players in the world</h1>
      <p>
        <a
          href="https://nbarankings.theringer.com/the-offer-sheet"
          target="_blank"
          rel="noreferrer"
        >
          Top player rankings
        </a>{' '}
        for 2024
      </p>
      <ol>
        <li>Nikola Jokic: 26.5 ppg, 12.4 rpg, 9.3 apg, 1.2 spg = Denver Nuggets</li>
        <li>Stephen Curry: 28.2 ppg (3P% 41.6), 4.4 apg, 0.7 spg = Golden State Warriors</li>
        <li>Joel Embiid: 35.1 ppg, 11.8 rpg, 1.9 bpg, 5.9 apg = Philadelphia 76ers</li>
        <li>Giannis Antetokounmpo: 30.6 ppg, 11.1 rpg, 1.2 bpg, 5.4 apg = Milwaukee Bucks</li>
        <li>Luka Doncic: 32.7 ppg (3P% 37.6), 8.4 rpg, 9.1 apg = Dallas Mavericks</li>
        <li>Jayson Tatum: 26.8 ppg (3P% 34.5), 8.7 rpg, 4.2 apg = Boston Celtics</li>
        <li>Devin Booker: 27.9 ppg (3P% 38.3), 8.2 apg, 0.7 spg = Phoenix Suns</li>
        <li>
          Shai Gilgeous-Alexander: 30.7 ppg, 0.8 bpg, 6.4 apg, 2.8 spg = Oklahoma City Thunder
        </li>
        <li>Kevin Durant: 31 ppg, 6.1 rpg, 1.1 blk, 5.6 apg, 2.8 spg = Phoenix Suns</li>
        <li>LeBron James: 25.2 ppg, 7.7 rpg, 7.3 apg, 1.5 spg = Los Angeles Lakers</li>
        <li>Jimmy Butler: 21.5 ppg, 5.2 rpg, 4.6 apg, 1.1 spg = Miami Heat</li>
        <li>Tyrese Haliburton: 24.7 ppg (3P% 42.4), 12 apg, 0.9 spg = Indiana Pacers</li>
      </ol>
      <div className="grid grid-cols-1 md:grid-cols-12 gap-5 my-4">
        <div className="md:col-span-3 flex justify-evenly">
          <img src={NIKOLA_JOKIC_IMAGE} alt="Nikola Jokic" />
        </div>
        <div className="md:col-span-3 flex justify-evenly">
          <img src={STEPHEN_CURRY_IMAGE} alt="Stephen Curry" />
        </div>
        <div className="md:col-span-3 flex justify-evenly">
          <img src={JOEL_EMBIID_IMAGE} alt="Joel Embiid" />
        </div>
        <div className="md:col-span-3 flex justify-evenly">
          <img src={GIANNIS_ANTETOKOUNMPO_IMAGE} alt="Giannis Antetokounmpo" />
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-12 gap-5 my-4">
        <div className="md:col-span-3 flex justify-evenly">
          <p className={greenText()}>Nikola Jokic 2014</p>
        </div>
        <div className="md:col-span-3 flex justify-evenly">
          <p className={greenText()}>Stephen Curry 2009</p>
        </div>
        <div className="md:col-span-3 flex justify-evenly">
          <p className={greenText()}>Joel Embiid 2014</p>
        </div>
        <div className="md:col-span-3 flex justify-evenly">
          <p className={greenText()}>Giannis Antetokounmpo 2013</p>
        </div>
      </div>
      <h1>Current top players in NBL</h1>
      <p>
        <a
          href="https://nbl.com.au/statistics/players?season=_cid"
          target="_blank"
          rel="noreferrer"
        >
          NBL league leaders
        </a>
      </p>
      <ol>
        <li>
          Bryce Cotton (American): 22.8 ppg (FG% 38), 4.1 rpg, 4.3 apg ={' '}
          <strong>Perth Wildcats</strong>
        </li>
        <li>
          Anthony Lamb (American): 20.2 ppg (FG% 50), 6.4 rpg, 2.5 apg ={' '}
          <strong>New Zealand Breakers</strong>
        </li>
        <li>
          Nathan Sobey (<strong>Australian</strong>): 20.4 (FG% 41) ppg, 4.6 rpg, 2.5 apg ={' '}
          <strong>Brisbane Bullets</strong>
        </li>
        <li>
          Dejan Vasiljevic (<strong>Australian</strong>): 19.7 ppg (FG% 40), 3.5 rpg, 3.1 apg ={' '}
          <strong>Adelaide 36ers</strong>
        </li>
        <li>
          Parker Jackson-Cartwright (American): 19.5 ppg (FG% 47), 3.2 rpg, 6.2 apg = New Zealand
          Breakers
        </li>
        <li>
          Mitchell Creek (<strong>Australian</strong>): 19.3 ppg (FG% 50), 6.8 rpg, 2.1 apg ={' '}
          <strong>South East Melbourne Phoenix</strong>
        </li>
        <li>Patrick Miller (American): 18.8 ppg (FG% 49), 3.6 rpg, 6 apg = Cairns Taipans</li>
        <li>
          Chris Goulding (<strong>Australian</strong>): 18 ppg (FG% 45), 2.2 rpg, 1.9 apg ={' '}
          <strong>Melbourne United</strong>
        </li>
        <li>Jaylen Adams (American): 17.8 ppg (FG% 41), 3.5 rpg, 5.3 apg = Sydney Kings</li>
        <li>
          Jordon Crawford (American) 17.6 ppg (FG% 39), 3.0 rpg, 3.9 apg = Tasmania Jackjumpers
        </li>
        <li>
          Alan Williams (American) 17.1 ppg (FG% 43), 10.9 rpg, 2.7 apg = South East Melbourne
          Phoenix
        </li>
        <li>Tahjere Mccall (American) 16.6 ppg (FG% 46), 4.5 rpg, 5.2 apg = Cairns Taipans</li>
        <li>
          Zylan Cheatham (American) 16.4 ppg (FG% 55), 4.7 rpg, 0.9 apg = New Zealand Breakers
        </li>
        <li>Milton Doyle (American) 16.2 ppg (FG% 42), 4.3 rpg, 4.4 apg = Tasmania Jackjumpers</li>
        <li>
          Jack McVeigh (<strong>Australian</strong>) 15.7 ppg (FG% 50), 5.3 rpg, 1.9 apg = Tasmania
          Jackjumpers
        </li>
      </ol>
      <div className="grid grid-cols-1 md:grid-cols-12 gap-5 my-4">
        <div className="md:col-span-3 flex justify-evenly">
          <img src={NATHAN_SOBEY_IMAGE} alt="Nathan Sobey" />
        </div>
        <div className="md:col-span-3 flex justify-evenly">
          <img src={DEJAN_VASILJEVIC_IMAGE} alt="Dejan Vasiljevic" />
        </div>
        <div className="md:col-span-3 flex justify-evenly">
          <img src={MITCHELL_CREEK_IMAGE} alt="Mitchell Creek" />
        </div>
        <div className="md:col-span-3 flex justify-evenly">
          <img src={CHRIS_GOULDING_IMAGE} alt="Chris Goulding" />
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-12 gap-5 my-4">
        <div className="md:col-span-3 flex justify-evenly">
          <p className={greenText()}> Nathan Sobey 2019</p>
        </div>
        <div className="md:col-span-3 flex justify-evenly">
          <p className={greenText()}>Dejan Vasiljevic 2020</p>
        </div>
        <div className="md:col-span-3 flex justify-evenly">
          <p className={greenText()}>Mitchell Creek 2010</p>
        </div>
        <div className="md:col-span-3 flex justify-evenly">
          <p className={greenText()}>Chris Goulding 2006</p>
        </div>
      </div>

      <h1>Current top Australian players in NBA</h1>
      <p>
        <a
          href="https://basketball.realgm.com/national/teams/2/Australia/nba_players"
          target="_blank"
          rel="noreferrer"
        >
          Australian players
        </a>{' '}
        currently in the NBA
      </p>
      <ol>
        <li>Kyrie Irving: 22.9 ppg, 4.7 rpg, 5.1 apg = Dallas Mavericks</li>
        <li>Josh Giddey: 12.12 ppg, 6 rpg, 4.2 apg = Oklahoma City Thunder</li>
        <li>Dante Exum: 9.33 ppg, 2.9 rpg, 2.87 apg = Dallas Mavericks</li>
        <li>Duop Reath: 8.64 ppg, 3.23 rpg, 1.05 apg = Portland Trail Blazers</li>
        <li>Josh Green: 7 ppg, 2.75 rpg, 2.58 apg = Dallas Mavericks</li>
      </ol>
      <div className="grid grid-cols-1 md:grid-cols-12 gap-5 my-4">
        <div className="md:col-span-3 flex justify-evenly">
          <img src={KYRIE_IRVING_IMAGE} alt="Kyrie Irving" />
        </div>
        <div className="md:col-span-3 flex justify-evenly">
          <img src={JOSH_GIDDEY_IMAGE} alt="Josh Giddey" />
        </div>
        <div className="md:col-span-3 flex justify-evenly">
          <img src={DANTE_EXUM_IMAGE} alt="Dante Exum" />
        </div>
        <div className="md:col-span-3 flex justify-evenly">
          <img src={DUOP_REATH_IMAGE} alt="Duop Reath" />
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-12 gap-5 my-4">
        <div className="md:col-span-3 flex justify-evenly">
          <p className={greenText()}>Kyrie Irving 2011</p>
        </div>
        <div className="md:col-span-3 flex justify-evenly">
          <p className={greenText()}>Josh Giddey 2021</p>
        </div>
        <div className="md:col-span-3 flex justify-evenly">
          <p className={greenText()}>Dante Exum 2014</p>
        </div>
        <div className="md:col-span-3 flex justify-evenly">
          <p className={greenText()}>Duop Reath 2023</p>
        </div>
      </div>

      <h1>Top players of all time</h1>
      <p>
        <a
          href="https://www.britannica.com/list/the-10-greatest-basketball-players-of-all-time"
          target="_blank"
          rel="noreferrer"
        >
          Top 10 basketball players
        </a>{' '}
        of all time
      </p>
      <p>
        <a
          href="https://en.wikipedia.org/wiki/List_of_National_Basketball_Association_career_scoring_leaders"
          target="_blank"
          rel="noreferrer"
        >
          NBA career scoring leaders
        </a>
      </p>
      <ol>
        <li>LeBron James: 25.2 ppg, 7.3 rpg, 7.4 apg</li>
        <li>Michael Jordan: 30.1 ppg, 6.2 rpg, 5.3 apg</li>
        <li>Magic Johnson: 19.5 ppg, 7.2 rpg, 11.2 apg</li>
        <li>Wilt Chamberlain: 30.1 ppg, 22.9 rpg, 4.4 apg</li>
        <li>Oscar Robertson: 25.7 ppg, 7.5 rpg, 9.5 apg</li>
        <li>Bill Russell: 15.1 ppg, 22.5 rpg, 4.3 apg</li>
        <li>Larry Bird: 24.3 ppg, 10 rpg, 6.4 apg</li>
        <li>Shaquille O’Neal: 23.7 ppg, 10.9 rpg, 2.5 apg</li>
        <li>Tim Duncan: 19 ppg, 10.8 rpg, 3 apg</li>
        <li>Kareem Abdul-Jabbar: 24.6 ppg, 11.2 rpg, 3.6 apg</li>
      </ol>
      <div className="grid grid-cols-1 md:grid-cols-12 gap-5 my-4">
        <div className="md:col-span-3 flex justify-evenly">
          <img src={LEBRON_JAMES_IMAGE} alt="LeBron James" />
        </div>
        <div className="md:col-span-3 flex justify-evenly">
          <img src={MICHAEL_JORDAN_IMAGE} alt="Michael Jordan" />
        </div>
        <div className="md:col-span-3 flex justify-evenly">
          <img src={MAGIC_JOHNSON_IMAGE} alt="Magic Johnson" />
        </div>
        <div className="md:col-span-3 flex justify-evenly">
          <img src={WILT_CHAMBERLAIN_IMAGE} alt="Wilt Chamberlain" />
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-12 gap-5 my-4">
        <div className="md:col-span-3 flex justify-evenly">
          <p className={greenText()}>LeBron James 2003-present</p>
        </div>
        <div className="md:col-span-3 flex justify-evenly">
          <p className={greenText()}>Michael Jordan 1984-2003</p>
        </div>
        <div className="md:col-span-3 flex justify-evenly">
          <p className={greenText()}>Magic Johnson 1979-1996</p>
        </div>
        <div className="md:col-span-3 flex justify-evenly">
          <p className={greenText()}>Wilt Chamberlain 1959-1973</p>
        </div>
      </div>
    </>
  )
}
