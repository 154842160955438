import SCHOOL_AT_NIGHT_IMAGE from '@srcroot/assets/images/matthew/haunted-school/school-at-night.png'
import WOLF_HOWLING_IMAGE from '@srcroot/assets/images/matthew/haunted-school/wolf-howling.png'
import SKULL_IMAGE from '@srcroot/assets/images/matthew/haunted-school/skull.png'
import HOT_ROD_IMAGE from '@srcroot/assets/images/matthew/hotrod.png'
import ULTRA_MAGNUS_IMAGE from '@srcroot/assets/images/matthew/haunted-school/dweller-in-the-depths-ultramagnus-rocketlaunch.jpg'
import HAPPY_KIDS_IMAGE from '@srcroot/assets/images/matthew/haunted-school/happy-kids.png'

import { grayText } from '@srcroot/utils/commonCss'

export const EnterHauntedSchool = () => {
  return (
    <>
      <h1 data-testid="enter-haunted-school-header">My first story</h1>

      <h2 className={grayText()}>31 Jul 2022</h2>

      <h2>Enter the Haunted School</h2>
      <h2>A Shiver Action series: Book 1</h2>
      <img src={SCHOOL_AT_NIGHT_IMAGE} alt="School at night" />

      <h2>Summary</h2>
      <p>
        Matthew moves to Australia, he hears strange <b>howling</b> noises from a weird school.
        Schools don&apos;t make weird noises, <b>or do they?</b>
      </p>
      <img src={WOLF_HOWLING_IMAGE} alt="Wolf howling" />

      <hr />
      <h2>Story</h2>

      <p>
        It was a sunny Saturday morning. The <b>Soo</b> family moved from <b>Malaysia</b> to{' '}
        <b>Australia</b>. Matthew was sad that he had to leave his friends but his parents said that
        he will have a good day, Matthew hoped so.
      </p>
      <p>
        So here Matthew was, walking to school. He stepped over some branches and a bush. Matthew
        saw a clearing up ahead. He looked and saw his school next to another building. It was a
        school but had black windows. He started to walk past it then heard a noise, a{' '}
        <b>howling</b> noice coming from the weird school.
      </p>
      <p>
        Matthew was about to investigate when he looked at his watch, it was <b>8:48am</b>, he ran
        to his school just in time to see his year go to their classroom. The class started off by
        Matthew introducing himself, then they did writing then reading. After that Soundwaves, then
        it was lunch.
      </p>
      <p>
        Matthew found his class pretty good, he even liked it a bit. Lunch was pretty fun, he played{' '}
        <b>handball</b> for a bit. Then his eye caught the wierd school building. Matthew thought
        about the strange howling noise. He said to himself <b>&quot;What made that sound?&quot;</b>
        . Matthew was going to investigate tomorrow.
      </p>
      <p>
        He was in class again. They did Health, History, Religion and Science. It was second lunch,
        this time they were playing <b>soccer</b>. But Matthew wasn&apos;t interested in the game.
        He was thinking about the strange school and the howling.
      </p>
      <p>
        At night Matthew didn&apos;t say a single word. He was thinking about the strange school. At
        night he couldn&apos;t sleep, just kept tossing and turning, finally he drifted off to
        sleep.
      </p>
      <p>
        Matthew woke up, got dressed and ran outside. He ran over branches and a bush. He ran and
        ran past the weird school, again he heard the howling noise. Matthew ignored it and ran to
        school. Matthew got there panting, went to his chair and started to work. While he was
        working, he said to himself{' '}
        <b>&quot;I will investigate that school tonight at midnight!&quot;</b>
      </p>
      <p>
        Matthew went home, changed his clothes, had a shower, ate then went to bed. He waited till
        midnight. Then changed his clothes, opened the door and stepped outside into the night. His
        shoes crunched on the dead leaves. He ran and ran till he was at the school door. Matthew
        opened it and stepped inside.
      </p>

      <div className="flex justify-evenly">
        <img width="200" height="200" src={SKULL_IMAGE} alt="skull" />
        <img width="200" height="200" src={SKULL_IMAGE} alt="skull" />
      </div>
      <p>
        The school was black, then <b>skulls</b> appeared and started attacking Matthew. Matthew
        dodged, punched, blocked and kicked. But there were too many skulls. He then said:
        <b>&quot;Rodimus Prime, appear and attack those skulls!&quot;</b>. Rodimus appeared in a
        flash of light, took out his rifle and sword and started attacking them.
      </p>
      <img src={HOT_ROD_IMAGE} alt="Hot Rod" />
      <p>
        It was a <b>tough</b> fight, skulls came charging and Matthew <b>punched</b> them,{' '}
        <b>side-kicked</b> them, <b>back-kicked</b> them and <b>threw</b> them. Rodimus was shooting
        them, cutting, kicking them and punching them. But there was still too much. Rodimus Prime
        said: <b>&quot;Ultra Magnus appear!&quot;</b>. Ultra Magnus appeared in a flash of light. He
        started shooting them with his gun and firing missiles at them.
      </p>
      <img src={ULTRA_MAGNUS_IMAGE} alt="Ultra Magnus" />
      <p>
        Finally all the skulls were <b>killed</b>, then a very ugly <b>demon</b> rose up and started
        shooting at us. We ducked for cover, Ultra Magnus was firing back, Rodimus was shooting and
        Matthew was climbing the wall.
      </p>
      <p>
        He then <b>jumped</b> on the demon and got his gun and started firing at the demon&apos;s
        head. The demon spun around waving his hands above his head trying to shake Matthew off.
        Rodimus realised this was a <b>distraction</b>, he quickly got his trailer, opened it, got
        his big gun, pushed the trigger and fired.
      </p>
      <p>
        The demon didn&apos;t notice it and was shot. Luckily for Matthew he saw the bullet coming
        and jumped off just in time to survive. Matthew looked at the demon then looked at the
        walls. Things were <b>changing!</b>
      </p>
      <img src={HAPPY_KIDS_IMAGE} alt="happy kids" />
      <p>
        The windows weren&apos;t black, the skulls were now <b>kids</b> and everything was normal.
        Matthew realised that the demon put a curse on the school to make it creepy. Matthew asked a
        kid <b>&quot;How long were you imprisoned for?&quot;</b>. The kid replied{' '}
        <b>&quot;For centuries!&quot;</b>. Matthew said to himself looks like the kids are saved by
        Matthew.
      </p>
    </>
  )
}
