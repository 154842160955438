import BEEF_SANDWICH_IMAGE from '@srcroot/assets/images/family/recipes/beef-sandwich/beef-sandwich.jpg'
import UNITED_KINGDOM_FLAG_IMAGE from '@srcroot/assets/images/family/recipes/Flag_of_the_United_Kingdom-300x150.png'

export const BrevilleSteakSandwich = () => {
  return (
    <>
      <h1>Breville Steak sandwich</h1>
      <img src={BEEF_SANDWICH_IMAGE} alt="Beef sandwich" />
      <br />

      <img width="120" height="80" src={UNITED_KINGDOM_FLAG_IMAGE} alt="United Kingdom flag" />

      <h1>Preparation</h1>
      <ol>
        <li>cut tomatoes</li>
        <li>warm up beef (microwave)</li>
        <li>breville (toast) cheese sandwich: put in sandwich</li>
        <ol>
          <li>beef (cut kids in pieces)</li>
          <li>tomato</li>
          <li>rocket / vegetables</li>
        </ol>
      </ol>
    </>
  )
}
