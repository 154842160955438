import AUTH0_LOGO_IMAGE from '@srcroot/assets/images/jason/wiki/auth0/auth0.png'
import AUTH0_JWT_ISTIO_AUTHORISE_FLOW_IMAGE from '@srcroot/assets/images/jason/wiki/auth0/jwt-istio-authorize-flow.png'

import { Footer } from '@srcroot/components/footer'

import { grayText, hyperlink } from '@srcroot/utils/commonCss'

import { scrollToSection } from '@srcroot/utils/scrollToSection'

export const WikiAuth0ConnectivityTesting = () => {
  const bashAuth0Script = `
#!/bin/bash

read -r -d '' DATA << EOF
{
"client_id":"xxxGetClientIdxxx",
"client_secret":"xxxGetClientSecretxxx",
"audience":"https://api.dev.xxx.cloud/customer/onboarding/v1/application",
"grant_type":"client_credentials"
}
EOF

curl --request POST 
--url https://xxx-customer-dev.au.auth0.com/oauth/token 
--header 'content-type: application/json' 
--data "$DATA"
    `

  const auth0CurlCommand = `
// Internal url, eg https://moa.digital.dev.xxx.cloud/onboarding/v1/application
// external url, eg https://api.dev.xxx.cloud/customer/onboarding/v1/application
curl -v --request POST --url https://moa.digital.dev.xxx.cloud/customer/onboarding/v1/application 
--header 'Authorization: Bearer xxxGetAuth0Tokenxxxx' 
--header 'Content-Type: application/json' 
--data '{
    "opportunityId": "0064Y000002DPuzFAA",
    "primaryContactName": "Ev M",
    "primaryContactEmail": "peter.parker@spiderman.com",
    "businessName":"SF DEV Test",
    "primaryContactPhone":"+61404000022",
    "abn": "47099389510"
}'
    `

  return (
    <>
      <ol>
        <li className={hyperlink()}>
          <button
            data-testid="auth0-connectivity-testing-link"
            onClick={() => scrollToSection('#connectivity-testing')}
          >
            How to use Auth0 token in rest call?
          </button>
        </li>
      </ol>

      <h1 data-testid="wiki-auth0-connectivity-header">What is Auth0</h1>
      <h2 className={grayText()}>13 Nov 2022</h2>

      <p>
        Auth0 is a <b>commercial</b> platform that performs <b>authentication</b> &{' '}
        <b>authorisation</b> for your application. It supports{' '}
        <a
          href="https://auth0.com/docs/authenticate/single-sign-on"
          target="_blank"
          rel="noreferrer"
        >
          Single Sign On
        </a>{' '}
        (SSO),{' '}
        <a
          href="https://auth0.com/docs/authenticate/protocols/oauth"
          target="_blank"
          rel="noreferrer"
        >
          OAuth 2.0
        </a>{' '}
        and{' '}
        <a
          href="https://auth0.com/docs/authenticate/protocols/saml/saml-configuration"
          target="_blank"
          rel="noreferrer"
        >
          SAML
        </a>{' '}
        <b>protocols</b>. We use it for token based authentication for Machine to Machine (
        <b>M2M</b>) API flow.
      </p>

      <img width="250" height="250" src={AUTH0_LOGO_IMAGE} alt="Auth0 logo" />

      <p>
        What is Auth0?{' '}
        <a
          href="https://www.quora.com/What-is-Auth0-and-how-does-it-work"
          target="_blank"
          rel="noreferrer"
        >
          Quora
        </a>
        ,{' '}
        <a
          href="https://traefik-forward-auth0.readthedocs.io/en/latest/auth0/auth0.html"
          target="_blank"
          rel="noreferrer"
        >
          Traefik
        </a>
      </p>
      <p>
        Auth0{' '}
        <a
          href="https://auth0.com/docs/get-started/auth0-overview"
          target="_blank"
          rel="noreferrer"
        >
          overview
        </a>
        ,{' '}
        <a href="https://auth0.com/docs/get-started" target="_blank" rel="noreferrer">
          get started
        </a>
      </p>
      <p>
        Reasons to use Auth0?{' '}
        <a
          href="https://www.xano.com/blog/5-reasons-to-use-auth0-for-authentication-in-your-application/"
          target="_blank"
          rel="noreferrer"
        >
          Xano
        </a>
        ,{' '}
        <a
          href="https://agilie.com/blog/reasons-to-use-auth0-for-authentication-in-your-application"
          target="_blank"
          rel="noreferrer"
        >
          Agilie
        </a>
      </p>
      <p>
        Auth0 features & benefits?{' '}
        <a href="https://www.mindbowser.com/what-is-auth0/" target="_blank" rel="noreferrer">
          Mind Bowser
        </a>
      </p>

      <h2>Auth0 Machine To Machine API flow</h2>
      <img src={AUTH0_JWT_ISTIO_AUTHORISE_FLOW_IMAGE} alt="Auth0 JWT istio authorise flow" />

      <hr />
      <h1 id="connectivity-testing" data-testid="connectivity-testing-anchor">
        How to use Auth0 token in rest call?
      </h1>
      <h2>Get Auth0 token</h2>
      <pre>{bashAuth0Script}</pre>

      <h2>Use auth0 token in post call</h2>
      <pre>{auth0CurlCommand}</pre>
      <Footer />
    </>
  )
}
