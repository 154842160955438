export const Technology = () => {
  return (
    <p>
      Hi, I love using <b>technology</b> to solve problems.
      <br />
      I&apos;m passionate about software that makes customers <strong>awesome</strong>.<br />I like
      being socially minded and giving back to community.
    </p>
  )
}
