import CIDR_2_ADDRESSES_IMAGE from '@srcroot/assets/images/jason/blog/2022/cidr/cidr-2-addresses.png'
import NETWORK_HOST_PORTIONS_IMAGE from '@srcroot/assets/images/jason/blog/2022/cidr/network-host-portions.png'
import CIDR_NETWORK_IMAGE from '@srcroot/assets/images/jason/blog/2022/cidr/cidr-network.jpg'
import CIDR_CLASSES_IMAGE from '@srcroot/assets/images/jason/blog/2022/cidr/cidr-classes.png'
import CIDR_CLASS_SIZING_IMAGE from '@srcroot/assets/images/jason/blog/2022/cidr/cidr-class-sizing.png'

import { Footer } from '@srcroot/components/footer'

import { grayText, hyperlink, redText } from '@srcroot/utils/commonCss'

import { scrollToSection } from '@srcroot/utils/scrollToSection'

export const BlogCidrAddress = () => {
  return (
    <>
      <ol>
        <li className={hyperlink()}>
          <button
            data-testid="calculate-cidr-link"
            onClick={() => scrollToSection('#calculate-cidr')}
          >
            How to calculate CIDR address?
          </button>
        </li>
      </ol>
      <h1 data-testid="blog-cidr-address-header">CIDR Notation</h1>

      <h2 className={grayText()}>12 Oct 2022</h2>
      <p>
        I recently had to <b>whitelist</b> some IP addresses for a client to connect over the{' '}
        <b>public internet</b>. We have a <b>Kubernetes</b> application that had to open up via{' '}
        <b>Public Ingress</b> (incoming firewall rules), secured with an <b>OAuth2</b> token.
      </p>
      <p>
        The Ingress rules require <b>CIDR</b> addresses. When I used{' '}
        <span className={redText()}>
          standard IP addresses the Terraform project failed on the <b>plan</b> step
        </span>{' '}
        as the addresses were not CIDR addresses.
      </p>

      <img src={CIDR_2_ADDRESSES_IMAGE} alt="CIDR addresses example" />

      <h2>What is CIDR?</h2>
      <p>
        Classless Inter-Domain Routing (<b>CIDR</b>) is a method for allocating IP addresses and IP
        routing (<b>subnet</b>).
      </p>
      <p>
        Good article about{' '}
        <a href="https://www.educba.com/what-is-cidr" target="_blank" rel="noreferrer">
          how to calculate
        </a>{' '}
        CIDR addresses
      </p>
      <p>
        Good article for{' '}
        <a href="https://www.ipxo.com/blog/what-is-cidr/" target="_blank" rel="noreferrer">
          beginners
        </a>
      </p>
      <p>
        CIDR{' '}
        <a href="https://www.ipaddressguide.com/cidr" target="_blank" rel="noreferrer">
          calculator
        </a>
      </p>
      <img src={NETWORK_HOST_PORTIONS_IMAGE} alt="Network Host portions" />

      <h2>Why is it important?</h2>
      <p>
        It can be used to define a <b>subnet</b> (network within a network) that can be used to{' '}
        <b>whitelist</b> IPs. For example, define the list of IP addresses allowed to connect
        through the firewall.
      </p>
      <img src={CIDR_NETWORK_IMAGE} alt="CIDR network" />

      <hr />

      <h1>Classes of CIDR</h1>
      <img src={CIDR_CLASSES_IMAGE} alt="CIDR classes" />
      <p>
        <b>Class A:</b> only the <b>first bit</b> is considered. It can take the IP address from
        1.X.X.X to 126.X.X.X
      </p>
      <p>
        <b>Class B:</b> first <b>two bits</b> are considered. It can take the IP address from
        128.0.X.X to 191.255.X.X
      </p>
      <p>
        <b>Class C:</b> first <b>three bits</b> are considered. It can take the IP address from
        192.0.0.X to 223.255.255.X
      </p>
      <p>
        <b>Class D:</b> first <b>four bits</b> are considered. It can take the IP address from
        224.0.0.0 to 239.255.255.255. This Class is used for multicasting
      </p>
      <p>
        <b>Class E</b>: reserved for <b>Research and Development</b>. It can take the IP address
        from 240.0.0.0 to 255.255.255.254
      </p>

      <img src={CIDR_CLASS_SIZING_IMAGE} alt="CIDR class sizing" />

      <h2 id="calculate-cidr" data-testid="calculate-cidr-anchor">
        How to calculate CIDR address?
      </h2>
      <p>
        CIDR block contains IP addresses, consisting of <b>3 basic rules</b>
      </p>
      <ol>
        <li>
          In the CIDR block, the IP addresses which are allocated to the hosts should be{' '}
          <b>continuous</b>
        </li>
        <li>
          Size of the block should be of <b>power 2</b> and should be equal to the total number of
          IP addresses
        </li>
        <li>Size of the block must be divisible by the first IP address of the block.</li>
      </ol>

      <hr />

      <h2>Example 1 (larger range)</h2>
      <p>
        <b>Start IP</b> address = 21.19.35.64, <b>End IP</b> address = 21.19.35.127
        <br />
        <b>Range</b> = 127 - 64 + 1 = 64
        <br />
        Size of the block: 2^<b>6</b> = 64
        <br />
        <b>Number of bits</b> = 32 - 6 = 26
        <br />
        <b>CIDR block</b> = 21.19.35.64<b>/26</b>
      </p>

      <h2>Example 2 (smaller range)</h2>
      <p>
        <b>Start IP</b> address = 10.20.16.254, <b>End IP</b> address = 10.20.16.255
        <br />
        <b>Range</b> = 255 - 254 + 1 = 2<br />
        Size of the block: 2^<b>1</b> = 2<br />
        <b>Number of bits</b> = 32 - 1 = 31
        <br />
        <b>CIDR block</b> = 10.20.16.254<b>/31</b>
      </p>

      <h2>Example 3 (smallest range)</h2>
      <p>
        <b>Start IP</b> address = 10.20.16.254, <b>End IP</b> address = 10.20.16.254
        <br />
        <b>Range</b> = 254 - 254 + 1 = 1<br />
        Size of the block: 2^<b>0</b> = 1<br />
        <b>Number of bits</b> = 32 - 0 = 32
        <br />
        <b>CIDR block</b> = 10.20.16.254<b>/32</b>
      </p>

      <Footer />
    </>
  )
}
