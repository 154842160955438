import { Link } from 'react-router-dom'

import { grayText } from '@srcroot/utils/commonCss'

export const Recipes = () => {
  return (
    <>
      <h2 className={grayText()}>20 Oct 2022</h2>

      <p>
        <Link to="cleanKettle">How to clean a kettle naturally with lemon</Link>
      </p>

      <h1 data-testid="recipes-header">Vegetables</h1>
      <ol>
        <li>
          <Link to="thermomixLentilStew">Thermomix Lentil stew</Link> (spanish)
        </li>
        <li>
          <Link to="thermomixPumpkinSoup">Thermomix Pumpkin soup</Link> (spanish)
        </li>
        <li>
          <Link to="thermomixSpinachSoup">Thermomix Spinach soup</Link> (spanish)
        </li>
      </ol>

      <h1>Rice & Pasta</h1>
      <ol>
        <li>
          <Link to="pressureCookerWhiteBrownRice">Pressure Cooker white or brown rice</Link>{' '}
          (english)
        </li>
        <li>
          <Link to="ovenPizza">Oven Pizza</Link> (english)
        </li>
        <li>
          <Link to="thermomixWhiteBeansRice">Thermomix White Beans and rice</Link> (spanish)
        </li>
        <li>
          <Link to="thermomixTunaSoupTomatoRice">Thermomix Tuna Soup and Tomato rice</Link>{' '}
          (spanish)
        </li>
      </ol>

      <h1>Meat & Fish</h1>
      <ol>
        <li>
          <Link to="brevilleSteakSandwich">Breville steak sandwich</Link> (english)
        </li>
        <li>
          <Link to="thermomixBolognese">Thermomix Bolognese</Link> (spanish)
        </li>
        <li>
          <Link to="thermomixCurryChicken">Thermomix Curry chicken</Link> (english)
        </li>
        <li>
          <Link to="panFriedChickenBreast">Pan fried juicy Chicken breasts</Link> (english)
        </li>
      </ol>

      <h1>Dessert</h1>
      <ol>
        <li>
          <Link to="microwaveChocolateCakeEggFree">Microwave Chocolate Cake egg free</Link>{' '}
          (english)
        </li>
      </ol>
    </>
  )
}
