import BOLOGNESE_SAUCE_IMAGE from '@srcroot/assets/images/family/recipes/bolognese/bolognese-sauce.jpg'
import SPANISH_FLAG_IMAGE from '@srcroot/assets/images/family/recipes/Flag_of_Spain-300x200.png'

export const ThermomixBolognese = () => {
  return (
    <>
      <h1>Thermomix Bolognese</h1>
      <img src={BOLOGNESE_SAUCE_IMAGE} alt="Bolognese sauce" />
      <br />

      <img width="120" height="80" src={SPANISH_FLAG_IMAGE} alt="Spanish flag" />

      <h1>Ingredientes</h1>
      <ol>
        <li>130 g zanahorias</li>
        <li>130 g cebollas</li>
        <li>1 diente ajo</li>
        <li>50 g pimiento verde</li>
        <li>apio</li>
        <li>180 g de champiñones</li>
        <li>500 g tomate triturado</li>
        <li>50 g aceite de oliva virgen extra</li>
        <li>500 g carne picada</li>
        <li>1 hoja laurel</li>
        <li>1 cucharadita de sal</li>
        <li>1 pellizco pimienta</li>
        <li>1 cucharada orégano</li>
      </ol>

      <hr />

      <h1>Preparación</h1>
      <ol>
        <li>
          Ponemos en el vaso las zanahorias, el ajo, la cebolla, el pimiento verde, apio,
          champiñones, aceite y tomate y trituramos <b>15 segundos, velocidad 5.</b>
        </li>
        <li>
          Sofreímos <b>7 minutos, temperatura varoma, velocidad 1.</b>
        </li>
        <li>
          Ponemos la carne picada, la sal, la hoja de laurel y el resto de especias. Mezclamos bien
          la carne con las verduras ayudándonos de la espátula y programamos{' '}
          <b>15 minutos, temperatura varoma, giro a la izquierda, velocidad cuchara.</b>
        </li>
        <li>
          Dejamos reposar en el vaso unos minutos y rectificamos el punto de sal. Si nos queda muy
          líquida lo ponemos <b>5 minutos más.</b>
        </li>
      </ol>
    </>
  )
}
