import { hyperlink } from '@srcroot/utils/commonCss'
import { scrollToSection } from '@srcroot/utils/scrollToSection'

export const AthleticsTableOfContents = () => {
  return (
    <>
      <ol>
        <li>Athletics tracks around Sydney</li>
        <li className={hyperlink()}>
          <button data-testid="warm-up-link" onClick={() => scrollToSection('#warm-up')}>
            How to warm up
          </button>
        </li>
        <li className={hyperlink()}>
          <button
            data-testid="one-hundred-meter-dash-link"
            onClick={() => scrollToSection('#one-hundred-meter-dash')}
          >
            Running 100m dash
          </button>
        </li>
        <ol>
          <li className={hyperlink()}>
            <button
              data-testid="one-hundred-meter-phases-link"
              onClick={() => scrollToSection('#one-hundred-meter-phases')}
            >
              Phases of 100m
            </button>
          </li>
          <li className={hyperlink()}>
            <button
              data-testid="start-no-blocks-link"
              onClick={() => scrollToSection('#start-no-blocks')}
            >
              Best start without blocks
            </button>
          </li>
          <li className={hyperlink()}>
            <button
              data-testid="one-hundred-meter-drills-link"
              onClick={() => scrollToSection('#one-hundred-meter-drills')}
            >
              100m drills
            </button>
          </li>
        </ol>
        <li className={hyperlink()}>
          <button
            data-testid="two-hundred-meter-dash-link"
            onClick={() => scrollToSection('#two-hundred-meter-dash')}
          >
            Running 200m dash
          </button>
        </li>
        <ol>
          <li className={hyperlink()}>
            <button
              data-testid="two-hundred-meter-phases-link"
              onClick={() => scrollToSection('#two-hundred-meter-phases')}
            >
              Phases of 200m
            </button>
          </li>
          <li className={hyperlink()}>
            <button
              data-testid="two-hundred-meter-drills-link"
              onClick={() => scrollToSection('#two-hundred-meter-drills')}
            >
              200m drills
            </button>
          </li>
        </ol>
        <li className={hyperlink()}>
          <button
            data-testid="more-technical-link"
            onClick={() => scrollToSection('#more-technical')}
          >
            More Technical
          </button>
        </li>
        <li className={hyperlink()}>
          <button data-testid="measure-100m-link" onClick={() => scrollToSection('#measure-100m')}>
            How to measure 100m?
          </button>
        </li>
      </ol>
    </>
  )
}
