import TRANSFORMERS_TOYS_IMAGE from '@srcroot/assets/images/matthew/transformers-vs-soldiers/transformers-toys.png'
import SOLDIERS_TOYS_IMAGE from '@srcroot/assets/images/matthew/transformers-vs-soldiers/soldiers-toys.png'
import TRANSFORMERS_G1_CARTOON_IMAGE from '@srcroot/assets/images/matthew/transformers-vs-soldiers/transformers-g1.png'
import EIGHT_HUNDRED_SOLDIERS_IMAGE from '@srcroot/assets/images/matthew/transformers-vs-soldiers/the-eight-hundred-soldiers.png'

import { grayText } from '@srcroot/utils/commonCss'

export const TransformersVsSoldiers = () => {
  return (
    <>
      <h1 data-testid="transformers-vs-soldiers-header">Toys: Transformers vs Soldiers</h1>

      <h2 className={grayText()}>5 Jun 2022</h2>

      <div className="grid grid-cols-1 md:grid-cols-12 gap-5 my-4">
        <div className="md:col-span-6 flex justify-evenly">
          <img src={TRANSFORMERS_TOYS_IMAGE} alt="Transformers toys" />
        </div>
        <div className="md:col-span-6 flex justify-evenly">
          <img src={SOLDIERS_TOYS_IMAGE} alt="Soldiers toys" />
        </div>
      </div>

      <h2>Which is better?</h2>
      <p>
        I <b>strongly</b> believe that <b>Transformers</b> are better than Soldiers
      </p>
      <ol>
        <li>
          Because Soldiers have one mode and Transformers have several modes. One Transformer can
          turn into 5 things plus his robot mode (eg <b>Sixshot</b>)
        </li>
        <li>
          If you need a toy car to move the Soldiers, you will have to get a toy car. If you
          dont&apos; have a toy car a Transformer is the <b>perfect</b> tool to use because you can
          transform the Transformer into a car.
        </li>
        <li>
          Some Transformers can combine into a big robot (<b>combiner bot</b>, eg <b>Devastator</b>)
          whereas the toy Soldiers can&apos;t combine.
        </li>
      </ol>
      <p>Thats why I strongly believe that transformers are better than soldiers.</p>
      <p>
        If you want to compare transformers vs soldiers to see which is more <b>fun</b> to play
        with, watch the <b>videos</b> below.
      </p>

      <ol>
        <li>
          TV series:
          <a
            href="https://www.youtube.com/watch?v=Y1ujpoDlgRU&list=PLD3dCMp2WHiwrVPo2XaZpkWp3K3xpx3h8"
            target="_blank"
            rel="noreferrer"
          >
            Transformers G1
          </a>{' '}
          (1984 - 1987, IMDb Rating = <b>8</b> / 10)
        </li>
        <li>
          Movie:
          <a href="https://www.youtube.com/watch?v=akKi2oakykY" target="_blank" rel="noreferrer">
            The Eight Hundred
          </a>{' '}
          (2020, IMDb Rating = <b>6.7</b> / 10)
        </li>
      </ol>

      <div className="grid grid-cols-1 md:grid-cols-12 gap-5 my-4">
        <div className="md:col-span-6 flex justify-evenly">
          <img src={TRANSFORMERS_G1_CARTOON_IMAGE} alt="Transformers G1 cartoon" />
        </div>
        <div className="md:col-span-6 flex justify-evenly">
          <img src={EIGHT_HUNDRED_SOLDIERS_IMAGE} alt="Eight hundred soldiers" />
        </div>
      </div>
    </>
  )
}
