import BROWN_RICE_IMAGE from '@srcroot/assets/images/family/recipes/rice/brown-rice.jpeg'
import WHITE_RICE_IMAGE from '@srcroot/assets/images/family/recipes/rice/white-rice.jpg'
import UNITED_KINGDOM_FLAG_IMAGE from '@srcroot/assets/images/family/recipes/Flag_of_the_United_Kingdom-300x150.png'

export const PressureCookerWhiteOrBrownRice = () => {
  return (
    <>
      <h1>Pressure Cooker white or brown rice</h1>
      <p>Changes from glass to induction cooker (5 Feb 2022)</p>
      <img src={BROWN_RICE_IMAGE} alt="Brown rice" />
      <img src={WHITE_RICE_IMAGE} alt="White rice" />
      <br />

      <img width="120" height="80" src={UNITED_KINGDOM_FLAG_IMAGE} alt="United Kingdom flag" />

      <h1>Brown rice</h1>
      <p>You can use small pressure cooker.</p>
      <ol>
        <li>
          Put oil: like 1 table spoon (tblsp) and garlic <b>(power 5)</b>
        </li>
        <li>
          Once garlic golden add 1.5 full glass of brown rice. Stir until it&apos;s fully mixed
        </li>
        <li>
          Add 2.5 full glass of water (3 water exploded in pressure pot so try 2.5 water). Stir.
        </li>
        <li>Add salt and bay leave (rinse it before adding it). Stir.</li>
        <li>
          Close lid. Put <b>power to high (eg 9)</b>
        </li>
        <li>
          Once 2 orange rings out, put <b>power to 4</b> and start counting <b>14 min.</b>
        </li>
        <li>
          Once finished, switch it off and let the pressure to get out before opening the lid.
        </li>
      </ol>

      <h1>White rice</h1>
      <p>You can use medium size pressure cooker.</p>
      <ol>
        <li>
          Put oil: like 1 table spoon (tblsp) and garlic <b>(power 6)</b>
        </li>
        <li>Once garlic golden add 2 full glass of white rice. Stir until it&apos;s fully mixed</li>
        <li>Add 3.5 glasses of water. Stir.</li>
        <li>Add salt and bay leave (rinse it before adding it). Stir.</li>
        <li>
          Close lid. Put <b>power to 8</b>
        </li>
        <li>
          Once 2 orange rings out, put <b>power to 6</b> and start counting <b>4.5 min.</b>
        </li>
        <li>
          Once finished, switch it off and let the pressure to get out before opening the lid.
        </li>
      </ol>
    </>
  )
}
