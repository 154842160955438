import PUMPKIN_SOUP_IMAGE from '@srcroot/assets/images/family/recipes/pumpkin-soup/pumpkin-soup-with-a-twist-71237-1.jpeg'
import SPANISH_FLAG_IMAGE from '@srcroot/assets/images/family/recipes/Flag_of_Spain-300x200.png'

export const ThermomixPumpkinSoup = () => {
  return (
    <>
      <h1>Thermomix Pumpkin soup</h1>
      <img src={PUMPKIN_SOUP_IMAGE} alt="Pumpkin soup" />
      <br />

      <img width="120" height="80" src={SPANISH_FLAG_IMAGE} alt="Spanish flag" />

      <h1>Ingredientes</h1>
      <ol>
        <li>500 gr. calabaza (trozos)</li>
        <li>150 gr. patatas</li>
        <li>150 gr. zanahorias</li>
        <li>50 gr. cebolla</li>
        <li>1 ajo</li>
        <li>60 gr. pimiento rojo</li>
        <li>1 tomate</li>
        <li>40 gr. aceite oliva virgen</li>
        <li>10 gr. sal</li>
        <li>500 gr. agua</li>
      </ol>

      <hr />

      <h1>Preparación</h1>
      <ol>
        <li>
          Poner en el vaso el ajo, cebolla y pimientos. <b>5 segundos V5</b>. Seguidamente programar{' '}
          <b>5 minutos, varoma</b>, V1.Agregar los ingredientes restantes,calabaza, patata,
          zanahoria, tomate, agua y sal. <b>30 minutos, varoma</b> V1.Terminado triturar{' '}
          <b>1 minuto, velocidad 5/10</b>
        </li>
      </ol>
    </>
  )
}
