import { Route, Routes } from 'react-router-dom'

import { Banner } from './components/banner'
import { NavigationBar } from './components/navigation-bar'

import { Home } from './pages/home'
import { Blog } from './pages/jason/blog'
import { BlogCidrAddress } from './pages/jason/blog/2022/cidr-address'
import { BlogBestValueLaptop } from './pages/jason/blog/2018/best-value-laptop'
import { BlogDocumentationQuadrants } from './pages/jason/blog/2024/documentation-quadrants'
import { WikiAuth0ConnectivityTesting } from './pages/jason/wiki/tutorials/auth0-connectivity-testing'
import { WikiKubernetesCheatSheet } from './pages/jason/wiki/reference/kubernetes-cheat-sheet'
import { Wiki } from './pages/jason/wiki'

import { Matthew } from './pages/matthew'
import { TransformersHotRod } from './pages/matthew/blog/transformers-hot-rod'
import { TransformersVsSoldiers } from './pages/matthew/blog/transformers-vs-soldiers'
import { EnterHauntedSchool } from './pages/matthew/blog/enter-haunted-school'
import { Geography } from './pages/matthew/geography'
import { ErrorPageNotFound } from './pages/home/errorPageNotFound'
import { PapuaNewGuinea } from './pages/matthew/geography/papua-new-guinea'

import { James } from './pages/james'
import { TransformersUltraMagnus } from './pages/james/blog/ultra-magnus'

import { Family } from './pages/family'
import { Basketball } from './pages/family/wiki/basketball'
import { Soccer } from './pages/family/wiki/soccer'
import { Athletics } from './pages/family/wiki/athletics'
import { Recipes } from './pages/family/recipes'
import { Covid } from './pages/family/covid'

import { ThermomixLentilStew } from './pages/family/recipes/vegetables/thermomixLentilStew'
import { ThermomixPumpkinSoup } from './pages/family/recipes/vegetables/thermomixPumpkinSoup'
import { ThermomixSpinachSoup } from './pages/family/recipes/vegetables/thermomixSpinachSoup'
import { PressureCookerWhiteOrBrownRice } from './pages/family/recipes/riceAndPasta/pressureCookerWhiteBrownRice'
import { OvenPizza } from './pages/family/recipes/riceAndPasta/ovenPizza'
import { ThermomixWhiteBeansAndRice } from './pages/family/recipes/riceAndPasta/thermomixWhiteBeansRice'
import { ThermomixTunaSoupTomatoRice } from './pages/family/recipes/riceAndPasta/thermomixTunaSoupTomatoRice'
import { BrevilleSteakSandwich } from './pages/family/recipes/meatAndFish/brevilleSteakSandwich'
import { ThermomixBolognese } from './pages/family/recipes/meatAndFish/thermomixBolognese'
import { ThermomixCurryChicken } from './pages/family/recipes/meatAndFish/thermomixCurryChicken'
import { PanFriedChickenBreast } from './pages/family/recipes/meatAndFish/panFriedJuicyChickenBreast'
import { MicrowaveChocolateCakeEggFree } from './pages/family/recipes/dessert/microwaveChocolateCakeEggFree'
import { WikiBuyPropertyNswAus } from './pages/jason/wiki/how-to-guides/buy-property-nsw-aus'
import { Tutorials } from './pages/jason/wiki/tutorials'
import { Reference } from './pages/jason/wiki/reference'
import { HowToGuides } from './pages/jason/wiki/how-to-guides'
import { CleanKettle } from './pages/family/recipes/cleanKettle'
import { BodySurfing } from './pages/family/wiki/body-surfing'

export const BannerNavBarRoutes = () => {
  return (
    <>
      <Banner />
      <NavigationBar />
      {/* Split out HashRouter from Routes so we can use MemoryRouter in tests */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/blog">
          <Route index element={<Blog />} />
          <Route path="cidrAddress" element={<BlogCidrAddress />} />
          <Route path="bestValueLaptop" element={<BlogBestValueLaptop />} />
          <Route path="documentationQuadrants" element={<BlogDocumentationQuadrants />} />
        </Route>
        <Route path="/wiki">
          <Route index element={<Wiki />} />
          <Route path="tutorial">
            <Route index element={<Tutorials />} />
            <Route path="auth0ConnectivityTesting" element={<WikiAuth0ConnectivityTesting />} />
          </Route>
          <Route path="reference">
            <Route index element={<Reference />} />
            <Route path="kubernetesCheatSheet" element={<WikiKubernetesCheatSheet />} />
          </Route>
          <Route path="howto">
            <Route index element={<HowToGuides />} />
            <Route path="buyPropertyNswAus" element={<WikiBuyPropertyNswAus />} />
          </Route>
        </Route>
        <Route path="/matt">
          <Route index element={<Matthew />} />
          <Route path="transformersHotRod" element={<TransformersHotRod />} />
          <Route path="transformersVsSoldiers" element={<TransformersVsSoldiers />} />
          <Route path="enterHauntedSchool" element={<EnterHauntedSchool />} />
          <Route path="geography">
            <Route index element={<Geography />} />
            <Route path="papuaNewGuinea" element={<PapuaNewGuinea />} />
          </Route>
        </Route>
        <Route path="/james">
          <Route index element={<James />} />
          <Route path="transformersUltraMagnus" element={<TransformersUltraMagnus />} />
        </Route>
        <Route path="/family">
          <Route index element={<Family />} />
          <Route path="basketballTraining" element={<Basketball />} />
          <Route path="soccerTraining" element={<Soccer />} />
          <Route path="athleticsTraining" element={<Athletics />} />
          <Route path="howToBodySurf" element={<BodySurfing />} />
          <Route path="recipes">
            <Route index element={<Recipes />} />
            <Route path="cleanKettle" element={<CleanKettle />} />
            <Route path="thermomixLentilStew" element={<ThermomixLentilStew />} />
            <Route path="thermomixPumpkinSoup" element={<ThermomixPumpkinSoup />} />
            <Route path="thermomixSpinachSoup" element={<ThermomixSpinachSoup />} />
            <Route
              path="pressureCookerWhiteBrownRice"
              element={<PressureCookerWhiteOrBrownRice />}
            />
            <Route path="ovenPizza" element={<OvenPizza />} />
            <Route path="thermomixWhiteBeansRice" element={<ThermomixWhiteBeansAndRice />} />
            <Route path="thermomixTunaSoupTomatoRice" element={<ThermomixTunaSoupTomatoRice />} />
            <Route path="brevilleSteakSandwich" element={<BrevilleSteakSandwich />} />
            <Route path="thermomixBolognese" element={<ThermomixBolognese />} />
            <Route path="thermomixCurryChicken" element={<ThermomixCurryChicken />} />
            <Route path="panFriedChickenBreast" element={<PanFriedChickenBreast />} />
            <Route
              path="microwaveChocolateCakeEggFree"
              element={<MicrowaveChocolateCakeEggFree />}
            />
          </Route>
          <Route path="covid" element={<Covid />} />
        </Route>
        <Route path="*" element={<ErrorPageNotFound />} />
      </Routes>
    </>
  )
}
