import MY_RESUME from '@srcroot/assets/attachments/jason-soo-resume-2015.pdf'

export const Resume = () => {
  return (
    <p>
      <a
        data-testid="home-about-me-link"
        href="https://about.me/jasonsoo"
        target="_blank"
        rel="noreferrer"
      >
        About me
      </a>{' '}
      & my{' '}
      <a
        data-testid="home-resume-link"
        href={MY_RESUME}
        download="jason-soo-resume-2015.pdf"
        target="_blank"
        rel="noreferrer"
      >
        resume
      </a>
    </p>
  )
}
