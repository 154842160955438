import { Page } from './Page'

export const getPageFromPath = (path: string): Page => {
  const lowerCasePath = path.toLowerCase()
  if (lowerCasePath.startsWith('/matt')) {
    return Page.MATT
  } else if (lowerCasePath.startsWith('/james')) {
    return Page.JAMES
  } else if (lowerCasePath.startsWith('/family')) {
    return Page.FAMILY
  }

  return Page.JASON
}
