import { Page } from '@srcroot/utils/Page'
import { getPageFromPath } from '@srcroot/utils/getPageFromPath'
import { ReactElement } from 'react'
import { Link, useLocation } from 'react-router-dom'

export const NavigationBar = () => {
  const location = useLocation()

  const getNavBarFromLocation = (path: string): ReactElement => {
    if (getPageFromPath(path) === Page.MATT) {
      return (
        <>
          <Link to="matt" className="px-4 py-4 hover:bg-gray-500">
            Home
          </Link>
          <Link to="matt/geography" className="px-4 py-4 hover:bg-gray-500">
            Geography
          </Link>
        </>
      )
    } else if (getPageFromPath(path) === Page.JAMES) {
      return (
        <>
          <Link to="james" className="px-4 py-4 hover:bg-gray-500">
            Home
          </Link>
        </>
      )
    } else if (getPageFromPath(path) === Page.FAMILY) {
      return (
        <>
          <Link to="family" className="px-4 py-4 hover:bg-gray-500">
            Home
          </Link>
          <Link to="family/recipes" className="px-4 py-4 hover:bg-gray-500">
            Recipes
          </Link>
          <Link to="family/covid" className="px-4 py-4 hover:bg-gray-500">
            Covid
          </Link>
        </>
      )
    }

    return (
      <>
        <Link to="" className="px-4 py-4 hover:bg-gray-500">
          Home
        </Link>
        <Link to="blog" className="px-4 py-4 hover:bg-gray-500">
          Blog
        </Link>
        <Link to="wiki" className="px-4 py-4 hover:bg-gray-500">
          Wiki
        </Link>
      </>
    )
  }

  return (
    <>
      <div className="flex my-2 text-xl">{getNavBarFromLocation(location.pathname)}</div>
    </>
  )
}
