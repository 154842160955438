import ONE_HUNDRED_METERS_DASH_IMAGE from '@srcroot/assets/images/family/athletics/100m-dash.png'
import DRIVE_PHASE_IMAGE from '@srcroot/assets/images/family/athletics/drive-phase.png'
import STARTING_BLOCKS_IMAGE from '@srcroot/assets/images/family/athletics/starting-blocks.png'
import THREE_POINT_START_IMAGE from '@srcroot/assets/images/family/athletics/3-point-start.png'
import BLOCK_START_WITHOUT_BLOCKS_IMAGE from '@srcroot/assets/images/family/athletics/block-start-without-blocks.png'

import { greenText } from '@srcroot/utils/commonCss'

export const Run100mDash = () => {
  return (
    <>
      <h1 id="one-hundred-meter-dash">Running 100m dash</h1>
      <img src={ONE_HUNDRED_METERS_DASH_IMAGE} alt="100m dash" />

      <p>
        How to{' '}
        <a href="https://www.youtube.com/watch?v=yLWejYWjANM" target="_blank" rel="noreferrer">
          win 100m
        </a>
      </p>

      <p>
        <a href="https://www.youtube.com/watch?v=_V6AKo9Ji_Q" target="_blank" rel="noreferrer">
          Training secrets
        </a>{' '}
        to running faster
      </p>
      <ol>
        <li>Train on grass to get more volume</li>
        <li>Keep the elbows in close</li>
        <li>Stretching</li>
        <li>Build a strong lower back</li>
        <li>Grow up in Jamaica</li>
      </ol>

      <p>
        <a href="https://www.youtube.com/watch?v=hff0eFIYfuY" target="_blank" rel="noreferrer">
          How to run 100m
        </a>{' '}
        as a world class sprinter
      </p>

      <h2 id="one-hundred-meter-phases">Phases of 100m dash</h2>
      <ol>
        <li>The Start</li>
        <li>Drive Phase (1st 20-30m)</li>
        <li>Transition (30-50m)</li>
        <li>Top Speed Phase (50m+)</li>
        <li>Finishing the Race</li>
      </ol>

      <h2>Race plan</h2>
      <ol>
        <li>Whole 100m, 100% (as fast as you can)</li>
        <li>Try & relax: to keep your form near the end</li>
      </ol>

      <h2>Detailed Phases</h2>
      <img src={DRIVE_PHASE_IMAGE} alt="drive phase" />
      <p>Drive Phase</p>
      <ol>
        <li>
          <b>The Start</b>
        </li>
        <ol>
          <li>Good reaction to the gun</li>
        </ol>
        <li>
          <b>Drive Phase (1st 20-30m)</b>
        </li>
        <ol>
          <li>45 degree angle to drive: head down, angle low</li>
          <li>
            Full extension, but don&apos;t want high knees. Quick steps that are <b>powerful</b> &{' '}
            <b>generating speed</b>
          </li>
          <li>
            Building <b>acceleration</b>, generating power
          </li>
          <li>Using strength from gym, flexibility, coordination</li>
          <li>
            Common mistake = stand up (top speed phase) within couple of steps, so not driving. When
            upright, you can&apos;t accelerate
          </li>
        </ol>
        <li>
          <b>Transition (30-50m)</b>
        </li>
        <ol>
          <li>From driving down to upright</li>
          <li>Slowly start to come up</li>
          <li>Starting to relax, starting to come upright</li>
          <li>Starting to get knees up</li>
          <li>Starting to go to big arms, relaxed shoulders</li>
        </ol>
        <li>
          <b>Top Speed Phase (50m+)</b>
        </li>
        <ol>
          <li>Big arms, opening up, nice & tall & big</li>
          <li>50-80m (almost end of race) = top speed</li>
          <li>
            After you hit top speed, you will slow down. Persons looking like running away from
            everyone, is just <b>slowing down less</b>
          </li>
          <li>
            How to slow down less = based on <b>Technique</b>
          </li>
          <ol>
            <li>Big long strides</li>
            <li>Nice & relaxed</li>
            <li>High knees & open strides</li>
            <li>Everything forwards & backwards</li>
            <li>
              No swaying back & forth, head going everywhere & knees going out (lose most speed, not
              being efficient with running)
            </li>
          </ol>
        </ol>
        <li>
          <b>Finishing the Race</b>
        </li>
        <ol>
          <li>End of race, keep relaxing through to finish line</li>
          <li>
            <b>Run through the line</b>
          </li>
          <li>Better to run through line than leaning. Lean way too early you can hurt yourself</li>
        </ol>
      </ol>

      <h2 id="start-no-blocks">Best start when training without blocks</h2>
      <div className="grid grid-cols-1 md:grid-cols-12 gap-5 my-4">
        <div className="md:col-span-4 flex justify-evenly">
          <img src={STARTING_BLOCKS_IMAGE} alt="Starting blocks" />
        </div>
        <div className="md:col-span-4 flex justify-evenly">
          <img src={THREE_POINT_START_IMAGE} alt="3 point start" />
        </div>
        <div className="md:col-span-4 flex justify-evenly">
          <img src={BLOCK_START_WITHOUT_BLOCKS_IMAGE} alt="Block start without blocks" />
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-12 gap-5 my-4">
        <div className="md:col-span-4 flex justify-evenly">
          <p className={greenText()}>Starting Blocks</p>
        </div>
        <div className="md:col-span-4 flex justify-evenly">
          <p className={greenText()}>3 Point Start</p>
        </div>
        <div className="md:col-span-4 flex justify-evenly">
          <p className={greenText()}>Block Start without Blocks</p>
        </div>
      </div>

      <p>
        When training without blocks, the best start position is the <b>3 Point Start</b>. Block
        start without blocks, sometimes you can <b>slip</b> in first couple of steps which makes you
        lose time
      </p>

      <h2 id="one-hundred-meter-drills">Training drills</h2>
      <p>
        How to run a{' '}
        <a href="https://www.youtube.com/watch?v=Vpd_l9jbLXY" target="_blank" rel="noreferrer">
          faster 100m dash
        </a>
      </p>
      <ul>
        <li>
          <b>6x flying 40s</b> (get to top speed & hold top speed for last 40m), 4-5min rest in
          between
        </li>
        <li>
          Unfortunately this training wasn&apos;t effective, as it was <b>confusing</b>. Relaxed too
          much at start just to go hard last 40m
        </li>
        <li>We had to ditch this & just go simple: 100% flat out all the way</li>
      </ul>

      <p>
        <a href="https://www.youtube.com/watch?v=cTUYvFCuV7k" target="_blank" rel="noreferrer">
          Acceleration
        </a>{' '}
        workout
      </p>
      <ul>
        <li>
          <b>5x 40m sprints</b>, with 3-5min rest in between
        </li>
        <li>Working on acceleration & power phase</li>
        <li>Main focus = working on drive phase. Drive for first 20-30m</li>
        <li>Main problem = pop straight up, can&apos;t push and drive</li>
      </ul>
    </>
  )
}
