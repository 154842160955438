import TUNA_SOUP_AND_TOMATO_RICE_IMAGE from '@srcroot/assets/images/family/recipes/tuna-soup-tomato-rice/RIce-Tomato-soup-2.jpg'
import SPANISH_FLAG_IMAGE from '@srcroot/assets/images/family/recipes/Flag_of_Spain-300x200.png'

export const ThermomixTunaSoupTomatoRice = () => {
  return (
    <>
      <h1>Thermomix Tuna soup and Tomato rice</h1>
      <img src={TUNA_SOUP_AND_TOMATO_RICE_IMAGE} alt="Tuna soup and tomato rice" />
      <br />
      <img width="120" height="80" src={SPANISH_FLAG_IMAGE} alt="Spanish flag" />

      <h1>Ingredientes</h1>
      <ol>
        <li>240g atun</li>
        <li>1 cebolla</li>
        <li>1 diente ajo</li>
        <li>80g rice</li>
        <li>1 calabacin</li>
        <li>400g tomate triturado</li>
        <li>100ml leche</li>
        <li>600ml agua</li>
      </ol>

      <hr />

      <h1>Preparación</h1>
      <ol>
        <li>
          Ponemos la cebolla y el ajo y picamos vel 5, 5 seg. Después{' '}
          <b>5 min, temp Varoma, vel 1.</b>
        </li>
        <li>
          Añadimos el tomate triturado. <b>10 min, temp Varoma, vel 1.</b>
        </li>
        <li>
          Añadimos el calabacín y trituramos <b>5 seg, vel 5</b>. Hasta que esté bien triturado
        </li>
        <li>
          Añadimos el agua caliente. <b>3 min, Temp 100, vel 1</b>. Después sal y pimienta
        </li>
        <li>
          Añadimos el arroz <b>20 min</b> (si es blanco, <b>50</b> si es brown){' '}
          <b>temp 100, vel 1</b>
        </li>
        <li>
          Ponemos la leche. Removemos <b>3 seg</b>, giro izq, <b>vel 2</b>. (PUEDE SALTARSE)
        </li>
        <li>Antes de servir ponemos el atún.</li>
      </ol>
    </>
  )
}
