import BUY_PROPERTY_PROCESS_IMAGE from '@srcroot/assets/images/jason/wiki/buyPropertyNswAus/buy-property-process.png'

import { Footer } from '@srcroot/components/footer'

import { hyperlink, redText, greenText } from '@srcroot/utils/commonCss'

import { scrollToSection } from '@srcroot/utils/scrollToSection'

export const WikiBuyPropertyNswAus = () => {
  return (
    <>
      <ol>
        <li className={hyperlink()}>
          <button
            data-testid="pre-requisites-link"
            onClick={() => scrollToSection('#pre-requisites')}
          >
            Pre requisites
          </button>
        </li>
        <li className={hyperlink()}>
          <button data-testid="steps-link" onClick={() => scrollToSection('#steps')}>
            Steps
          </button>
        </li>
        <li className={hyperlink()}>
          <button
            data-testid="beware-gazumping-link"
            onClick={() => scrollToSection('#beware-gazumping')}
          >
            Beware gazumping
          </button>
        </li>
      </ol>

      <h1 data-testid="wiki-buy-property-nsw-aus-header">Buying property in NSW, Australia</h1>
      <h2>5 Oct 2022</h2>

      <p>
        Buying a property in <strong>NSW</strong> via <strong>private treaty</strong> (not auction).
        Based on:
      </p>
      <ul>
        <li>
          <a
            href="https://www.fairtrading.nsw.gov.au/housing-and-property/buying-and-selling-property/buying-a-property"
            target="_blank"
            rel="noreferrer"
          >
            Fair trading
          </a>
        </li>
        <li>
          <a href="https://www.facs.nsw.gov.au/housing" target="_blank" rel="noreferrer">
            Housing NSW
          </a>
        </li>
      </ul>

      <h2>Questions</h2>
      <ol>
        <li>How long does finance take to formally approve?</li>
        <li>How long does finance take to settle?</li>
        <li>How long does it take from making offer to exchanging contracts?</li>
      </ol>

      <h2 id="pre-requisites">Pre requisites</h2>
      <ol>
        <li>Organise finance</li>
        <ol>
          <li>
            Initial mortgage search & <strong>decide</strong> on provider
          </li>
          <li>
            Get <strong>Pre Approval</strong>
          </li>
        </ol>
        <li>
          Find Conveyancer: check the sale contract <strong>before</strong> making an{' '}
          <strong>offer</strong>
        </li>
        <br />
        <li>
          Inspect properties: <strong>decide</strong> / hone in on areas. Get a good sense of{' '}
          <strong>value</strong>
        </li>
      </ol>

      <h2 id="steps">Steps</h2>

      <img src={BUY_PROPERTY_PROCESS_IMAGE} alt="Buy property process NSW aus" />

      <br />
      <ol>
        <li>Check sale contract</li>
        <ol>
          <li>
            <strong>Like it?</strong> Get copy of <strong>sale contract</strong> from Agent & send
            to <strong>Conveyancer</strong>
          </li>
          <li>
            Agent must inform you about <strong>previous</strong> property{' '}
            <strong>inspection</strong> reports
          </li>
          <li>
            Get finance <strong>pre-approved</strong> (if not already done so)
          </li>
        </ol>
        <li>
          <strong>Make offer</strong> & <strong>pay initial deposit</strong>
        </li>
        <ol>
          <li>
            Make <strong>offer</strong>
          </li>
          <li>
            Pay <strong>initial deposit (expression of interest) ~ 0.25%</strong>. This doesn&apos;t
            mean property is yours or gets taken off market. Only proves to seller that offer is{' '}
            <strong>serious</strong>
          </li>
          <li>
            Once deposit <strong>paid</strong>: agent must provide <strong>receipt</strong> and tell
            you in <strong>writing</strong>: they have no obligation to sell property to you. You
            have no obligation to buy the property. They will <strong>refund</strong> your{' '}
            <strong>deposit</strong> if you don&apos;t end up buying the property
          </li>
          <li>
            Beware{' '}
            <span className={redText()}>
              <strong>Gazumping</strong>
            </span>{' '}
            - see section below
          </li>
        </ol>
        <li>Formal loan application</li>
        <ol>
          <li>
            <span className={redText()}>
              <strong>Don&apos;t</strong>
            </span>{' '}
            exchange contracts before <strong>finance</strong> has been{' '}
            <span className={redText()}>
              <strong>formally approved</strong>
            </span>{' '}
            in <strong>writing</strong>{' '}
            <span className={redText()}>(pre approved not sufficient)</span>
          </li>
          <li>
            <strong>Lender</strong> will <strong>value property</strong>. Lender{' '}
            <strong>lends % of valuation</strong> of property,{' '}
            <span className={redText()}>
              <strong>not % of purchase price</strong>
            </span>
          </li>
        </ol>
        <li>
          <strong>Negotiate changes</strong> to contract
        </li>
        <ol>
          <li>
            Ask seller to <strong>change terms</strong> of sale contract before signing it. May also
            be asking to{' '}
            <span className={redText()}>
              <strong>fix defects</strong>
            </span>
          </li>
          <li>
            Do checks &{' '}
            <span className={redText()}>
              <strong>inspections</strong>
            </span>
            : eg <strong>strata</strong> search / inspection. <strong>Building</strong> inspection.{' '}
            <strong>Pest</strong> inspection. <strong>Valuation</strong> report. Other{' '}
            <strong>checks?</strong>
          </li>
          <li>
            If advised of <strong>other offers</strong> have been made, ask for this in{' '}
            <strong>writing</strong>. Agents have no <strong>obligation</strong>, but most agents do
            it
          </li>
          <li>
            Decide on ownership type: <strong>Joint Tenants vs Tenants in Common</strong>. See NSW
            family services guide.
          </li>
        </ol>
        <li>
          <strong>Exchange contracts</strong>
        </li>
        <ol>
          <li>
            <span className={redText()}>
              <strong>Before</strong>
            </span>{' '}
            exchanging contracts: agreement is{' '}
            <span className={redText()}>
              <strong>not binding</strong>
            </span>{' '}
            & both you or seller can change minds
          </li>
          <li>Two copies: you & seller both sign before they are exchanged</li>
          <li>
            <strong>after</strong> exchange:{' '}
            <span className={redText()}>
              <strong>seller is locked in</strong>
            </span>
          </li>
        </ol>
        <li>
          <strong>Pay deposit</strong> & cooling off period
        </li>
        <ol>
          <li>
            At time of exchange, pay ~ <strong>10% deposit</strong>
          </li>
          <li>
            <span className={redText()}>
              <strong>Cooling off</strong>
            </span>{' '}
            period: in <strong>NSW</strong> have <strong>5 business days</strong> cooling off
            period. Starts as soon as you exchange & ends 5pm on 5th business day after exchange
          </li>
          <li>
            During this period you can <strong>withdraw</strong> from contract with{' '}
            <strong>written</strong> notice: have to pay vendor{' '}
            <span className={redText()}>
              <strong>0.25% of purchase price</strong>
            </span>
          </li>
        </ol>
        <li>
          <strong>Settlement process</strong>
        </li>
        <ol>
          <li>
            <strong>~ 6 weeks</strong> after contracts exchanged
          </li>
          <li>
            Pay rest of sale price & become legal owner on settlement day (Conveyancer will meet
            vendor&apos;s Conveyancer & representatives from your bank & seller&apos;s bank)
          </li>
          <li>
            Ensure this is <strong>smooth</strong>: maintain <strong>regular contact</strong> with
            your{' '}
            <span className={redText()}>
              <strong>Conveyancer</strong>
            </span>{' '}
            throughout this period
          </li>
        </ol>
        <li>
          Organise <strong>insurance</strong>
        </li>
        <br />
        <li>Final check / inspection</li>
        <ol>
          <li>
            <strong>Day of settlement</strong>, important for{' '}
            <span className={redText()}>
              <strong>final</strong>
            </span>{' '}
            search of <strong>title</strong> to{' '}
            <span className={redText()}>
              ensure property is clear from any interests or restrictions
            </span>
          </li>
          <li>
            <strong>Morning of settlement</strong> day: conduct{' '}
            <span className={redText()}>
              <strong>final inspection</strong>
            </span>{' '}
            of property (ensure same condition as when contracts exchanged)
          </li>
        </ol>
        <li>
          <span className={greenText()}>
            <strong>Settlement day</strong>: <strong>Legal owner</strong> of property -
            Congratulations!!
          </span>
        </li>
        <br />
        <li>
          <strong>Stamp Duty</strong>: pay{' '}
          <span className={redText()}>
            <strong>within 3 months</strong>
          </span>{' '}
          of signing contract unless eligible for{' '}
          <span className={greenText()}>First Home Owner&apos;s grant</span>
        </li>
      </ol>

      <h2 id="beware-gazumping" className={redText()}>
        Beware Gazumping
      </h2>
      <p>
        Can take <strong>two</strong> forms.
      </p>
      <ol>
        <li>
          The intending buyer believes that the property has been secured by payment of an initial
          or part deposit, then proceeds to arrange finance, legal and other matters. When ready to{' '}
          <strong>exchange contracts</strong>, the intending buyer finds that{' '}
          <strong>another buyer</strong> has <strong>exchanged contracts</strong> on the property.
        </li>
        <li>
          The <strong>seller</strong> or the real estate agent accepts{' '}
          <strong>two or more initial deposits</strong> and then tells the intending buyers that the
          price has gone up. The intending purchasers are then{' '}
          <strong>left to outbid each other</strong> as if it were an <strong>auction</strong>. This
          is unfortunate for the buyer, but the seller is entitled to get the best price for the
          property.
        </li>
      </ol>

      <Footer />
    </>
  )
}
