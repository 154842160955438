import { grayText } from '@srcroot/utils/commonCss'

import AUTOBOT_LOGO_IMAGE from '@srcroot/assets/images/matthew/transformers-hotrod/autobot-logo.png'
import HOTROD_CAR_IMAGE from '@srcroot/assets/images/matthew/transformers-hotrod/hotrod-car.jpeg'
import HOTROD_ROBOT_IMAGE from '@srcroot/assets/images/matthew/hotrod3.png'
import HOTROD_TOY_IMAGE from '@srcroot/assets/images/matthew/transformers-hotrod/hotrod-toy.jpeg'
import HOTROD_STATS_IMAGE from '@srcroot/assets/images/matthew/transformers-hotrod/hot-rod-stats.png'

export const TransformersHotRod = () => {
  return (
    <>
      <h1 data-testid="transformers-hot-rod-header">Transformers: Hot Rod</h1>

      <h2 className={grayText()}>22 Jan 2023</h2>

      <h2>Motto: &quot;My actions speak louder than words.&quot;</h2>
      <p>
        Allegiance: Autobot
        <br />
        Function: Cavalier
      </p>

      <img width="150" height="150" src={AUTOBOT_LOGO_IMAGE} alt="autobot logo" />

      <h2>Transformation: Cybertronian Race Car</h2>
      <img src={HOTROD_CAR_IMAGE} alt="Hotrod car" />

      <h2>Weapons and Tools</h2>
      <p>
        Hot Rod has 2 <b>photon lasers</b> that temporarily electro-magnetize an enemy robot&apos;s
        microcircuits. Speed: <b>120 mph</b>. Range: <b>4 miles</b>, he also has a hand that can
        turn into a <b>saw</b>, three <b>guns</b> on his wrists and a fishing rod.
      </p>
      <img src={HOTROD_ROBOT_IMAGE} alt="Hotrod robot" />

      <h2>Description</h2>
      <p>
        Hot Rod is an all-american-boy autobot. He&apos;s a typical adolescent who dreams of being
        heroic and important. He tends to follow the rules too closely. Although he means well, Hot
        Rod&apos;s impulsive actions often get him into trouble. He can be hot-headed at times, but
        he&apos;s always a well meaning, admirable lad and a brave and honourable fighter.
      </p>
      <p>
        Hot Rod is my <b>favourite toy</b> for now...
      </p>
      <img src={HOTROD_TOY_IMAGE} alt="Hotrod toy" />

      <h2>Stats</h2>
      <img src={HOTROD_STATS_IMAGE} alt="Hotrod stats" />
    </>
  )
}
