import { Link } from 'react-router-dom'

import CIDR_2_ADDRESSES_IMAGE from '@srcroot/assets/images/jason/blog/2022/cidr/cidr-2-addresses.png'
import LAPTOPS_IMAGE from '@srcroot/assets/images/jason/blog/2018/best-value-laptop/laptops.png'
import DOCUMENTATION_QUADRANTS_IMAGE from '@srcroot/assets/images/jason/blog/2024/documentation-quadrants/documentation-quadrants.png'

import { Footer } from '@srcroot/components/footer'
import { grayText } from '@srcroot/utils/commonCss'

export const Blog = () => {
  return (
    <>
      <h1 data-testid="blog-2024">2024</h1>
      <div className="grid grid-cols-1 md:grid-cols-12 gap-5 my-4">
        <div className="md:col-span-6 flex justify-evenly">
          <img src={DOCUMENTATION_QUADRANTS_IMAGE} alt="organising documents" />
        </div>
        <div className="md:col-span-6 flex justify-evenly">
          <div>
            <h2 className={grayText()}>Documentation quadrants</h2>
            <p>How to organise your documentation?</p>
            <p>
              18 Mar 2024,{' '}
              <Link data-testid="blog-documentation-quadrants-link" to="documentationQuadrants">
                read more
              </Link>
            </p>
          </div>
        </div>
      </div>

      <h1 data-testid="blog-2022">2022</h1>
      <div className="grid grid-cols-1 md:grid-cols-12 gap-5 my-4">
        <div className="md:col-span-6 flex justify-evenly">
          <div>
            <h2 className={grayText()}>CIDR notation</h2>
            <p>What is it? Why do you need it?</p>
            <p>
              12 Oct 2022,{' '}
              <Link data-testid="blog-cidr-link" to="cidrAddress">
                read more
              </Link>
            </p>
          </div>
        </div>
        <div className="md:col-span-6 flex justify-evenly">
          <img src={CIDR_2_ADDRESSES_IMAGE} alt="CIDR addresses" />
        </div>
      </div>

      <h1 data-testid="blog-2018">2018</h1>
      <div className="grid grid-cols-1 md:grid-cols-12 gap-5 my-4">
        <div className="md:col-span-6 flex justify-evenly">
          <img src={LAPTOPS_IMAGE} alt="laptops" />
        </div>
        <div className="md:col-span-6 flex justify-evenly">
          <div>
            <h2 className={grayText()}>AUS best value laptop</h2>
            <p>In my opinion</p>
            <p>
              9 Feb 2018,{' '}
              <Link data-testid="blog-laptops-link" to="bestValueLaptop">
                read more
              </Link>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}
