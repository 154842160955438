export const WarmUp = () => {
  return (
    <>
      <h1 id="warm-up">How to warm up</h1>
      <p>
        <a href="https://www.youtube.com/watch?v=tR-LcAnbp14" target="_blank" rel="noreferrer">
          Warm up
        </a>{' '}
        before running a dash ~ 10-15 mins
      </p>
      <h2>Toe walks: 10-15 meters, up & back</h2>
      <ol>
        <li>toes in</li>
        <li>toes out</li>
        <li>heel walk</li>
        <li>outside of foot</li>
      </ol>

      <h2>Rolls & stretches: 10 clockwise, 10 anti clockwise</h2>
      <ol>
        <li>ankle roll</li>
        <li>knee roll</li>
        <li>hip roll</li>
        <li>torso roll</li>
        <li>neck side stretch, hold with hand</li>
        <li>neck back stretch</li>
        <li>neck front stretch, hold with 2 hands</li>
      </ol>

      <h2>Skipping: up & back</h2>
      <ol>
        <li>basic skip</li>
        <li>skip with both arms rolling forward</li>
        <li>skip with both arms rolling backwards</li>
        <li>skip with 1 arm forward then other arm forwards</li>
        <li>skip with 1 arm backward then other arm backwards</li>
        <li>skip with arms opposite: 1 arm forward, 1 arm backwards (repeat other side)</li>
        <li>skip with arms open & cross chest</li>
        <li>side skip jumping jacks</li>
        <li>skip sideways front leg then back leg</li>
      </ol>

      <h2>Leg swings: lean against fence (1 leg, then other)</h2>
      <ol>
        <li>swing leg sideways</li>
        <li>swing leg forwards & backwards</li>
      </ol>

      <h2>Skipping again</h2>
      <ol>
        <li>Nutcrackers</li>
        <li>A skips</li>
        <li>B skips</li>
        <li>High knees</li>
      </ol>

      <h2>Striders</h2>
      <p>Either run 50 meters, then walk back. Or walk the curve & stride the straights</p>
    </>
  )
}
