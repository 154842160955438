import { Link } from 'react-router-dom'

import ULTRA_MAGNUS_IMAGE from '@srcroot/assets/images/james/ultra-magnus/ultra-magnus-bot.png'

import { grayText } from '@srcroot/utils/commonCss'

export const James = () => {
  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-12 gap-5 my-4">
        <div className="md:col-span-6 flex justify-evenly">
          <div>
            <h2 className={grayText()}>Transformers: Ultra Magnus</h2>
            <p>Why is Ultra Magnus my favourite transformer?</p>
            <p>
              22 Sep 2022,{' '}
              <Link data-testid="transformers-ultra-magnus-link" to="transformersUltraMagnus">
                read more
              </Link>
            </p>
          </div>
        </div>
        <div className="md:col-span-6 flex justify-evenly">
          <img src={ULTRA_MAGNUS_IMAGE} alt="Ultra Magnus" />
        </div>
      </div>
    </>
  )
}
