import { Link } from 'react-router-dom'

import { Footer } from '@srcroot/components/footer'

export const Wiki = () => {
  return (
    <>
      <div data-testid="wiki-vertical-line" className="vl"></div>

      <div className="flex justify-evenly">
        <div>
          <Link to="tutorial">
            <h2>TUTORIALS</h2>
          </Link>
          <p>Learning oriented</p>
        </div>
        <div>
          <Link data-testid="how-to-link" to="howto">
            <h2>HOW TO GUIDES</h2>
          </Link>
          <p>Problem oriented</p>
        </div>
      </div>

      <hr className="hr-thick" />

      <div className="flex justify-evenly">
        <div>
          <h2>DISCUSSIONS</h2>
          <p>Understanding oriented</p>
        </div>
        <div>
          <Link data-testid="reference-link" to="reference">
            <h2>REFERENCE</h2>
          </Link>
          <p>Information oriented</p>
        </div>
      </div>
      <Footer />
    </>
  )
}
