import BUY_PROPERTY_NSW_AUS_IMAGE from '@srcroot/assets/images/jason/wiki/buyPropertyNswAus/buying-property.jpeg'

import { Footer } from '@srcroot/components/footer'

import { grayText } from '@srcroot/utils/commonCss'

import { Link } from 'react-router-dom'

export const HowToGuides = () => {
  return (
    <>
      <h1 data-testid="wiki-how-to-guides-header">How To Guides</h1>
      <h2>Problem oriented</h2>

      <div className="grid grid-cols-1 md:grid-cols-12 gap-5 my-4">
        <div className="md:col-span-6 flex justify-evenly">
          <div>
            <h2 className={grayText()}>Buy property in NSW, Australia</h2>
            <p>
              5 Oct 2022,{' '}
              <Link data-testid="buy-property-nsw-aus-link" to="buyPropertyNswAus">
                read more
              </Link>
            </p>
          </div>
        </div>
        <div className="md:col-span-6 flex justify-evenly">
          <img src={BUY_PROPERTY_NSW_AUS_IMAGE} alt="Buy Property NSW, Australia" />
        </div>
      </div>
      <Footer />
    </>
  )
}
