import CHOCOLATE_CAKE_IMAGE from '@srcroot/assets/images/family/recipes/chocolate-cake/chocolate-cake.png'
import UNITED_KINGDOM_FLAG_IMAGE from '@srcroot/assets/images/family/recipes/Flag_of_the_United_Kingdom-300x150.png'

export const MicrowaveChocolateCakeEggFree = () => {
  return (
    <>
      <h1>Microwave Chocolate Cake egg free</h1>
      <img src={CHOCOLATE_CAKE_IMAGE} alt="Chocolate cake" />
      <br />

      <img width="120" height="80" src={UNITED_KINGDOM_FLAG_IMAGE} alt="United Kingdom flag" />

      <h1>Ingredients</h1>
      <ol>
        <li>All purpose flour - 1/2 cup / 60 grams</li>
        <li>Sugar - 1/4 cup / 50 grams</li>
        <li>Cocoa Powder - 3 table spoon (tblspn)</li>
        <li>Yogurt - 2 tblspn</li>
        <li>Baking Soda - 1/2 tea spoon (tsp)</li>
        <li>Water - 1/3 cup / 80 ml</li>
        <li>Oil - 1/4 cup / 60 ml</li>
      </ol>

      <hr />

      <h1>Preparation</h1>
      <ol>
        <li>Take flour and cocoa powder in a bowl and mix well, put it aside.</li>
        <li>
          Take water in a microwave safe bowl and microwave it for <b>1 min.</b>
        </li>
        <li>
          Take it out and add sugar and mix well till it is dissolved then add oil and mix well.
        </li>
        <li>
          Take yogurt in a bowl, add baking soda in it and mix well. Add this mixture to the water,
          sugar, oil mix and mix all well.
        </li>
        <li>Add in the flour and cocoa mix and mix well so it&apos;s well combined.</li>
        <li>
          Now take a microwave safe bowl, add a tsp of oil and spread it all around the sides.
        </li>
        <li>
          Pour the mixture in this bowl and microwave it for <b>5 mins</b> (The original recipe is
          done in a <b>800 watt</b> microwave) (In our microwave{' '}
          <b>2 min 60% power with a 1200 watt</b> microwave, try el palillo and if dirty{' '}
          <b>30&apos;</b> more).
        </li>
        <li>Now remove it from the oven and leave it to cool completely.</li>
      </ol>
    </>
  )
}
