import { HashRouter } from 'react-router-dom'

import { BannerNavBarRoutes } from './BannerNavBarRoutes'

export const App = () => {
  return (
    <>
      {/* Split out HashRouter from Routes so we can use MemoryRouter in test */}
      <HashRouter>
        <BannerNavBarRoutes />
      </HashRouter>
    </>
  )
}
