import { Link } from 'react-router-dom'

import HOTROD_IMAGE from '@srcroot/assets/images/matthew/hotrod3.png'
import TRANSFORMERS_IMAGE from '@srcroot/assets/images/matthew/transformers-vs-soldiers/transformers-toys.png'
import SCHOOL_AT_NIGHT_IMAGE from '@srcroot/assets/images/matthew/haunted-school/school-at-night.png'

import { grayText } from '@srcroot/utils/commonCss'

export const Matthew = () => {
  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-12 gap-5 my-4">
        <div className="md:col-span-6 flex justify-evenly">
          <div>
            <h2 className={grayText()}>Transformers: Hot Rod</h2>
            <p>My favourite toy so far...</p>
            <p>
              22 Jan 2023,{' '}
              <Link data-testid="transformers-hot-rod-link" to="transformersHotRod">
                read more
              </Link>
            </p>
          </div>
        </div>
        <div className="md:col-span-6 flex justify-evenly">
          <img src={HOTROD_IMAGE} alt="Hotrod" />
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-12 gap-5 my-4">
        <div className="md:col-span-6 flex justify-evenly">
          <img src={TRANSFORMERS_IMAGE} alt="Transformers toys" />
        </div>
        <div className="md:col-span-6 flex justify-evenly">
          <div>
            <h2 className={grayText()}>Toys: Transformers vs Soldiers</h2>
            <p>Which is better?</p>
            <p>
              5 Jun 2022,{' '}
              <Link data-testid="transformers-vs-soldiers-link" to="transformersVsSoldiers">
                read more
              </Link>
            </p>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-12 gap-5 my-4">
        <div className="md:col-span-6 flex justify-evenly">
          <div>
            <h2 className={grayText()}>My first story</h2>
            <p>Enter the Haunted School</p>
            <p>
              31 Jul 2022,{' '}
              <Link data-testid="enter-haunted-school-link" to="enterHauntedSchool">
                read more
              </Link>
            </p>
          </div>
        </div>
        <div className="md:col-span-6 flex justify-evenly">
          <img src={SCHOOL_AT_NIGHT_IMAGE} alt="School at night" />
        </div>
      </div>
    </>
  )
}
