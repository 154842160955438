import LASER_METER_IMAGE from '@srcroot/assets/images/family/athletics/laser-meter.png'
import FIFTY_METER_LINE_IMAGE from '@srcroot/assets/images/family/athletics/50m-line.png'

import { greenText } from '@srcroot/utils/commonCss'

export const MoreTechnicalAndMeasureDistance = () => {
  return (
    <>
      <h1 id="more-technical">More Technical</h1>
      <p>
        Proper{' '}
        <a href="https://www.youtube.com/watch?v=6m_fjNhRhkY" target="_blank" rel="noreferrer">
          sprinting form in 3 steps
        </a>
      </p>

      <p>
        <a href="https://www.youtube.com/watch?v=-O1tEQJb844" target="_blank" rel="noreferrer">
          100m masterclass
        </a>{' '}
        with Harry Aikines
      </p>

      <hr />

      <h1 id="measure-100m">How to measure 100m?</h1>
      <div className="grid grid-cols-1 md:grid-cols-12 gap-5 my-4">
        <div className="md:col-span-6 flex justify-evenly">
          <img src={LASER_METER_IMAGE} alt="Laser meter" />
        </div>
        <div className="md:col-span-6 flex justify-evenly">
          <img src={FIFTY_METER_LINE_IMAGE} alt="50m line" />
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-12 gap-5 my-4">
        <div className="md:col-span-6 flex justify-evenly">
          <p className={greenText()}>Laser meter</p>
        </div>
        <div className="md:col-span-6 flex justify-evenly">
          <p className={greenText()}>50 meter line</p>
        </div>
      </div>

      <p>
        Aside from running at a track, or rugby field (where the distance is marked), how do you
        measure <b>100m</b> at the park?
      </p>
      <p>
        You could go <b>High Tech</b> (laser meter ~ $50) or <b>Low Tech</b> (50m line ~ $4.50). I
        think the{' '}
        <a
          href="https://www.bunnings.com.au/spear-jackson-50m-no8-lime-green-brickline_p5830002"
          target="_blank"
          rel="noreferrer"
        >
          50m line
        </a>{' '}
        is simpler & better value for money
      </p>
    </>
  )
}
