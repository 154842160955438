import { grayText, greenText } from '@srcroot/utils/commonCss'

import WHITE_WASH_IMAGE from '@srcroot/assets/images/family/body-surfing/white-wash.png'
import GREEN_WAVE_IMAGE from '@srcroot/assets/images/family/body-surfing/green-wave.jpeg'
import ADVANCED_BODY_SURFING_IMAGE from '@srcroot/assets/images/family/body-surfing/thomas-VanMelum-on-big-right-at-Boomer.jpg'
import SURF_POSITION_IMAGE from '@srcroot/assets/images/family/body-surfing/wave-positioning.jpeg'

export const BodySurfing = () => {
  return (
    <>
      <h1 data-testid="body-surfing-header">How to Body Surf?</h1>

      <h2 className={grayText()}>29 Jun 2024</h2>

      <h1>Beginner</h1>

      <p>
        Beginner body surfers usually start by learning how to catch <strong>white wash</strong>{' '}
        (white water after wave has broken)
      </p>
      <img width="1000" src={WHITE_WASH_IMAGE} alt="white wash" />

      <p>
        <a
          data-testid="lloyd-cole-how-to-body-surf"
          href="https://www.youtube.com/watch?v=zYt2MT3TUXg"
          target="_blank"
          rel="noreferrer"
        >
          How to Body Surf
        </a>{' '}
        by Lloyd Cole (2m), 3 time world champion.{' '}
        <strong>
          <span className={greenText()}>Inspirational</span>
        </strong>{' '}
        video showing him with head down catching a small white wash wave.
      </p>

      <p>
        <a href="https://www.youtube.com/watch?v=zaqG4A3gEFY" target="_blank" rel="noreferrer">
          Beginners guide to body surfing
        </a>{' '}
        by Bodhi Surf & Yoga (6m 21s) in Costa Rica. Good explanation of how to body surf.
      </p>

      <p>
        <a
          data-testid="jobacus-how-to-body-surf"
          href="https://www.youtube.com/watch?v=8nRcdg4mljs"
          target="_blank"
          rel="noreferrer"
        >
          How to body surf
        </a>{' '}
        by Jobacus (3m 41s) in Western France. 2 guys that mention the 2 basic positions: arms back
        or arms forward
      </p>

      <h1>Intermediate</h1>
      <p>Intermediate body surfers are now looking to catch green waves.</p>

      <img src={GREEN_WAVE_IMAGE} alt="body surfing green wave" />
      <p>
        <a href="https://www.youtube.com/watch?v=puPoeqj5tZ8" target="_blank" rel="noreferrer">
          Bodysurfing green waves
        </a>{' '}
        by Bodhi Surf & Yoga (4m 53s) in Costa Rica. Intermediate version of above beginner video
      </p>
      <p>
        <a href="https://www.youtube.com/watch?v=5Cq2ePY_aEg" target="_blank" rel="noreferrer">
          How to body surf waves for Triathlon
        </a>{' '}
        by Global Triathlon Network (4m 34s). Advocates using <strong>1 arm super man</strong>{' '}
        position used by most professional body surfers. Good video to start showing best position
        to catch green waves.
      </p>

      <h1>Advanced</h1>
      <img width="1000" src={ADVANCED_BODY_SURFING_IMAGE} alt="advanced body surfing" />
      <p>
        <a
          data-testid="surf-sufficient-how-to-body-surf"
          href="https://www.youtube.com/watch?v=EblpNV_13Jc"
          target="_blank"
          rel="noreferrer"
        >
          How to body surf
        </a>{' '}
        by Surf Sufficient (4m 6s)
      </p>

      <p>
        <a
          href="https://www.youtube.com/watch?v=yATVdbJDEtU&t=187s"
          target="_blank"
          rel="noreferrer"
        >
          5 steps to perfect positioning in the surf
        </a>{' '}
        by Kale Brock (10m 22s). Even though its a surfing video, the wave positioning knowledge is
        the same for surfing, boogie boarding or body surfing.
      </p>

      <img width="1000" src={SURF_POSITION_IMAGE} alt="surf positioning" />
    </>
  )
}
