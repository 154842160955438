import TWO_HUNDRED_METERS_DASH_IMAGE from '@srcroot/assets/images/family/athletics/200m-dash.png'

export const Run200mDash = () => {
  return (
    <>
      <h1 id="two-hundred-meter-dash">Running 200m dash</h1>
      <img src={TWO_HUNDRED_METERS_DASH_IMAGE} alt="200m dash" />

      <p>
        How to{' '}
        <a href="https://www.youtube.com/watch?v=D3w6j9HFbZY" target="_blank" rel="noreferrer">
          run a 200m
        </a>
      </p>
      <ol>
        <li>Run the curve well</li>
        <ol>
          <li>
            Hug the <b>inside line</b>. The more you&apos;re on the outside line, the more steps or
            distance you have to cover
          </li>
          <li>Don&apos;t step on the line, you&apos;ll be disqualified</li>
          <li>Smooth in the curve, not out of control with the curve</li>
        </ol>
        <li>Practise out of the curve</li>
        <ol>
          <li>Slingshot out of the curve (use centrifugal force)</li>
          <li>Avoid mis-steps, every step counts</li>
        </ol>
        <li>Relax and hold form at the end</li>
      </ol>

      <p>
        How to run{' '}
        <a href="https://www.youtube.com/watch?v=ASqtvCAV8GU" target="_blank" rel="noreferrer">
          200m tutorial
        </a>
      </p>
      <ol>
        <li>Rule 1 = cannot run it all out from the gun (need phases)</li>
        <li>Rule 2 = Lane 1 or 8 is still 200m</li>
        <li>Rule 3 = don&apos;t touch the line between lanes</li>
        <li>
          Block start <b>placement is angled</b>, so you have straight line out of the block
        </li>
      </ol>

      <p>
        <a href="https://www.youtube.com/watch?v=Kji_frLGFuA" target="_blank" rel="noreferrer">
          Aaron Brown
        </a>{' '}
        walks you through a 200m dash
      </p>

      <p>
        <a href="https://www.youtube.com/watch?v=oLMMTGTND2Y" target="_blank" rel="noreferrer">
          3 tips
        </a>{' '}
        for running 200m dash
      </p>
      <ol>
        <li>Learn how to run the curve</li>
        <ol>
          <li>Hug inside of lane</li>
          <li>Lean properly</li>
          <li>Use centrifugal force coming off the curve</li>
        </ol>
        <li>Work on cardio as you need good endurance</li>
        <ol>
          <li>
            Run longer distances (300 / 400 / 500m) to buid endurance to <b>finish strongly</b>
          </li>
        </ol>
        <li>Learn how to relax at end of race</li>
        <ol>
          <li>
            When feeling lactic acid at the end: <b>relax & hold your form</b>
          </li>
        </ol>
      </ol>

      <p>
        How{' '}
        <a href="https://www.youtube.com/watch?v=7qDqMukLFRU" target="_blank" rel="noreferrer">
          Noah Lyles
        </a>{' '}
        sprints 200m under 20s
      </p>

      <h2 id="two-hundred-meter-phases">Phases of 200m dash</h2>
      <ol>
        <li>Go Hard (first 50m)</li>
        <li>Float Phase (50m - end of curve)</li>
        <li>Re-accelerate (out of curve for 20m)</li>
        <li>Last 60m</li>
      </ol>

      <h2>Race plan</h2>
      <ol>
        <li>Whole 200m, 100% (as fast as you can)</li>
        <li>Try & relax: to keep your form near the end</li>
      </ol>

      <h2>Detailed Phases - Race strategy</h2>
      <ol>
        <li>
          <b>Go Hard (first 50m)</b>
        </li>
        <ol>
          <li>
            50m all out, try to make up stagger on outside lane (catch up with guy on outside lane)
          </li>
        </ol>
        <li>
          <b>Float Phase (50m - end of curve)</b>
        </li>
        <ol>
          <li>
            Settle in & relax, only thing that changes is effort level.{' '}
            <b>Speed same, but effort less</b>
          </li>
          <li>Most people get this wrong by slowing down too much when trying to float</li>
          <li>Slingshot off the turn</li>
        </ol>
        <li>
          <b>Re-accelerate (out of curve for 20m)</b>
        </li>
        <ol>
          <li>Slingshot off the turn</li>
          <li>Re-accelerate for ~ 20m out of the curve</li>
        </ol>
        <li>
          <b>Last 60m</b>
        </li>
        <ol>
          <li>Hold that speed</li>
          <li>Drive your arms, as legs will follow your arms</li>
          <li>Relax and hold your form</li>
          <ol>
            <li>Big arms, relaxed shoulders, relaxed face</li>
            <li>Big strides & finish off the race</li>
          </ol>
        </ol>
      </ol>

      <h2>2 Types of Runners</h2>
      <ol>
        <li>
          <b>100m person</b> bumping up to 200m
        </li>
        <ul>
          <li>100m is your best event</li>
          <li>Advantage is that you&apos;re the fastest guy, or one of the fastest guys</li>
          <li>
            You really need the float phase, as you don&apos;t have the endurance to run hard the
            entire race
          </li>
        </ul>
        <li>
          <b>400m person</b> bumping down to 200m
        </li>
        <ul>
          <li>Run almost all out the whole time</li>
          <li>Run 95-98% whole race, but still have endurance to finish strong</li>
          <li>Try to keep up with the 100m guy at start and look to beat them in final 50m</li>
        </ul>
      </ol>

      <h2 id="two-hundred-meter-drills">Training drills</h2>
      <p>
        How to run a{' '}
        <a href="https://www.youtube.com/watch?v=J_8cmScRf8I" target="_blank" rel="noreferrer">
          faster 200m dash
        </a>
      </p>
      <ul>
        <li>
          <b>6x 80m sprints</b> (95-98% effort), 5-7min rest in between
        </li>
        <li>
          <b>Go hard</b> for 1st 50m, <b>float</b> for last 30m
        </li>
        <li>Pick different lanes for practise. Practise your worst lane</li>
        <li>Inside lanes are hardest, as curve is tightest</li>
        <li>
          In between reps, don&apos;t stop moving. Don&apos;t lie down & rest. Just walk around till
          next rep
        </li>
      </ul>
    </>
  )
}
