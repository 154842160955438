import PAPUA_NEW_GUINEA_FLAG_IMAGE from '@srcroot/assets/images/matthew/geography/papua-new-guinea/flag-png.png'
import PAPUA_NEW_GUINEA_MAP_IMAGE from '@srcroot/assets/images/matthew/geography/papua-new-guinea/png-map.png'
import KING_CHARLES_IMAGE from '@srcroot/assets/images/matthew/geography/papua-new-guinea/Charles_Prince_of_Wales-2017.jpg'
import PRIME_MINISTER_IMAGE from '@srcroot/assets/images/matthew/geography/papua-new-guinea/james-marape-png-pm.jpg'
import PAPUA_NEW_GUINEA_EXPORTS_IMAGE from '@srcroot/assets/images/matthew/geography/papua-new-guinea/png-exports-2019.png'

import { grayText } from '@srcroot/utils/commonCss'

export const PapuaNewGuinea = () => {
  return (
    <>
      <h1 data-testid="papua-new-guinea-header">Papua New Guinea</h1>
      <h2 className={grayText()}>31 Oct 2022</h2>
      <img width="500" height="350" src={PAPUA_NEW_GUINEA_FLAG_IMAGE} alt="Papua New Guinea flag" />

      <p>
        Papua New Guinea (<b>PNG</b>){' '}
        <a href="https://en.wikipedia.org/wiki/Papua_New_Guinea" target="_blank" rel="noreferrer">
          wikipedia
        </a>
      </p>
      <p>
        PNG{' '}
        <a
          href="https://www.britannica.com/place/Papua-New-Guinea"
          target="_blank"
          rel="noreferrer"
        >
          Britannica
        </a>
      </p>

      <h2>Location & Area</h2>
      <img src={PAPUA_NEW_GUINEA_MAP_IMAGE} alt="Papua New Guinea map" />

      <p>PNG is 150km north of Queensland (QLD) tip of Australia</p>
      <p>Area of country = 462,840 km2</p>
      <p>
        Capital = <b>Port Moresby</b>
      </p>

      <h2>Population & Language</h2>
      <p>
        Population = <b>8,935,000</b> (2020 estimate)
      </p>
      <p>Official Languages = English, Hiri Motu</p>

      <h2>Government</h2>
      <div className="grid grid-cols-1 md:grid-cols-12 gap-5 my-4">
        <div className="md:col-span-6 flex justify-evenly">
          <img src={KING_CHARLES_IMAGE} alt="King Charles" />
        </div>
        <div className="md:col-span-6 flex justify-evenly">
          <img src={PRIME_MINISTER_IMAGE} alt="Prime Minister James Marape" />
        </div>
      </div>

      <h2>Constitutional Monarchy</h2>
      <p>Monarch = King Charles 3 of England</p>
      <p>Prime Minister = James Marape</p>

      <h2>Industries</h2>
      <h2 className={grayText()}>Top exports (2019)</h2>
      <img src={PAPUA_NEW_GUINEA_EXPORTS_IMAGE} alt="Papua New Guinea exports" />
      <ol>
        <li>Petroleum gas (36%)</li>
        <li>Gold (23%)</li>
        <li>Copper Ore (7%)</li>
      </ol>
    </>
  )
}
