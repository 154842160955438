import KUBERNETES_IMAGE from '@srcroot/assets/images/jason/wiki/kubernetes/kubernetes.png'

import { Footer } from '@srcroot/components/footer'

import { grayText } from '@srcroot/utils/commonCss'

import { Link } from 'react-router-dom'

export const Reference = () => {
  return (
    <>
      <h1 data-testid="wiki-reference-header">Reference</h1>
      <h2>Information oriented</h2>

      <div className="grid grid-cols-1 md:grid-cols-12 gap-5 my-4">
        <div className="md:col-span-6 flex justify-evenly">
          <div>
            <h2 className={grayText()}>Kubernetes cheat sheet</h2>
            <p>
              23 Oct 2022,{' '}
              <Link data-testid="kubernetes-cheat-sheet-link" to="kubernetesCheatSheet">
                read more
              </Link>
            </p>
          </div>
        </div>
        <div className="md:col-span-6 flex justify-evenly">
          <img src={KUBERNETES_IMAGE} alt="Kubernetes" />
        </div>
      </div>
      <Footer />
    </>
  )
}
