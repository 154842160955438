export const Principles = () => {
  return (
    <>
      <h2>Guiding Principles</h2>

      <ul>
        <li>Follow your passion and have fun</li>
        <li>Empathise with people</li>
        <li>Set goals and practise daily</li>
        <li>Keep on learning</li>
      </ul>
    </>
  )
}
