import CHICKEN_BREAST_IMAGE from '@srcroot/assets/images/family/recipes/chicken-breast/Italian-Chicken-Recipe-1-819x1024.jpg'
import UNITED_KINGDOM_FLAG_IMAGE from '@srcroot/assets/images/family/recipes/Flag_of_the_United_Kingdom-300x150.png'

export const PanFriedChickenBreast = () => {
  return (
    <>
      <h1>Pan fried juicy Chicken breasts</h1>
      <img src={CHICKEN_BREAST_IMAGE} alt="Chicken breast" />
      <br />

      <img width="120" height="80" src={UNITED_KINGDOM_FLAG_IMAGE} alt="United Kingdom flag" />

      <h1>Preparation</h1>
      <ol>
        <li>
          Flatten the chicken breasts. Season the chicken breasts. Lightly season the chicken with
          salt and pepper.
        </li>
        <li>
          Cook the chicken breasts over <b>medium heat for 1 minute</b> without moving. Reduce the
          heat to medium. Add the chicken breasts.
        </li>
        <li>
          Flip the chicken breasts. Flip each chicken breast over. Cover the pan and cook on{' '}
          <b>low for 10 minutes.</b>
        </li>
        <li>
          Turn off the heat and let sit for an additional <b>10 minutes.</b>
        </li>
      </ol>

      <hr />

      <h1>Notes</h1>
      <p>
        <b>Dredge in seasoned flour:</b> You can also dredge the chicken breasts in flour before
        cooking. Season the flour with spices or fresh herbs and make sure the chicken is golden on
        one side before you flip it over. This will give your chicken a very subtle crust.
      </p>
      <p>
        <b>Quick brine:</b> You can make your boneless skinless chicken breasts even juicier and
        more flavorful with a super-quick brine. Even just <b>15 minutes</b> in a simple brine will
        make them juicier. Watch the video above to see how this is done.
      </p>
      <p>
        <b>Storage:</b> Leftovers can be refrigerated in an airtight container for up to{' '}
        <b>4 days or frozen for up to 2 months.</b>
      </p>

      <hr />

      <h1>With the new Ikea thing:</h1>
      <ol>
        <li>10 min (about 5 min temp 6 and 5 min temp 5)</li>
        <li>10 min reposo</li>
        <li>another 10 min temp 5</li>
        <li>10 min reposo</li>
      </ol>
    </>
  )
}
