import SPINACH_SOUP_IMAGE from '@srcroot/assets/images/family/recipes/spinach-soup/Broccoli-Spinach-Soup.jpg'
import SPANISH_FLAG_IMAGE from '@srcroot/assets/images/family/recipes/Flag_of_Spain-300x200.png'

export const ThermomixSpinachSoup = () => {
  return (
    <>
      <h1>Thermomix Spinach soup</h1>
      <img src={SPINACH_SOUP_IMAGE} alt="Spinach soup" />
      <br />

      <img width="120" height="80" src={SPANISH_FLAG_IMAGE} alt="Spanish flag" />

      <h1>Ingredientes</h1>
      <ol>
        <li>500 g espinacas congeladas , (previamente descongeladas)</li>
        <li>1 - 2 diente de ajo</li>
        <li>50 g aceite de oliva virgen extra</li>
        <li>1 cucharadita sal</li>
        <li>80 g harina</li>
        <li>800 g leche</li>
        <li>30 g mantequilla</li>
        <li>1 pellizco nuez moscada</li>
        <li>1 pellizco pimienta</li>
      </ol>

      <hr />

      <h1>Preparación</h1>
      <ol>
        <li>
          Triturar el ajo y ponerlo en el vaso. Añada el aceite y programe{' '}
          <b>3.5 minutos, temperatura Varoma, velocidad 2</b>.
        </li>
        <li>
          Incorpore las espinacas congeladas y la sal. Programe <b>7 minutos, temperatura Varoma</b>
          , giro a la izquierda y velocidad cuchara.
        </li>
        <li>
          Añada la harina y rehogue durante <b>5 minuto, 100º, velocidad 2</b>.
        </li>
        <li>
          Ponga la leche, la mantequilla, la nuez moscada y la pimienta. Programe{' '}
          <b>14 minutos, 100º, velocidad 2</b>.
        </li>
      </ol>
    </>
  )
}
