import MY_PROFILE_IMAGE from '@srcroot/assets/images/home/jasonsoo.jpeg'

import { Technology } from './technology'
import { Resume } from './resume'
import { Principles } from './principles'

import { Footer } from '@srcroot/components/footer'

export const Home = () => {
  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-12 gap-5 my-4">
        <div className="md:col-span-4 flex justify-evenly">
          <img
            data-testid="home-my-profile-image"
            className="rounded-full w-52 h-52"
            src={MY_PROFILE_IMAGE}
            alt="my profile"
          />
        </div>
        <div className="md:col-span-8 flex justify-evenly">
          <div>
            <Technology />
            <Resume />
            <Principles />
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}
