export const redText = (): string => {
  return 'text-red-600'
}

export const greenText = (): string => {
  return 'text-green-600'
}

export const grayText = (): string => {
  return 'text-gray-500'
}

export const hyperlink = (): string => {
  return 'underline text-blue-600 hover:text-blue-800 visited:text-purple-600'
}
