import LAPTOPS_IMAGE from '@srcroot/assets/images/jason/blog/2018/best-value-laptop/laptops.png'

import { Footer } from '@srcroot/components/footer'

import { grayText, greenText, hyperlink } from '@srcroot/utils/commonCss'

import { scrollToSection } from '@srcroot/utils/scrollToSection'

export const BlogBestValueLaptop = () => {
  return (
    <>
      <ol>
        <li className={hyperlink()}>
          <button
            data-testid="consider-buy-laptop-us-link"
            onClick={() => scrollToSection('#buy-from-us')}
          >
            Have you ever considered buying laptop from US?
          </button>
        </li>
        <li className={hyperlink()}>
          <button
            data-testid="my-recommendation-link"
            onClick={() => scrollToSection('#recommendation')}
          >
            What&apos;s my recommendation?
          </button>
        </li>
      </ol>

      <h1 data-testid="blog-laptops-header">Best value laptop in Australia (in my opinion)</h1>
      <h2 className={grayText()}>9 Feb 2018</h2>

      <img src={LAPTOPS_IMAGE} alt="laptops" />

      <h1>Summary</h1>
      <p>
        I recently helped a mate buy a laptop (Jan 18) and I think that the{' '}
        <b>
          <span className={greenText()}>Asus Zenbook UX330</span>
        </b>{' '}
        provides great value. I&apos;m writing this to{' '}
        <b>
          <span className={greenText()}>help</span>
        </b>{' '}
        laptop buyers use{' '}
        <b>
          <span className={greenText()}>value comparison</span>
        </b>{' '}
        in their purchases.
      </p>

      <hr />

      <h1>Usage</h1>
      <p>
        My mate&apos;s requirements was Internet browsing with good performance running multiple
        office apps (eg Excel) at the same time.
      </p>
      <p>
        He already shortlisted the <b>Dell XPS 13</b> and <b>Lenovo X1</b>, but wasn&apos;t sure
        which one to buy?
      </p>
      <p>
        For his usage, I think the 2 laptops that he picked are really good and would easily do the
        job. I think the Intel <b>i5 CPU</b> is sufficient, probably doesn&apos;t need an i7. I
        currently run an i5 (~ 2 years old) which handles a full programming environment well, so
        should be able to handle office apps.
      </p>
      <p>
        Getting <b>SSD hard drive</b> is key to good performance and snappy start up times. I
        recommend <b>256 GB</b> as minimun and don&apos;t think 128 GB SSD cuts it (in my opinion).
      </p>

      <hr />

      <h1>How to do a value comparison?</h1>
      <ol>
        <li>Get price / specs of the market leader</li>
        <li>Get the price / specs of shortlisted contenders</li>
        <li>Look for price / specs of suggested good value propositions</li>
        <li>
          Decide based on <b>price / specs / build quality / reviews</b> =&gt;{' '}
          <span className={greenText()}>hopefully, that&apos;s the value proposition</span>
        </li>
      </ol>

      <p>
        I first started with the{' '}
        <b>
          <a href="https://www.apple.com/au/macbook-air/specs/" target="_blank" rel="noreferrer">
            MacBook Air
          </a>
        </b>
        , a perceived leader in the field. It retails ~ <b>$1,799</b> with i5, 8GB RAM, 256 GB SSD.
      </p>

      <hr />

      <h1>How does the Lenovo & Dell compare with the same specs?</h1>
      <p>
        <a
          href="https://www3.lenovo.com/au/en/laptops/thinkpad/thinkpad-x/ThinkPad-X1-Carbon-5th-Gen/p/22TP2TXX15G"
          target="_blank"
          rel="noreferrer"
        >
          Lenovo X1
        </a>{' '}
        retails ~ <b>$1,709</b> with i5-7200U (7th Gen, Q3 16, Benchmark 4,667), 8 GB RAM, 256 GB
        SSD, 14” FHD 1920×1080
      </p>
      <p>
        <a
          data-testid="dell-xps-13-link"
          href="http://www.dell.com/au/p/xps-13-9360-laptop/pd?ref=PD_OC"
          target="_blank"
          rel="noreferrer"
        >
          Dell XPS 13
        </a>{' '}
        retails ~ <b>$1,599</b> with i5-8250U (8th Gen, Q3 17, Benchmark 7,517), 8 GB RAM, 256 GB
        SSD, 13.3” FHD 1920×1080
      </p>
      <p>
        Both the Lenovo & Dell are cheaper than Apple for the same specs (to provide some sort of
        value). But I think the <b>Dell</b> is <b>better value</b> than Lenovo:
      </p>
      <ol>
        <li>~ $100 cheaper</li>
        <li>
          you get an <b>8th Gen i5</b>, instead of 7th Gen i5 in Lenovo. The Benchmark of the 8th
          Gen i5 is <b>7,517</b> vs <b>4,667</b> (7th Gen i5) and the higher is better. Amazing how
          much the performance gains are 1 generation to the next.
        </li>
      </ol>

      <h2>What&apos;s the review comparisons between XPS 13 vs X1?</h2>
      <p>
        <a
          href="http://www.notebookreview.com/feature/lenovo-thinkpad-x1-carbon-vs-dell-xps-13/"
          target="_blank"
          rel="noreferrer"
        >
          Lenovo ThinkPad X1 Carbon vs Dell XPS 13
        </a>
      </p>
      <p>
        <span className={greenText()}>
          Note, in the reviews, the X1 had more powerful CPU, but for quotes above, XPS 13 has the
          more powerful CPU, so you can disregard the performance comparison in the review.
        </span>
      </p>

      <hr />

      <h1 id="buy-from-us" data-testid="buy-from-us-anchor">
        Have you ever considered buying a laptop from US?
      </h1>
      <p>
        In general, I&apos;ve noticed that US have cheaper laptops with similar / better specs. The
        US power charger has a different adapter to Australia but that&apos;s a minor issue.
      </p>

      <h2>Thinking about risk?</h2>
      <p>
        I think whats more important is to get <b>international warranty</b>, so if there are any
        issues, you can take to local Asus dealer.
      </p>

      <h2>How was my experience?</h2>
      <p>
        I actually bought an <b>Asus Zenbook UX305</b> from <b>US</b> back in <b>Jan 2016</b>.
        I&apos;ve used it for 2 years and have had no problems so really enjoyed the price saving.
      </p>

      <hr />

      <h1>How does Asus Zenbook bought from US compare?</h1>
      <p>
        Amazon US sells the{' '}
        <a
          href="https://www.amazon.com/dp/B075N1YHVK/ref=psdc_13896615011_t1_B06XSZC11D"
          target="_blank"
          rel="noreferrer"
        >
          Asus Zenbook UX330
        </a>{' '}
        which ships to Aus.
      </p>
      <p>
        <b>Asus Zenbook UX330</b> retails ~ USD$749 = <b>AUD$952</b> (1USD = 1.27AUD), with i5-8250U
        (8th Gen, Q3 17, Benchmark 7,517), 8 GB RAM, 256 GB SSD, 13.3” FHD 1920x1080
      </p>
      <p>
        Its a lot cheaper (much better value) with similar performance, though build quality is not
        as good as X1 or XPS 13. But because of much cheaper price / performance / value I think its
        worth considering. Its build quality is pretty good too, I&apos;ve used an Asus Zenbook for
        2 years without issues.
      </p>

      <h2>What&apos;s the review comparison of the Asus?</h2>
      <p>
        <a
          href="https://thewirecutter.com/reviews/the-best-windows-ultrabook/"
          target="_blank"
          rel="noreferrer"
        >
          Review of Asus
        </a>{' '}
        (best windows Ultrabook)
      </p>
      <p>
        Following a similar <b>value comparison</b> approach, I bought my laptop back in 2016.
      </p>
      <p>
        My specs: i5-5200U (5th Gen, Q1 15, Benchmark 3,495), 8 GB RAM, 256 GB SSD, 13.3” FHD
        1920x1080
      </p>
      <p>
        It was much cheaper than Dell XPS and Zenbook Aus. I got mine in the US converted to ~
        AUD$1,184 when Aus retailers were selling ~ $1,600 (but only 128 GB SSD) and XPS 13 ~ $1,800
        at the time.
      </p>
      <p>
        <span className={greenText()}>
          Note how Asus UX330 (successor of UX305) is twice as fast with 8th Gen i5 (7,517 vs 3,495)
          for same price.
        </span>
      </p>

      <h2>What about Aus import duty?</h2>
      <p>
        I think you have to pay Aus import duty if you spend &gt; AUD$1.000. In 2016 &lt; AUD$1,000
        you didn&apos;t have to pay import duty. Need to check if this has changed.
      </p>

      <hr />

      <h1>What about impact from recent CPU bugs (Meltdown / Spectre)?</h1>
      <p>
        With{' '}
        <a href="https://meltdownattack.com/" target="_blank" rel="noreferrer">
          Meltdown / Spectre
        </a>{' '}
        bugs, the <b>8th Gen intel</b> chips <b>are</b> impacted by the bug. So maybe its worth
        waiting a year or so for next gen chips to be built which is not impacted. But if you have
        to buy now, then that&apos;s ok, just apply the operating system <b>patches</b> to keep you{' '}
        <b>safe</b>.
      </p>

      <hr />

      <h1 id="recommendation" data-testid="recommendation-anchor">
        What&apos;s my recommendation?
      </h1>
      <p>
        My recommendation is to get the <b>Asus</b> from <b>US</b>. Its really good value from price
        / performance point of view. Not as polished as XPS 13, but for price point is{' '}
        <b>
          <span className={greenText()}>excellent value</span>
        </b>{' '}
        (in my opinion).
      </p>
      <p>
        I&apos;ve had no issues with mine so far and do not regret it. Its still going strong and my
        next upgrade will be another Asus (UX305 successor). But I&apos;ll probably wait for next
        gen of intel chips not impacted by Meltdown / Spectre.
      </p>
      <p>
        I&apos;m still running a full development environment happily with 5th Gen i5 (2 yrs later).
        I run <b>Ubuntu linux</b> though, so startup times are very snappy and it runs really fast.
        Same specs will probably run Windows a little slower, as Windows is more heavyweight.
      </p>
      <Footer />
    </>
  )
}
