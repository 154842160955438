import { Link } from 'react-router-dom'

import SOCCER_IMAGE from '@srcroot/assets/images/family/soccer/soccer.png'
import ATHLETICS_IMAGE from '@srcroot/assets/images/family/athletics/2019-world-100m-final-men.png'
import RUGBY_IMAGE from '@srcroot/assets/images/family/rugby/rugby-tackle.png'
import BASKETBALL_IMAGE from '@srcroot/assets/images/family/basketball/basketball.png'
import BODY_SURFING_IMAGE from '@srcroot/assets/images/family/body-surfing/thomas-VanMelum-on-big-right-at-Boomer.jpg'

import { grayText } from '@srcroot/utils/commonCss'

export const Family = () => {
  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-12 gap-5 my-4">
        <div className="md:col-span-6 flex justify-evenly">
          <img src={BASKETBALL_IMAGE} alt="basketball" />
        </div>
        <div className="md:col-span-6 flex justify-evenly">
          <div>
            <h2 className={grayText()}>Basketball training</h2>
            <p>
              4 Jan 2024,{' '}
              <Link data-testid="basketball-training-link" to="basketballTraining">
                read more
              </Link>
            </p>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-12 gap-5 my-4">
        <div className="md:col-span-6 flex justify-evenly">
          <div>
            <h2 className={grayText()}>Soccer training</h2>
            <p>
              15 Oct 2022,{' '}
              <Link data-testid="soccer-training-link" to="soccerTraining">
                read more
              </Link>
            </p>
          </div>
        </div>
        <div className="md:col-span-6 flex justify-evenly">
          <img src={SOCCER_IMAGE} alt="soccer" />
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-12 gap-5 my-4">
        <div className="md:col-span-6 flex justify-evenly">
          <img src={ATHLETICS_IMAGE} alt="athletics" />
        </div>
        <div className="md:col-span-6 flex justify-evenly">
          <div>
            <h2 className={grayText()}>Athletics training</h2>
            <p>
              7 Oct 2022,{' '}
              <Link data-testid="athletics-training-link" to="athleticsTraining">
                read more
              </Link>
            </p>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-12 gap-5 my-4">
        <div className="md:col-span-6 flex justify-evenly">
          <div>
            <h2 className={grayText()}>How to Body Surf?</h2>
            <p>
              29 Jun 2024,{' '}
              <Link data-testid="body-surfing-link" to="howToBodySurf">
                read more
              </Link>
            </p>
          </div>
        </div>
        <div className="md:col-span-6 flex justify-evenly">
          <img src={BODY_SURFING_IMAGE} alt="body surfing" />
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-12 gap-5 my-4">
        <div className="md:col-span-6 flex justify-evenly">
          <img src={RUGBY_IMAGE} alt="rugby" />
        </div>
        <div className="md:col-span-6 flex justify-evenly">
          <div>
            <h2 className={grayText()}>Rugby: kids tackling</h2>
            <p>Work In Progress</p>
          </div>
        </div>
      </div>
    </>
  )
}
