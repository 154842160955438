import { grayText } from '@srcroot/utils/commonCss'
import { SoccerCompetitions } from './soccerCompetitions'
import { Training } from './training'
import { TopPlayers } from './topPlayers'
import { Shooting } from './shooting'
import { MidFieldAndDefense } from './midFieldAndDefense'
import { TableOfContents } from './tableOfContents'

export const Soccer = () => {
  return (
    <>
      <h1 data-testid="soccer-training-header">Soccer</h1>

      <TableOfContents />
      <hr />
      <h2 className={grayText()}>1 Sep 2022</h2>
      <SoccerCompetitions />
      <hr />
      <Training />
      <hr />
      <TopPlayers />
      <hr />
      <Shooting />
      <hr />
      <MidFieldAndDefense />
    </>
  )
}
