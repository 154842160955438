import LENTILS_STEW_IMAGE from '@srcroot/assets/images/family/recipes/lentils/Lentil-Stew-8-680x1020.jpg'
import SPANISH_FLAG_IMAGE from '@srcroot/assets/images/family/recipes/Flag_of_Spain-300x200.png'

export const ThermomixLentilStew = () => {
  return (
    <>
      <h1>Thermomix Lentil stew</h1>
      <img src={LENTILS_STEW_IMAGE} alt="Lentils stew" />
      <br />

      <img width="120" height="80" src={SPANISH_FLAG_IMAGE} alt="Spanish flag" />

      <h1>Ingredientes</h1>
      <ol>
        <li>350 g de lentejas pardinas (al menos deben estar en remojo 2 horas)</li>
        <li>100 g de pimiento verde en trocitos</li>
        <li>100 g de zanahoria en rodajas</li>
        <li>100 g de patata en tacos</li>
        <li>100 g de cebolla troceada</li>
        <li>1 pizca de pimentón picante o dulce o ambos</li>
        <li>50 g de aceite de oliva virgen extra</li>
        <li>sal al gusto</li>
        <li>2 dientes de ajo</li>
        <li>1 hoja de laurel</li>
        <li>800 g de agua</li>
      </ol>

      <hr />

      <h1>Preparación</h1>
      <ol>
        <li>
          Pesa las lentejas en seco y ponlas en remojo antes de su preparación. Con dos horas antes
          es suficiente, pero puedes dejarlas de un día para otro. Si son pardinas frescas no suelen
          necesitar remojo. Cuanto más vieja es la lenteja más deshidratada está y más tiempo de
          remojo necesitará.
        </li>
        <li>
          Vierte las lentejas lavadas y escurridas en el vaso con el resto de los ingredientes
          picados, finalizando con el agua y programa <b>30-35 minutos, 100º</b>, giro a la
          izquierda y velocidad cuchara.
        </li>
        <li>Cuando acabe el tiempo estarán listas.</li>
      </ol>

      <h1>Notas</h1>
      <ol>
        <li>
          Podéis trocear las verduras en Thermomix®: pimiento, cebolla, ajos y trocea{' '}
          <b>4 segundos en velocidad 4</b>. A mi la zanahoria me gusta cortarla en rodajas, queda
          más bonita.
        </li>
        <li>
          Si puedes, preparar un sofrito con el aceite, la cebolla, ajos y el pimiento troceados.{' '}
          <b>Programa 12 minutos, Varoma</b>, giro a la izquierda y velocidad cuchara.
        </li>
      </ol>
    </>
  )
}
